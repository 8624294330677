<template>
  <div style="width: 1200px;min-height: 80vh;" class="mr0At" v-loading="showLoading">
    <!--搜索结果-->
    <div v-if="list.length>0">
      <!--内容-->
      <div class="shop_warp">
        <!--列表-->
        <div class="dif difw w100 mr0At" v-if="list.length>0">
          <div class="goods_item pointer" v-for="(item,index) in list" :key="index" @click="goDetail(item)">
            <div class="img">
              <img :src="item.image" :alt="item.name" :title="item.name">
            </div>
            <div class="box" style="padding: 9px 8px;">
              <div class="difb alc">
                <div class="one_overflow fs16 col333 bold" style="width: 150px;">{{item.name}}</div>
                <div class="one_overflow" style="font-size: 12px;color: #1572F9">库存{{item.stock}}</div>
              </div>
              <div class="one_overflow col333" style="margin-top: 6px;font-size: 12px;">商家：{{item.mch_name}}</div>
            </div>
          </div>
        </div>
      </div>
      <!--分页-->
      <div class="difcac home_page" style="margin: 38px 0 50px 0;">
        <el-pagination
            background
            layout="prev, pager, next"
            :page-count="allPage"
            :current-page="page"
            @current-change="changeSize">
        </el-pagination>
      </div>
    </div>
    <!--未搜索到结果-->
    <div v-if="list.length<=0" style="padding-top: 140px;">
      <el-result icon="info" title="提示" subTitle="未搜索到结果"></el-result>
    </div>
  </div>
</template>

<script>
export default {
  name: "search",
  data() {
    return {
      list: [],//搜索结果
      page: 1,
      limit: 20,
      allPage: 1,//总页数
      keyword: '',//关键词
      showLoading: false,//显示加载
    }
  },
  methods: {
    //搜索内容
    search() {
      let data = {
        keyword: this.keyword,
        page: this.page,
        limit: this.limit
      }
      this.showLoading=true
      this.$axios("POST","/Goods/getGoodList",data)
      .then(res => {
        this.showLoading = false
        if(res.code===1) {
          this.list = res.data.list
          this.allPage = Math.ceil(res.data.total/this.limit)
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    //商品详情
    goDetail(item) {
      let url = this.$router.resolve({
        path: '/goods',
        query: {
          id: item.id
        }
      })
      window.open(url.href)
    },
    //选择页码
    changeSize(e) {
      this.page = e
      window.scrollTo({
        top: 0,//推荐使用，getBoundingClientRect 相对于当前视口的位置
        behavior: "smooth" // 平滑滚动
      });
      this.search();
    },
  },
  watch: {
    $route(newValue) {
      this.keyword = newValue.query.s
      this.search()
    }
  },
  created() {
    this.keyword = this.$route.query.s
    this.search()
  },
}
</script>

<style scoped>
  .shop_warp{
    width: 1200px;
    background: #FFFFFF;
    margin: 20px auto;
    box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.3);
    padding: 12px;
    padding-bottom: 15px;
  }
  .shop_top{
    border: 1px solid #EEEEEE;
    padding-top: 11px;
    padding-bottom: 19px;
  }
  .logo{
    max-width: 140px;
    height: 60px;
    background: #1572F9;
    margin-right: 28px;
    font-weight: bold;
    font-size: 18px;
    color: #ffffff;
  }
  .swiper{
    width: 100%;
  }
  /*分类*/
  ::v-deep .el-tabs__item{
    font-size: 32px;
    width: 200px;
    text-align: center;
  }
  ::v-deep .el-tabs__nav-scroll{
    padding-bottom: 30px;
  }
  ::v-deep .el-tabs__nav-next .el-icon-arrow-right{
    font-size: 30px;
    padding-top: 5px;
  }
  ::v-deep .el-tabs__nav-prev .el-icon-arrow-left{
    font-size: 30px;
    padding-top: 5px;
  }
  ::v-deep .el-tabs__item.is-active{
    color: #1572F9;
  }
  ::v-deep .el-tabs__active-bar{
    bottom: -30px;
    height: 6px;
    background-color: #1572F9;
  }
  ::v-deep .el-tabs__active-bar:after{
    content: '';
    width: 0;
    height: 0;
    border-bottom: 20px solid #1572F9;
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,-100%);
  }
  ::v-deep .el-tabs__nav-wrap::after{
    height: 6px;
  }

  .goods_item{
    width: 224px;
    height: 265px;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
    margin-bottom: 20px;
    margin-right: 13px;
    overflow: hidden;
  }
  .goods_item:nth-child(5n) {
    margin-right: 0;
  }
  .goods_item .img{
    overflow: hidden;
    width: 100%;
    height: 203px;
  }
  .goods_item img{
    width: 100%;
    height: 100%;
    background: #f1f1f1;
    transition: all 0.3s;
  }
  .goods_item img:hover{
    transform: scale(1.1,1.1);
  }

  .buy_page ::v-deep .number{
    width: 40px;
    height: 40px;
    padding: 6px 5px;
  }
  .buy_page ::v-deep .more{
    width: 40px;
    height: 40px;
    padding: 6px 5px;
  }
  .buy_page ::v-deep .btn-next{
    width: 40px;
    height: 40px;
    padding: 6px 5px;
  }
  .buy_page ::v-deep .btn-prev{
    width: 40px;
    height: 40px;
    padding: 6px 5px;
  }

  .pay_bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 333;
    background-color: rgba(0,0,0,0.5);
  }
  .pay_warp{
    width: 602px;
    height: 389px;
    background: #FFFFFF;
    border-radius: 26px 26px 26px 26px;
    box-sizing: border-box;
    padding-top: 46px;
  }
  .button1{
    width: 216px;
    height: 73px;
    background: #DCDCDC;
    font-size: 36px;
    color: #ffffff;
    cursor: pointer;
    border-radius: 40px;
    transition: all 0.2s;
  }
  .button1:hover{
    background-color: #c4c4c4;
  }
  .button2{
    border-radius: 40px;
    width: 216px;
    height: 73px;
    background: #1572F9;
    font-size: 36px;
    color: #ffffff;
    margin-left: 24px;
    cursor: pointer;
    transition: all 0.2s;
  }
  .button2:hover{
    background: #0152d0;
  }
</style>