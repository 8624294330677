<template>
  <div class="address_warp">
    <div class="add_button difcac colfff" @click="goAdd()">新增收货地址</div>
    <!--地址列表-->
    <div class="address_list" v-if="list.length>0">
      <div class="address_item" :class="{'active': item.is_default==1}"
           v-for="(item,index) in list" :key="index">
        <div class="one_overflow fs18 col000" style="margin-bottom: 8px;">收货人：{{item.name}}</div>
        <div class="difb alc">
          <div class="fs14 col000" style="line-height: 28px;">
            <p>手机：{{item.tel}}</p>
            <p>所在地址：{{item.sca_name}}</p>
            <p class="one_overflow" style="width: 500px;">{{item.address}}</p>
          </div>
          <div class="dif alc">
            <div class="default_button difcac colfff" :class="{'active': item.is_default==1}">
              <span v-if="item.is_default==1">默认地址</span>
              <span v-else @click="setDefault(item)">设为默认地址</span>
            </div>
            <p class="pointer hover fs14 mrLft20" style="color: #414141" @click="goUpdate(item)">编辑</p>
          </div>
        </div>
        <!--选中图标-->
        <div v-if="item.is_default==1" class="bottom_iconbg"></div>
        <i class="el-icon-check bottom_icon"></i>
      </div>
    </div>
    <div v-else style="padding-bottom: 400px;padding-top: 100px;">
      <el-result icon="info" title="暂无地址"></el-result>
    </div>
  </div>
</template>

<script>
export default {
  name: "addressList",
  data() {
    return {
      list: [],//地址列表
    }
  },
  methods: {
    //添加地址
    goAdd() {
      this.$router.push({
        path: '/me/addAddress'
      })
    },
    //设为默认地址
    setDefault(item) {
     let obj = item
      if(item.sex==='男') {
        obj.sex = 1
      }else {
        obj.sex = 0
      }
      obj.address_id = item.id
      obj.is_default = 1
      this.$axios("POST","/user/updateAddress",obj,'设置中')
          .then(res => {
            if(res.code===1) {
              this.$message.success('设置成功')
              setTimeout(()=>{
                this.getList()
              },1000)
            }else {
              this.$alert(res.msg,{type: 'error'})
            }
          })
    },
    //编辑地址
    goUpdate(item) { //编辑地址
      localStorage.setItem('hbaddress',JSON.stringify(item))
      this.$router.push({
        path: '/me/addAddress',
        query: {id: item.id}
      })
    },
    //获取地址列表
    getList() {
      this.$axios("POST","/user/getMyAddress",{})
      .then(res => {
        if(res.code===1) {
          this.list = res.data
        }
      })
    },
  },
  created() {
    this.getList() //获取地址列表
  }
}
</script>

<style scoped>
  .bottom_iconbg{
    position: absolute;
    bottom: -20px;
    right: -20px;
    width: 40px;
    height: 40px;
    background-color: #4981E8;
    transform: rotate(45deg);
  }
  .bottom_icon{
    position: absolute;
    color: #ffffff;
    bottom: 0;
    right: 0;
    font-size: 16px;
  }
  .address_warp{
    width: 100%;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 9px 30px;
  }
  .add_button{
    transition: all 0.3s;
    width: 282px;
    height: 64px;
    background: #007AFF;
    border-radius: 4px 4px 4px 4px;
    cursor: pointer;
    color: #ffffff;
    font-size: 20px;
    margin-left: 6px;
    margin-bottom: 21px;
  }
  .add_button:hover{
    background: #3A53FF;
  }
  .address_item{
    width: 1024px;
    height: 150px;
    background: #FFFFFF;
    border: 1px solid #C3C3C3;
    box-sizing: border-box;
    margin-bottom: 17px;
    padding: 17px 24px;
  }
  .address_item.active{
    border: 1px solid #4981E8;
    position: relative;
    overflow: hidden;
  }
  .default_button{
    width: 180px;
    height: 40px;
    background: #C3C3C3;
    border: 1px solid #C3C3C3;
    cursor: pointer;
    transition: all 0.2s;
  }
  .default_button.active,
  .default_button:hover{
    background-color: #1572F9;
  }
</style>