module.exports = {
  data() {
    return {
      url:'https://chuangyou2717.oss-cn-hangzhou.aliyuncs.com',//域名 线上
      upLoadUrl: 'https://cy227.com/addons/alioss/index/upload',//上传路径
      upLoadUrl2: 'https://cy227.com/api/common/upload',//上传+水印
      videoUpLoadUrl: 'https://cy227.com/api/index/video_add',//视频上传路径
      version: '1.2.2',//版本
      searchKeyword: '',
    }
  },
  methods: {
    setView() {
      this.$axios('POST','/index/setViewNum',{})
    },
    getKeyword(query) {
      if(query.s) {
         this.searchKeyword = query.s
      }else {
        this.searchKeyword = ''
      }
    }
  },
}