<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "myCommission"
}
</script>

<style scoped>
.order_list{
  border-top: 1px solid #1572F9;
}
.order_item{
  padding: 16px 14px 19px 21px;
  border-bottom: 1px solid #1572F9;
}
.order_img{
  width: 106px;
  height: 106px;
  background-color: #EEEEEE;
  margin-right: 34px;
}
.tabs{
  height: 29px;
  background: #1572F9;
}
.tab_item{
  font-size: 14px;
  color: #ffffff;
  box-sizing: border-box;
  padding: 0 21px;
  cursor: pointer;
  height: 29px;
}
.tab_item.active{
  background-color: #3EA6FF;
}
</style>