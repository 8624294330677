<template>
  <div style="padding-bottom: 81px;">
    <!--地址列表-->
    <div style="width: 1200px;" class="mr0At">
      <div class="difb alc" style="padding: 48px 0;border-bottom: 1px solid #E8E8E8;">
        <p class="fs32 col000">确认收货地址</p>
        <p class="fs32 col000 pointer hover" @click="goAddress()">地址管理</p>
      </div>
      <div class="address_warp pdTop20">
        <el-radio-group v-model="currentAddress">
          <div v-for="(item,index) in 8" :key="index">
            <el-radio :label="item" class="dif alc" style="margin-bottom: 70px;">
              <p class="address_item hover one_overflow">
                张三，176151161131，山东省济南市历下区海尔时代大厦l705室张三，176151161131，山东省济南市历下区海尔时代大厦l705室
              </p>
            </el-radio>
          </div>
        </el-radio-group>

      </div>
    </div>
    <div style="border-bottom: 1px solid #E2E2E2;"></div>
    <!--商品信息-->
    <div style="width: 1200px;padding-top: 42px;" class="mr0At">
      <!--表头-->
      <div class="dif alc fs14 col000" style="margin-bottom: 12px;">
        <div style="margin-left: 142px;margin-right: 179px;">商品信息</div>
        <div style="margin-right: 180px;">商品规格</div>
        <div style="margin-right: 179px;">单价（元）</div>
        <div style="margin-right: 180px;">数量</div>
        <div>金额（元）</div>
      </div>
      <!--表单-->
      <div class="goods_warp">
        <div class="goods_item dif alc" v-for="(item,index) in 3" :key="index">
          <!--商品-->
          <div class="dif alc">
            <img src="" alt="" class="goods_img">
            <p class="two_overflow fs14 col000"
               style="word-break: break-all;width: 130px;">白色纯棉长颈鹿T恤</p>
          </div>
          <!--规格-->
          <div class="two_overflow fs14 col000 tct"
               style="width: 120px;margin-left: 74px;word-break: break-all">白色；XL</div>
          <!--单价-->
          <div class="fs20 tct" style="color: #414141;width: 200px;margin-left: 75px;">95.00</div>
          <!--数量-->
          <div style="margin-left: 70px;">
            <el-input-number size="mini" :min="1" v-model="num"></el-input-number>
          </div>
          <!--金额-->
          <div class="fs24  tct" style="color: #D64040;width: 160px;margin-left: 80px;">
            ¥95.00
          </div>
        </div>
      </div>
    </div>

    <!--支付-->
    <div class="pay_center">
      <!--支付-->
      <div class="difb alc" style="width: 1100px;">
        <div></div>
        <div class="dif alc">
          <span class="fs32 col000">总计：</span>
          <span style="font-size: 54px;color: #F75C5C">92.00积分</span>
        </div>
      </div>
      <div class="dife alc">
        <div class="pay_button difcac">立即兑换</div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "payIntegral",
  data() {
    return{
      currentAddress: '',
      num: 1,
      payType: 1
    }
  },
  methods: {
    // 地址管理
    goAddress() {

    },
  }
}
</script>

<style scoped>
::v-deep .el-radio__inner{
  width: 24px;
  height: 24px;
}
::v-deep .el-radio__input.is-checked .el-radio__inner::after{
  width: 12px;
  height: 12px;
}
.address_warp{
  overflow-y: auto;
  height: 463px;
}
.address_item{
  width: 1100px;
  font-size: 24px;
  color: #000000;
}
.goods_warp{
  background: #FFFFFF;
  box-sizing: border-box;
  border: 1px solid #E8E8E8;
}
.goods_item{
  border-bottom: 1px solid #E8E8E8;
  box-sizing: border-box;
  padding: 20px 0 20px 37px ;
}
.goods_img{
  width: 90px;
  height: 90px;
  background-color: #EEEEEE;
  margin-right: 15px;
}

.pay_warp{
  background-color: #f1f1f1;
  height: 79vh;
}
.pay_center{
  height: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  width: 1200px;
  margin: 0 auto;
  padding-left: 50px;
  padding-top: 37px;
}
.pay_item{
  width: 236px;
  height: 69px;
  background: #FFFFFF;
  border-radius: 6px 6px 6px 6px;
  border: 2px solid #868686;
  cursor: pointer;
  font-size: 28px;
  color: #383D42;
  overflow: hidden;
  position: relative;
}
.pay_item.active{
  border: 2px solid #337DF7;
  color: #337DF7;
}
.check{
  width: 41px;
  height: 41px;
  background-color: #3765FF;
  position: absolute;
  right: -20px;
  bottom: -20px;
  transform: rotate(45deg);
}
.check_icon{
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 18px;
  color: #ffffff;
  z-index: 22;
}
.pay_button{
  width: 180px;
  height: 40px;
  background: #1572F9;
  border: 1px solid #1572F9;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.1s;
  margin-top: 60px;
  margin-right: 100px;
}
.pay_button:hover{
  background: #0042aa;
}
</style>