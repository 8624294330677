<template>
  <div style="width: 1200px;margin: 0px auto;" class="bgff pdTop10 " v-loading="isLoading">
    <div class="dif alc mt10 mb10">
      <div class="w-400  mr20">
        <el-input clearable @clear="search" placeholder="请输入搜索内容" @keyup.enter.native="search" v-model="keyword" ></el-input>
      </div>
      <el-button type="primary" icon="el-icon-search" @click="search()">搜索</el-button>
    </div>
    <!--我发布的-->
    <div class="mrTop10">
      <el-tabs v-model="activeName" @tab-click="changeElTabs">
        <el-tab-pane v-for="(item,index) in cates" :key="index" v-if="index!==3 && index!==4 && index!==2 && index!==1"
                     :label="item.name" :name="item.id"></el-tab-pane>
      </el-tabs>
    </div>
    <!--工程设备-->
    <div  class="box" style="padding: 0 54px 30px 24px;" v-if="currentActive===0">
      <div v-if="cates[0].list.length>0">
        <div class="submit_item  hover" v-for="(item,index) in cates[0].list" :key="index" @click="goDetail(item)">
          <div class="difb alc">
            <div class="dif alc">
              <p class="one_overflow fs14 col000 hover" style="width: 310px;">{{item.title}}</p>
              <p style="margin-left: 191px;color: #FB6124" class="fs14 bold">{{item.status_text}}</p>
            </div>
            
          </div>
          <div class="difb alc fs14" style="margin-top: 22px;color: #969696">
            <span>{{item.userinfo.nickname}}</span>
            <span>{{item.createtime_str}}</span>
          </div>
        </div>
        <!--分页-->
        <div class="difcac buy_page" style="margin: 38px 0 50px 0;">
          <el-pagination
              background
              layout="prev, pager, next"
              :page-count="allPage"
              :current-page="page"
              @current-change="changeSize">
          </el-pagination>
        </div>
      </div>
      <div v-else style="padding: 150px 0;">
        <el-result icon="info" title="提示" subTitle="暂未发布"></el-result>
      </div>
    </div>
    <!--安装工程-->
    <div  class="box" style="padding: 0 54px 30px 24px;" v-if="currentActive===1">
      <div v-if="cates[1].list.length>0">
        <div class="submit_item  hover" v-for="(item,index) in cates[1].list" :key="index" @click="goDetail(item)">
          <div class="difb alc">
            <div class="dif alc">
              <p class="one_overflow fs14 col000 hover" style="width: 310px;">{{item.title}}</p>
              <p style="margin-left: 191px;color: #FB6124" class="fs14 bold">{{item.status_text}}</p>
            </div>
            
          </div>
          <div class="difb alc fs14" style="margin-top: 52px;color: #969696">
            <span>{{item.userinfo.nickname}}</span>
            <span>{{item.createtime_str}}</span>
          </div>
        </div>
        <!--分页-->
        <div class="difcac buy_page" style="margin: 38px 0 50px 0;">
          <el-pagination
              background
              layout="prev, pager, next"
              :page-count="allPage"
              :current-page="page"
              @current-change="changeSize">
          </el-pagination>
        </div>
      </div>
      <div v-else style="padding: 150px 0;">
        <el-result icon="info" title="提示" subTitle="暂未发布"></el-result>
      </div>
    </div>
    <!--物流货运-->
    <div  class="box" style="padding: 0 54px 30px 24px;" v-if="currentActive===2">
      <div v-if="cates[2].list.length>0">
        <div class="submit_item  hover" v-for="(item,index) in cates[2].list" :key="index" @click="goDetail(item)">
          <div class="difb alc">
            <div class="dif alc">
              <p class="one_overflow fs14 col000 hover" style="width: 310px;">{{item.title}}</p>
              <p style="margin-left: 191px;color: #FB6124" class="fs14 bold">{{item.status_text}}</p>
            </div>
            
          </div>
          <p class="fs14 mrTop10 hover pointer" style="color: #868686;line-height: 1.4em;">
            {{item.describe}}
          </p>
          <div class="difb alc fs14" style="margin-top: 32px;color: #969696">
            <span>{{item.userinfo.nickname}}</span>
            <span>{{item.createtime_str}}</span>
          </div>
        </div>
        <!--分页-->
        <div class="difcac buy_page" style="margin: 38px 0 50px 0;">
          <el-pagination
              background
              layout="prev, pager, next"
              :page-count="allPage"
              :current-page="page"
              @current-change="changeSize">
          </el-pagination>
        </div>
      </div>
      <div v-else style="padding: 150px 0;">
        <el-result icon="info" title="提示" subTitle="暂未发布"></el-result>
      </div>
    </div>
    <!--人才招聘-->
    <div  class="box" style="padding: 0 54px 30px 24px;" v-if="currentActive===3">
      <div v-if="cates[3].list.length>0">
        <div class="submit_item  hover" v-for="(item,index) in cates[3].list" :key="index" @click="goDetail(item)">
          <div class="difb alc">
            <div class="dif alc">
              <p class="one_overflow fs14 col000 hover" style="width: 310px;">{{item.title}}</p>
              <!--<p style="margin-left: 191px;color: #FB6124" class="fs14 bold">{{item.status_text}}</p>-->
            </div>
            
          </div>
          <div style="margin: 13px 0;">
            <span style="color: #FB6124" class="fs14">{{item.post_salary_text}}</span>
            <!--<span style="color: #868686" class="fs14">元/月</span>-->
            <span style="color: #FB6124;margin-left: 13px;" class="fs14">{{item.work_experience_text}}</span>
          </div>
          <div class="difb alc fs14" style="margin-top: 32px;color: #969696">
            <span>{{item.userinfo.nickname}}</span>
            <span>{{item.createtime_str}}</span>
          </div>
        </div>
        <!--分页-->
        <div class="difcac buy_page" style="margin: 38px 0 50px 0;">
          <el-pagination
              background
              layout="prev, pager, next"
              :page-count="allPage"
              :current-page="page"
              @current-change="changeSize">
          </el-pagination>
        </div>
      </div>
      <div v-else style="padding: 150px 0;">
        <el-result icon="info" title="提示" subTitle="暂未发布"></el-result>
      </div>
    </div>
    <!--求职信息-->
    <div  class="box" style="padding: 0 54px 30px 24px;" v-if="currentActive===4">
      <div v-if="cates[4].list.length>0">
        <div class="submit_item  hover" v-for="(item,index) in cates[4].list" :key="index" @click="goDetail(item)">
          <div class="difb alc">
            <div class="dif alc">
              <p class="one_overflow fs14 col000 hover" style="width: 310px;">{{item.title}}</p>
              <!--<p style="margin-left: 191px;color: #FB6124" class="fs14 bold">{{item.status_text}}</p>-->
            </div>
            
          </div>
          <div style="margin: 13px 0;">
            <span style="color: #FB6124" class="fs14">{{item.post_salary_text}}</span>
            <!--<span style="color: #868686" class="fs14">元/月</span>-->
            <span style="color: #FB6124;margin-left: 13px;" class="fs14">{{item.work_experience_text}}</span>
          </div>
          <div class="difb alc fs14" style="margin-top: 32px;color: #969696">
            <span>{{item.userinfo.nickname}}</span>
            <span>{{item.createtime_str}}</span>
          </div>
        </div>
        <!--分页-->
        <div class="difcac buy_page" style="margin: 38px 0 50px 0;">
          <el-pagination
              background
              layout="prev, pager, next"
              :page-count="allPage"
              :current-page="page"
              @current-change="changeSize">
          </el-pagination>
        </div>
      </div>
      <div v-else style="padding: 150px 0;">
        <el-result icon="info" title="提示" subTitle="暂未发布"></el-result>
      </div>
    </div>
    <!--二手设备-->
    <div  class="box" style="padding: 0 54px 30px 24px;" v-if="currentActive===5">
      <div v-if="cates[5].list.length>0">
        <div class="submit_item  hover" v-for="(item,index) in cates[5].list" :key="index" @click="goDetail(item)">
          <div class="difb alc">
            <div class="dif alc">
              <p class="one_overflow fs14 col000 hover" style="width: 310px;">{{item.title}}</p>
              <!--<p style="margin-left: 191px;color: #FB6124" class="fs14 bold">{{item.status_text}}</p>-->
            </div>
            
          </div>
          <div class="difb alc fs14" style="margin-top: 22px;color: #969696">
            <span>{{item.userinfo.nickname}}</span>
            <span>{{item.createtime_str}}</span>
          </div>
        </div>
        <!--分页-->
        <div class="difcac buy_page" style="margin: 38px 0 50px 0;">
          <el-pagination
              background
              layout="prev, pager, next"
              :page-count="allPage"
              :current-page="page"
              @current-change="changeSize">
          </el-pagination>
        </div>
      </div>
      <div v-else style="padding: 150px 0;">
        <el-result icon="info" title="提示" subTitle="暂未发布"></el-result>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "badding",
  data() {
    return {
      current: 0,
      tabs: [
        {id: 1,name: '我发布的'},
        // {id: 2,name: '收到的的报价'},
      ],
      cates: [
        {id: '1',name: '采购信息',list: []},
        {id: '2',name: '安装工程',list: []},
        {id: '3',name: '物流货运',list: []},
        {id: '4',name: '人才招聘',list: []},
        {id: '5',name: '求职信息',list: []},
        {id: '6',name: '二手设备',list: []},
      ],//分类
      activeName: '1',
      currentActive: 0,

      isLoading: false,
      keyword: '',
      limit: 4,//每页数量
      page: 1,//当前页数
      allPage: 1,//总页数
    }
  },
  methods: {
    search() {
      this.page = 1
      this.getList()
    },
    //删除信息
    delInfo(id) {
      this.$confirm('确认要删除该发布信息吗？',{type: 'error'})
          .then(con => {
            this.isLoading = true
            this.$axios("POST","/common/delMyPost",{
              id: id,
              type: this.activeName
            }).then(res => {
              if(res.code===1) {
                this.$message.success('删除成功')
                this.getList()
              }else {
                this.$alert(res.msg,{type: 'error'})
              }
            })
          })
    },
    //选择我发布的的 收到的报价
    changeTabs(index) {
      this.current = index
    },
    //选择发布类型
    changeElTabs(tab,event) {
      this.page = 1
      this.activeName = tab.name
      this.currentActive = this.cates.findIndex(item => item.id==tab.name)
      this.getList()
    },
    // 获取我的发布
    getList() {
      let data = {
        page: this.page,
        limit: this.limit,
        is_my_fee: 1,
        keyword: this.keyword
      }
      let current = this.currentActive
      let url = '/post/ask_buy/getList' //工程设备列表
      if(this.currentActive===1) url = '/post/information/getList' //安装工程列表
      if(this.currentActive===2) url = '/post/post_logistics_freight/getList' //物流货运列表
      if(this.currentActive===3) url = '/post/job_fair/getList' //人才招聘列表
      if(this.currentActive===4) url = '/post/job_intention/getList' //求职信息列表
      if(this.currentActive===5) url = '/post/second_hand/getList' //二手设备列表
      this.isLoading = true
      this.$axios("POST",url,data)
          .then(res => {
            this.isLoading = false
            if(res.code===1) {
              this.cates[current].list = res.data.list
              this.allPage = Math.ceil(res.data.total/this.limit)
            }else {
              this.$message.error(res.msg)
            }
          })
    },
    //切换页数
    changeSize(e) {
      this.page = e
      this.getList();//获取工程设备求购列表
    },
    goDetail(item) { //我发布的报价单详情
      if(this.isUpdata) return;
      let url = ''
      if(this.activeName==1) url = '/detail'//工程设备
      if(this.activeName==2) url = '/insworks/detail'//安装工程
      if(this.activeName==3) url = '/logistics/detail'//物流货运
      if(this.activeName==4) url = '/recruit/detail'//人才招聘
      if(this.activeName==5) url = '/jobwanted/detail'//求职信息
      if(this.activeName==6) url = '/secondhand/detail'//二手设备
      let path = this.$router.resolve({
        path: url,
        query: {id: item.id},
      })
      window.open(path.href)
    },
  },
  created() {
    this.getList() //获取列表
  }
}
</script>

<style scoped>
.tabs{
  padding: 15px 0 15px 15px;
  border-bottom: 1px solid #E5E5E5;
}
.tab_item{
  margin-right: 152px;
  font-size: 26px;
  color: #000000;
  transition: all 0.3s;
}
.tab_item.active{
  color: #1572F9;
}
.submit_item{
  border-bottom: 1px solid #F2F2F2;
  padding-top: 18px;
  padding-bottom: 25px;
}
/*分类*/
::v-deep .el-tabs__item{
  font-size: 28px;
  text-align: center;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
::v-deep .el-tabs__nav-scroll{
  padding-bottom: 30px;
}
::v-deep .el-tabs__nav-next .el-icon-arrow-right{
  font-size: 30px;
  padding-top: 5px;
}
::v-deep .el-tabs__nav-prev .el-icon-arrow-left{
  font-size: 30px;
  padding-top: 5px;
}
::v-deep .el-tabs__item.is-active{
  color: #1572F9;
}
::v-deep .el-tabs__active-bar{
  bottom: -30px;
  height: 6px;
  background-color: #1572F9;
}
::v-deep .el-tabs__active-bar:after{
  content: '';
  width: 0;
  height: 0;
  border-bottom: 20px solid #1572F9;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%,-100%);
}
::v-deep .el-tabs__nav-wrap::after{
  height: 6px;
}
</style>