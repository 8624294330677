<template>
  <div style="width: 100%;" class="bgff" v-loading="isLoading">
    <!--<div class="tabs dif alc">
      <div class="tab_item hover pointer" @click="changeTabs(index)"
           v-for="(item,index) in tabs" :class="{'active': current===index}" :key="index">{{item.name}}</div>
    </div>-->
    <div class="dif alc pt20 pl20 ">
      <div class="w-400  mr20">
        <el-input clearable @clear="search" placeholder="请输入搜索内容" @keyup.enter.native="search" v-model="keyword" ></el-input>
      </div>
      <el-button type="primary" icon="el-icon-search" @click="search()">搜索</el-button>
    </div>
    <!--我发布的-->
    <div class=" pdTop20">
      <el-tabs v-model="activeName" @tab-click="changeElTabs">
        <el-tab-pane v-for="(item,index) in cates" :key="index" v-if="isShowTab(item)"
                     :label="item.name" :name="item.id"></el-tab-pane>
      </el-tabs>
    </div>
    <!--工程设备-->
    <div  class="box" style="padding: 0 54px 30px 24px;" v-if="activeName==='0'">
      <div v-if="cates[currentActive].list.length>0">
        <div class="submit_item  hover pointer" @click="goDetail(item,0)" v-for="(item,index) in cates[currentActive].list" :key="item.id">
          <div class="difb alc">
            <div class="dif alc">
              <p class="one_overflow fs14 col000 hover" style="width: 310px;">{{item.title}}</p>
              <p style="margin-left: 191px;color: #FB6124" class="fs14 bold">{{item.status_text}}</p>
            </div>
            <p style="margin-left: 391px;color: #fa3534" class="fs14 bold pointer" @click.stop="delInfo(item.id)">删除</p>
          </div>
          <div class="difb alc fs14" style="margin-top: 22px;color: #969696">
            <span>{{item.userinfo.nickname}}</span>
            <span>{{item.createtime_str}}</span>
          </div>
        </div>
        <!--分页-->
        <div class="difcac buy_page" style="margin: 38px 0 50px 0;">
          <el-pagination
              background
              layout="prev, pager, next"
              :page-count="allPage"
              :current-page="page"
              @current-change="changeSize">
          </el-pagination>
        </div>
      </div>
      <div v-else style="padding: 150px 0;">
        <el-result icon="info" title="提示" subTitle="暂未发布"></el-result>
      </div>
    </div>
    <!--安装工程-->
    <div  class="box" style="padding: 0 54px 30px 24px;" v-if="activeName==='1'">
      <div v-if="cates[currentActive].list.length>0">
        <div class="submit_item  hover" v-for="(item,index) in cates[currentActive].list" :key="index">
          <div class="difb alc">
            <div class="dif alc">
              <p class="one_overflow fs14 col000 hover" style="width: 310px;">{{item.title}}</p>
              <p style="margin-left: 191px;color: #FB6124" class="fs14 bold">{{item.status_text}}</p>
            </div>
            <p style="margin-left: 391px;color: #fa3534" class="fs14 bold pointer" @click.stop="delInfo(item.id)">删除</p>
          </div>
          <div class="difb alc fs14" style="margin-top: 52px;color: #969696">
            <span>{{item.userinfo.nickname}}</span>
            <span>{{item.createtime_str}}</span>
          </div>
        </div>
        <!--分页-->
        <div class="difcac buy_page" style="margin: 38px 0 50px 0;">
          <el-pagination
              background
              layout="prev, pager, next"
              :page-count="allPage"
              :current-page="page"
              @current-change="changeSize">
          </el-pagination>
        </div>
      </div>
      <div v-else style="padding: 150px 0;">
        <el-result icon="info" title="提示" subTitle="暂未发布"></el-result>
      </div>
    </div>
    <!--物流货运-->
    <div  class="box" style="padding: 0 54px 30px 24px;" v-if="activeName==='2'">
      <div v-if="cates[currentActive].list.length>0">
        <div class="submit_item  hover" v-for="(item,index) in cates[currentActive].list" :key="index">
          <div class="difb alc">
            <div class="dif alc">
              <p class="one_overflow fs14 col000 hover" style="width: 310px;">{{item.title}}</p>
              <p style="margin-left: 191px;color: #FB6124" class="fs14 bold">{{item.status_text}}</p>
            </div>
            <p style="margin-left: 391px;color: #fa3534" class="fs14 bold pointer" @click.stop="delInfo(item.id)">删除</p>
          </div>
          <p class="fs14 mrTop10 hover pointer" style="color: #868686;line-height: 1.4em;">
            {{item.describe}}
          </p>
          <div class="difb alc fs14" style="margin-top: 32px;color: #969696">
            <span>{{item.userinfo.nickname}}</span>
            <span>{{item.createtime_str}}</span>
          </div>
        </div>
        <!--分页-->
        <div class="difcac buy_page" style="margin: 38px 0 50px 0;">
          <el-pagination
              background
              layout="prev, pager, next"
              :page-count="allPage"
              :current-page="page"
              @current-change="changeSize">
          </el-pagination>
        </div>
      </div>
      <div v-else style="padding: 150px 0;">
        <el-result icon="info" title="提示" subTitle="暂未发布"></el-result>
      </div>
    </div>
    <!--人才招聘-->
    <div  class="box" style="padding: 0 54px 30px 24px;" v-if="activeName==='3'">
      <div v-if="cates[currentActive].list.length>0">
        <div class="submit_item  hover" v-for="(item,index) in cates[currentActive].list" :key="index">
          <div class="difb alc">
            <div class="dif alc">
              <p class="one_overflow fs14 col000 hover" style="width: 310px;">{{item.title}}</p>
              <!--<p style="margin-left: 191px;color: #FB6124" class="fs14 bold">{{item.status_text}}</p>-->
            </div>
            <p style="margin-left: 391px;color: #fa3534" class="fs14 bold pointer" @click.stop="delInfo(item.id)">删除</p>
          </div>
          <div style="margin: 13px 0;">
            <span style="color: #FB6124" class="fs14">{{item.post_salary_text}}</span>
            <!--<span style="color: #868686" class="fs14">元/月</span>-->
            <span style="color: #FB6124;margin-left: 13px;" class="fs14">{{item.work_experience_text}}</span>
          </div>
          <div class="difb alc fs14" style="margin-top: 32px;color: #969696">
            <span>{{item.userinfo.nickname}}</span>
            <span>{{item.createtime_str}}</span>
          </div>
        </div>
        <!--分页-->
        <div class="difcac buy_page" style="margin: 38px 0 50px 0;">
          <el-pagination
              background
              layout="prev, pager, next"
              :page-count="allPage"
              :current-page="page"
              @current-change="changeSize">
          </el-pagination>
        </div>
      </div>
      <div v-else style="padding: 150px 0;">
        <el-result icon="info" title="提示" subTitle="暂未发布"></el-result>
      </div>
    </div>
    <!--求职信息-->
    <div  class="box" style="padding: 0 54px 30px 24px;" v-if="activeName==='4'">
      <div v-if="cates[currentActive].list.length>0">
        <div class="submit_item  hover" v-for="(item,index) in cates[currentActive].list" :key="index">
          <div class="difb alc">
            <div class="dif alc">
              <p class="one_overflow fs14 col000 hover" style="width: 310px;">{{item.title}}</p>
              <!--<p style="margin-left: 191px;color: #FB6124" class="fs14 bold">{{item.status_text}}</p>-->
            </div>
            <p style="margin-left: 391px;color: #fa3534" class="fs14 bold pointer" @click.stop="delInfo(item.id)">删除</p>
          </div>
          <div style="margin: 13px 0;">
            <span style="color: #FB6124" class="fs14">{{item.post_salary_text}}</span>
            <!--<span style="color: #868686" class="fs14">元/月</span>-->
            <span style="color: #FB6124;margin-left: 13px;" class="fs14">{{item.work_experience_text}}</span>
          </div>
          <div class="difb alc fs14" style="margin-top: 32px;color: #969696">
            <span>{{item.userinfo.nickname}}</span>
            <span>{{item.createtime_str}}</span>
          </div>
        </div>
        <!--分页-->
        <div class="difcac buy_page" style="margin: 38px 0 50px 0;">
          <el-pagination
              background
              layout="prev, pager, next"
              :page-count="allPage"
              :current-page="page"
              @current-change="changeSize">
          </el-pagination>
        </div>
      </div>
      <div v-else style="padding: 150px 0;">
        <el-result icon="info" title="提示" subTitle="暂未发布"></el-result>
      </div>
    </div>
    <!--二手设备-->
    <div  class="box" style="padding: 0 54px 30px 24px;" v-if="activeName==='5'">
      <div v-if="cates[currentActive].list.length>0">
        <div class="submit_item  hover" v-for="(item,index) in cates[currentActive].list" :key="item.id" @click="goDetail(item,5)">
          <div class="difb alc">
            <div class="dif alc">
              <p class="one_overflow fs14 col000 hover" style="width: 310px;">{{item.title}}</p>
              <!--<p style="margin-left: 191px;color: #FB6124" class="fs14 bold">{{item.status_text}}</p>-->
            </div>
            <p style="margin-left: 391px;color: #fa3534" class="fs14 bold pointer" @click.stop="delInfo(item.id)">删除</p>
          </div>
          <div class="difb alc fs14" style="margin-top: 22px;color: #969696">
            <span>{{item.userinfo.nickname}}</span>
            <span>{{item.createtime_str}}</span>
          </div>
        </div>
        <!--分页-->
        <div class="difcac buy_page" style="margin: 38px 0 50px 0;">
          <el-pagination
              background
              layout="prev, pager, next"
              :page-count="allPage"
              :current-page="page"
              @current-change="changeSize">
          </el-pagination>
        </div>
      </div>
      <div v-else style="padding: 150px 0;">
        <el-result icon="info" title="提示" subTitle="暂未发布"></el-result>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "offer",
  data() {
    return {
      current: 0,
      tabs: [
        {id: 1,name: '我发布的'},
        // {id: 2,name: '收到的的报价'},
      ],
      cates: [
        {id: '0',name: '采购信息',list: [],type:  1},
        // {id: '1',name: '安装工程',list: []},
        // {id: '2',name: '物流货运',list: []},
        // {id: '3',name: '人才招聘',list: []},
        // {id: '4',name: '求职信息',list: []},
        {id: '5',name: '二手设备',list: [],type:  6},
      ],//分类
      activeName: '0',
      currentActive: 0,

      isLoading: false,
      keyword: '',
      limit: 30,//每页数量
      page: 1,//当前页数
      allPage: 1,//总页数
    }
  },
  methods: {
    search() {
      this.page = 1
      this.getList()
    },
    isShowTab(item) {
      if(item.id==='0' && this.$store.state.userInfo.firm_type==1) {
        return false
      }
      return true
    },
    //删除信息
    delInfo(id) {
      this.$confirm('确认要删除该发布信息吗？',{type: 'error'})
      .then(con => {
        this.isLoading = true
        this.$axios("POST","/common/delMyPost",{
          id: id,
          type: this.cates[this.currentActive].type
        }).then(res => {
          if(res.code===1) {
            this.$message.success('删除成功')
            this.getList()
          }else {
            this.$alert(res.msg,{type: 'error'})
          }
        })
      })
    },
    //选择我发布的的 收到的报价
    changeTabs(index) {
      this.current = index
    },
    //选择发布类型
    changeElTabs(tab,event) {
      this.page = 1
      this.activeName = tab.name
      this.currentActive = this.cates.findIndex(item => item.id==tab.name)
      this.getList()
    },
    // 获取我的发布
    getList() {
      let data = {
        page: this.page,
        limit: this.limit,
        is_user: 1,
        keyword: this.keyword
      }
      let current = this.currentActive
      let url = '/post/ask_buy/getList' //工程设备列表
      if(this.activeName==='1') url = '/post/information/getList' //安装工程列表
      if(this.activeName==='2') url = '/post/post_logistics_freight/getList' //物流货运列表
      if(this.activeName==='3') url = '/post/job_fair/getList' //人才招聘列表
      if(this.activeName==='4') url = '/post/job_intention/getList' //求职信息列表
      if(this.activeName==='5') url = '/post/second_hand/getList' //二手设备列表
      this.isLoading = true
      this.$axios("POST",url,data)
      .then(res => {
        this.isLoading = false
        if(res.code===1) {
          this.cates[current].list = res.data.list
          this.allPage = Math.ceil(res.data.total/this.limit)
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    //切换页数
    changeSize(e) {
      this.page = e
      this.getList();//获取工程设备求购列表
    },
    goDetail(item,type) { //我发布的报价单详情
      if(type===0) { //求购详情
        let path = this.$router.resolve({
          path: '/detail',
          query: {id: item.id}
        })
        window.open(path.href)
      }else if(type===5) { //二手设备详情
        let path = this.$router.resolve({
          path: '/secondhand/detail',
          query: {id: item.id}
        })
        window.open(path.href)
      }

    },
  },
  created() {
    if(this.$store.state.userInfo.firm_type==1) {
      this.currentActive = 1
      this.activeName = '5'
    }
    this.getList() //获取列表
  }
}
</script>

<style scoped>
  .tabs{
    padding: 15px 0 15px 15px;
    border-bottom: 1px solid #E5E5E5;
  }
  .tab_item{
    margin-right: 152px;
    font-size: 26px;
    color: #000000;
    transition: all 0.3s;
  }
  .tab_item.active{
    color: #1572F9;
  }
  .submit_item{
    border-bottom: 1px solid #F2F2F2;
    padding-top: 18px;
    padding-bottom: 15px;
    cursor: pointer;
  }
  /*分类*/
  ::v-deep .el-tabs__item{
    font-size: 28px;
    text-align: center;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  ::v-deep .el-tabs__nav-scroll{
    padding-bottom: 30px;
  }
  ::v-deep .el-tabs__nav-next .el-icon-arrow-right{
    font-size: 30px;
    padding-top: 5px;
  }
  ::v-deep .el-tabs__nav-prev .el-icon-arrow-left{
    font-size: 30px;
    padding-top: 5px;
  }
  ::v-deep .el-tabs__item.is-active{
    color: #1572F9;
  }
  ::v-deep .el-tabs__active-bar{
    bottom: -30px;
    height: 6px;
    background-color: #1572F9;
  }
  ::v-deep .el-tabs__active-bar:after{
    content: '';
    width: 0;
    height: 0;
    border-bottom: 20px solid #1572F9;
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,-100%);
  }
  ::v-deep .el-tabs__nav-wrap::after{
    height: 6px;
  }
</style>