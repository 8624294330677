<template>
  <div style="background-color: #FAFAFA;padding-top: 18px;padding-bottom: 39px;">
    <div class="difb mr0At" style="width: 1200px;">
      <!--左侧选项-->
      <div>
        <div class="left_warp">
          <!--<div class="left_title difcac">个人中心</div>-->
          <div class="left_item difcac" :class="{'active': currentPath===item.path}" v-for="(item,index) in tabs"
               @click="goPath(item)" v-if="isShowItem(item)"
               :key="index">
            <el-badge :value="$store.state.num1" :hidden="item.id!==14 || $store.state.num1<=0">
              {{item.name}}
            </el-badge>
          </div>
        </div>
      </div>
      <!--右侧内容-->
      <div class="right_warp">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "meCenter",
  computed: {
    ...mapState(['userInfo'])
  },
  data() {
    return {
      tabs: [
        {id: 1,name: '个人中心',path: '/me/myInfo'},
        {id: 11,name: '我的提成',path: '/me/myCommission'},
        {id: 14,name: '绑定列表',path: '/me/bindList'},
        {id: 13,name: '采购商列表',path: '/me/purchaser'},
        // {id: 12,name: '购物车',path: '/cart'},
        // {id: 2,name: '我的订单',path: '/me/myOrder'},
        {id: 9,name: '积分订单',path: '/me/integralOrder'},
        {id: 3,name: '我的发布',path: '/me/offer'},
        {id: 4,name: '收货地址',path: '/me/address'},
        // {id: 5,name: '企业入驻',path: '/me/enterprise'},
        // {id: 6,name: '报价表',path: '/me/bidding'},
        {id: 6,name: '我的收藏',path: '/me/myCollect'},
        {id: 7,name: '代理申请',path: '/me/applyAgent'},
        // {id: 8,name: '开通VIP',path: '/me/vip'},
        {id: 10,name: '抵扣包',path: '/me/deduction'},
        {id: 9,name: '联系客服',path: '/me/contact'},
      ],
      currentPath: '/me/myInfo',
    }
  },
  methods: {
    isShowItem(item) {
      if(item.id===13 ) {
        if(this.userInfo.firm_type==1) return true
        return false
      }
      return true
    },
    //路由跳转
    goPath(item) {
      if(localStorage.getItem('test')) {
        localStorage.removeItem('test')
        console.log('删除');
      }else {
        localStorage.setItem('test',new Date().getTime())
        console.log('添加');
      }
      if(item.id===7) { //代理商申请
        this.goAgent(item)
        return
      }
      if(item.id===5) { //企业入驻
        this.goEnterprise(item)
        return
      }
      this.currentPath = item.path
      this.$router.push(item.path)
    },
    //企业入驻申请接口
    goEnterprise(item) {
      let data = {
        path: item.path,
      }
      this.currentPath = '/me/enterprise'
      this.$router.push(data)
      return
      this.$axios("POST","/mchs/checkSub",{})
          .then(res => {
            if(res.code===1) {
              let data = {
                path: item.path,
              }
              this.currentPath = '/me/enterprise'
              this.$router.push(data)
            }else {
              this.$message.error(res.msg)
            }
          })
    },
    //代理商申请接口
    goAgent(item) {
      let data = {
        path: item.path,
      }
      this.currentPath = '/me/applyAgent'
      this.$router.push(data)
      return
      this.$axios("POST","/Agent/checkSub",{})
      .then(res => {
        if(res.code===1) {

          if(res.data.status==2) data.query = {info: res.data.info}
        }else {
          this.$message.error(res.msg)
        }
      })
    },
  },
  created() {
    this.currentPath = this.$route.path
  },
  watch: {
    $route: function (newValue) {
      this.currentPath = this.$route.path
    }
  }
}
</script>

<style scoped>
.left_item ::v-deep.el-badge__content{
  line-height: 16px !important;
}
  .left_warp{
    width: 123px;
    /*height: 803px;*/
    background: #FFFFFF;
    border-radius: 16px 16px 16px 16px;
    border: 1px solid #E2E2E2;
    box-sizing: border-box;
    padding-top: 23px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .left_title{
    width: 90px;
    height: 36px;
    background: #1572F9;
    border-radius: 13px 13px 13px 13px;
    color: #ffffff;
    font-size: 18px;
    margin-bottom: 11px;
  }
  .left_item{
    font-size: 18px;
    color: #000000;
    margin-bottom: 21px;
    cursor: pointer;
    transition: all 0.2s;
  }
  .left_item:hover{
    color: #1572F9;
  }
  .left_item.active{
    color: #1572F9;
  }
  .right_warp{
    width: 1060px;
  }
</style>
