<template>
  <div class="e_warp" style="display: flex;flex-direction: column">
    <div class="dif alc  fs16 col333" style="margin-bottom: 20px;">
      <div class="dif alc pointer hover" @click="goBack()">
        <i class="el-icon-arrow-left"></i>
        <p>返回</p>
      </div>
      <div class="pointer hover" style="color: #fa3534;margin-left: 330px;" @click="delAddress()" v-if="id">删除地址</div>
    </div>
    <div class="e_item dif alc dif alc">
      <div class="e_left">收&nbsp; 货&nbsp;人:</div>
      <div class="e_select">
        <el-input placeholder="请输入姓名" v-model="obj.name"></el-input>
      </div>
    </div>
    <div class="e_item dif alc dif alc">
      <div class="e_left">性&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别:</div>
      <div class="e_select">
        <el-radio :label="1" v-model="obj.sex">男</el-radio>
        <el-radio :label="0" v-model="obj.sex">女</el-radio>
      </div>
    </div>
    <div class="e_item dif alc dif alc">
      <div class="e_left">联系电话:</div>
      <div class="e_select">
        <el-input placeholder="请输入联系电话" v-model="obj.tel" maxlength="11"></el-input>
      </div>
    </div>
    <div class="e_item dif alc dif alc">
      <div class="e_left" @click="openAddress()">收货地址:</div>
      <div class="e_select posr">
        <div class="posr">
          <el-input type="textarea" v-model="address" placeholder="请选择"
                    :autosize="{ minRows: 2, maxRows: 4}"  style="position:relative;z-index: 222;width: 260px;">
          </el-input>
          <div @click="openAddress()" style="position:absolute;top: 0;left: 0;width: 100%;height: 100%;z-index: 333">

          </div>
        </div>
        <el-cascader style="position:absolute;bottom: 0;left: 0;"
            ref="address"
            size="large"
            @change="changeCitys"
            :options="citys"
            v-model="selectedOptions">
        </el-cascader>
      </div>
    </div>
    <div class="e_item dif alc dif alc">
      <div class="e_left">详细地址:</div>
      <div class="e_select">
        <el-input maxlength="250" style="width: 260px;"
                  show-word-limit
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 15}"
            placeholder="请输入详细地址，如道路，门牌号，小区，楼栋号，单元等信息" v-model="obj.address"></el-input>
      </div>
    </div>
    <div class="e_item dif alc dif alc">
      <div class="e_left">是否默认:</div>
      <div class="e_select">
        <el-radio :label="1" v-model="obj.is_default">是</el-radio>
        <el-radio :label="0" v-model="obj.is_default">否</el-radio>
      </div>
    </div>
    <div class="submit_button difcac colfff pointer" @click="saveTimer()">保存</div>
  </div>
</template>

<script>
import { provinceAndCityData, regionData, provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data'
export default {
  name: "addAddress",
  data() {
    return {
      id: '',//地址id
      citys: regionData,
      selectedOptions: [],//选中的地址
      address: '',
      obj: {
        name: '',//收货人
        tel: '',//联系电话
        address: '',//地址
        sca_name: '',//省市区
        sex: 1,//性别 0女 1男
        is_default: 0,//是否默认
      },
      timer: null,
      isButton: true,
    }
  },
  methods: {
    openAddress() {
      this.$refs.address.toggleDropDownVisible(true)
    },
    //选择地址
    changeCitys(e) {
      console.log(e);
      this.address = `${CodeToText[e[0]]}/${CodeToText[e[1]]}/${CodeToText[e[2]]}`
    },
    //删除地址
    delAddress() {
      this.$confirm('确认要删除该地址吗?',{type: 'warning'})
      .then(con => {
        this.$axios("POST","/user/delMyAddress",{address_id: this.id},'删除中')
        .then(res => {
          if(res.code===1) {
            this.$message.success('删除成功')
            setTimeout(()=>{
              this.goBack()
            },1000)
          }else {
            this.$alert(res.msg,{type: 'error'})
          }
        })
      })
    },
    //防抖
    saveTimer() {
      if(!this.isButton) return
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.save()
      },500)
    },
    //保存地址
    save() {
      let data = this.obj
      if(this.id) data.address_id = this.id
      if(data.name==='') {
        this.isButton = true
        return this.$alert('请输入收货人',{type: 'error'})
      }else if(data.tel==='') {
        this.isButton = true
        return this.$alert('请输入联系人',{type: 'error'})
      }else if(this.selectedOptions.length<=0) {
        this.isButton = true
        return this.$alert('请选择收货地址',{type: 'error'})
      }else if(data.address==='') {
        this.isButton = true
        return this.$alert('请输入详细地址',{type: 'error'})
      }
      data.sca_name = `${CodeToText[this.selectedOptions[0]]}-${CodeToText[this.selectedOptions[1]]}-${CodeToText[this.selectedOptions[2]]}`
      this.$axios("POST","/user/updateAddress",data,'保存中')
      .then(res => {
        if(res.code===1) {
          this.$message.success('保存成功')
          this.goBack()
        }else {
          this.isButton = true
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    //返回
    goBack() {
      this.$router.push({
        path: '/me/address'
      })
    },
  },
  created() {
    if(this.$route.query.id) {
      this.id = this.$route.query.id
      this.obj = JSON.parse(localStorage.getItem('hbaddress'))
      if(this.obj.sex==='男') {
        this.obj.sex = 1
      }else {
        this.obj.sex = 0
      }
      //处理地址数据
      let arr = this.obj.sca_name.split('-')
      this.selectedOptions = [
          TextToCode[arr[0]].code,
          TextToCode[arr[0]][arr[1]].code,
          TextToCode[arr[0]][arr[1]][arr[2]].code,
      ]
    }
  }
}
</script>

<style scoped>
.e_warp{
  width: 100%;
  height: 803px;
  background: #FFFFFF;
  box-sizing: border-box;
  padding-left: 36px;
  padding-top: 29px;
}
.e_left{
  font-size: 16px;
  color: #000000;
  margin-right: 28px;
}
.e_item{
  border-bottom: 1px dashed #EEEEEE;
  padding: 13px 61px;
  box-sizing: border-box;
  width: 490px;
}
.e_select ::v-deep.el-input__inner{
  width: 260px;
  height: 38px;
  background: #FFFFFF;
  border-radius: 2px 2px 2px 2px;
  border: 1px solid #D2D2D2;
  box-sizing: border-box;
  padding: 0 17px;
  font-size: 16px;
}
.submit_button{
  width: 443px;
  height: 65px;
  background: #007AFF;
  border-radius: 4px 4px 4px 4px;
  font-size: 20px;
  margin-top: 45px;
  transition: all 0.3s;
}
.submit_button:hover{
  background: #3A53FF;
}
</style>