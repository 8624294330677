<template>
  <div class="w-900">
    <!--轮播图-->
    <div class="swiper">
      <el-carousel height="237px" v-if="swiperList.length<=0">
        <el-carousel-item v-for="item in swiper" :key="item.id">
          <img :src="item.slideimage" class="w100 h100 bgcb" @click="goSwiper(item)">
        </el-carousel-item>
      </el-carousel>
      <!--商家轮播图-->
      <el-carousel height="237px" v-else>
        <el-carousel-item v-for="item in swiperList" :key="item.id">
          <img :src="item" class="w100 h100 bgcb" >
        </el-carousel-item>
      </el-carousel>
    </div>
    <!--logo-->
    <div class="difb alc mt5">
      <img :src="item.slideimage" @click="goSwiper(item)" class="block pointer w-447 h-70" v-for="(item,index) in logos" :key="item.id">
    </div>
    <!--推荐商家-->
    <div class="dif shadow_bottom box" v-if="referMerchants.length>0 && showShops" style="padding-left: 5px;padding-bottom: 8px;">
      <template>
        <div class="shop_item3" v-for="(item,index) in referMerchants" :key="item.id" @click="goShopDetail(item)">
          <img :src="item.cover_image" class="w100" style="display: block;height: 215px;" :title="item.name" :alt="item.name">
          <div class="shop_cont posr">
            <div class="shop_vip difcac" v-if="item.vip_level==2">VIP商户</div>
            <div class="shop_vip difcac" style="color: #000" v-if="item.vip_level==3">钻石VIP商户</div>
            <!--<div class="shop_vip_black difcac" v-if="item.vip_level==3">钻石VIP商户</div>-->
            <p class="one_overflow col333 bold fs14">{{item.name}}</p>
            <p class="one_overflow col333" style="font-size: 12px;margin-top: 4px;">{{item.info_address}}</p>
          </div>
        </div>
        <!--<div class="shop_item2" v-for="(item,index) in referMerchants" :key="item.id" @click="goShopDetail(item)" style="box-shadow: 0px 4px 10px 1px rgba(0,0,0,0.2);"></div>-->
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "swiperContent",
  props: {
    module: {
      type: Number,
      default: 1
    },
    showShops: {
      type: Boolean,
      default: true
    },
    swiperList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      swiper: [],//轮播图
      logos: [],//logo
      referMerchants: [],//推荐商家
    }
  },
  methods: {
    //点击轮播图跳转
    goSwiper(item) {
      if(item.is_jump==2) {
        this.$router.push({
          path: '/shopDetail',
          query: {id: item.mch_id}
        })
      }
    },
    //商户详情
    goShopDetail(item) {
      this.$router.push({
        path: '/shopDetail',
        query: {
          id: item.id
        }
      })
    },
    //获取轮播图
    getSwiper() {
      this.$axios("POST","/index/getRotationMap",{module: this.module})
          .then(res => {
            if(res.code===1) this.swiper = res.data
          })
      this.$axios("POST","/index/getRotationMap",{module: 12})
          .then(res => {
            if(res.code===1) this.logos = res.data
          })
      this.$axios("POST","/mchs/getList",{
        page: 1, limit: 4,is_top: 1
      }).then(res => {
        this.referMerchants = res.data.list
      })
    },
  },
  mounted() {
    this.getSwiper()
  }
}
</script>

<style scoped>
.shop_item2{
  margin-right: 10px;
  margin-top: 5px;
  width: 220px;
  height: 80px;
  background: #FFF;
  box-sizing: border-box;
  cursor: pointer;

}
.shop_item2:nth-last-child(1) {
  margin-right: 0;
}
.swiper{
  /*width: 100%;*/
  width: 900px;
}
.shop_warp{
  width: 1200px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.3);
  padding: 20px;
  padding-bottom: 15px;
}
.shop_item3{
  margin-top: 5px;
  width: 215px;
  overflow: hidden;
  /*height: 215px;*/
  margin-right: 10px;
  position: relative;
  box-shadow: 0px 4px 10px 1px rgba(0,0,0,0.2);
  cursor: pointer;
}
.shop_item3 img{
  transition: all 0.3s;
}
.shop_item3:hover img{
  transform: scale(1.1);
}
.shop_item3:nth-last-child(1) {
  margin-right: 0;
}
.shop_vip{
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-100%);
  /*width: 57px;*/
  height: 29px;
  background: #FFAA00;
  border-radius: 8px 0px 0px 0px;
  font-size: 12px;
  color: #FFFFFF;
  padding: 0 6px;
}
.shop_vip_black{
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-100%);
  height: 29px;
  background: #1C1C1C;
  border-radius: 8px 0px 0px 0px;
  font-size: 12px;
  color: gold;
  padding: 0 6px;
}
.shop_cont{
  width: 100%;
  height: 50px;
  /*position: absolute;*/
  /*bottom: 0;*/
  /*left: 0;*/
  padding: 6px 8px 5px 8px;
  box-sizing: border-box;
  background: rgba(255, 255, 255,1);
}
.shop_item:nth-child(5n) {
  margin-right: 0;
}
</style>