import Vue from 'vue'
import Vuex from 'vuex'
import request from "@/assets/js/axios";
import router from "@/router";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // imgUrl: 'https://web.dzhbzg.cn',//图片路径
    imgUrl: 'https://huibangzhonggong.oss-cn-beijing.aliyuncs.com',//图片路径
    upLoadUrl: 'https://web.dzhbzg.cn/api/common/upload',//上传路径
    zIndex: 333,
    token: null,
    userInfo: {nickname:''},//用户信息
    code: '', //市级code
    address: '', //市级
    total_num: 0,//全部角标
    num1: 0,//绑定记录角标
  },
  mutations: {
    setAddress(state,payload) {
      state.address = payload.address
      state.code = payload.code
      localStorage.setItem('hb_address',payload.address)
      localStorage.setItem('hb_code',payload.code)
    },
    setIndex(state,payload) { //设置组件层级
      state.zIndex = payload.index
    },
    setToken(state,payload) { //设置token
      state.token = payload.token
    },
    setUserInfo(state,payload) { //设置用户信息
      state.userInfo = payload
      localStorage.setItem('hbInfo',JSON.stringify(payload))
    },
    logout(state) { //退出登录
      state.userInfo = {nickname:''}
      state.token = null
    },
  },
  actions: {
    initJs() {
      this.$axios = request
    },
    async getBadge({state,dispatch,commit},data) {
      let res =  await request("POST","/user/getBadge",{})
      if(res && res.code===1) {
        state.total_num = res.data.total_num
        state.num1 = res.data.num1
      }
      return res.data
    },
    async getUserInfo({state,dispatch,commit},data) {
      let res =  await request("POST","/user/getUserInfo",{})
      if(res && res.code===1) {
        commit('setUserInfo',res.data)
        if(!data) {
          if(res.data.audit_status !==2) {
            // router.replace('/Exstatus').catch(err => err)
          }
        }
      }else {
        if(!data) {
          router.replace('/login?type=0').catch(err => err)
        }
      }
      return res.data
    },
  },
  modules: {

  }
})
