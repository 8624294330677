<template>
  <div>
    <!--用户信息-->
    <div id="myInfo" v-loading="isLoading" v-if="!shwoTixian">
      <div class="top1 "  >
        <div class="dif alc">
          <el-upload
              class="upLoad_warp"
              :action="$store.state.upLoadUrl"
              :show-file-list="false"
              :headers="{'token': token}"
              name="file"
              :on-progress="uploading"
              :on-success="uploadSuccess"
          >
            <div class="avatar one_overflow">
              <img :src="userInfo.avatar" alt="" class="avatar" style="margin-bottom: 0;">
            </div>
          </el-upload>
          <!--用户信息-->
          <div style="margin-left: 40px;">
            <div class="dif alc">
              <div style="width: 105px;">
                <span class="text-red">*</span>
                用户昵称：</div>
              <div>
                <el-input placeholder="请输入昵称" v-model="userInfo.nickname"></el-input>
              </div>
            </div>
<!--            <div class="dif alc mrTop10">
              <div style="width: 105px;">
                <span class="text-red">*</span>
                联系电话：</div>
              <div>
                <el-input placeholder="请输入联系电话" v-model="userInfo.link_mobile"></el-input>
              </div>
            </div>-->
            <!--<div class="dif alc mrTop10 ">
              <div style="width: 105px;">
                <span class="text-red">*</span>
                联系地址：</div>
              <div>
                <el-input placeholder="请输入联系地址" v-model="userInfo.link_address"></el-input>
              </div>
            </div>-->
            <div class="dif alc mrTop10">
              <div style="width: 105px;">
                <span class="text-red">*</span>
                行业分类：</div>
              <div>
                <el-input placeholder="请选择行业分类" disabled v-model="userInfo.industry_name"></el-input>
                <!--<el-select disabled v-model="userInfo.industry_id" placeholder="请选择行业分类" style="width: 187px;">
                  <el-option v-for="item of cates" :label="item.name" :value="item.id"></el-option>
                </el-select>-->
              </div>
            </div>
            <el-button type="primary" size=" " @click="save()" style="margin-left: 120px;margin-top: 20px;">保 存</el-button>
          </div>
          <!--联系电话-->
          <div style="margin-left: 40px;">
            <div class="dif alc">
              <div style="width: 105px;">
                <span class="text-red">*</span>
                联系电话：</div>
              <div>
                <el-input placeholder="请输入联系电话" v-model="userInfo.link_mobile"></el-input>
              </div>
            </div>
            <div class="dif alc mrTop10">
              <div style="width: 105px;">
                <span class="text-red">*</span>
                验证码：</div>
              <div class="dif alc">
                <el-input placeholder="请输入验证码" v-model="captcha" style="margin-right: 10px;"></el-input>
                <el-button type="primary" size=" " @click="getCode()" >
                  <span v-if="time===31">获取验证码</span>
                  <span v-else>{{time}}秒后重试</span>
                </el-button>
              </div>
            </div>
            <el-button type="primary" size=" " @click="saveMobile()" style="margin-left: 120px;margin-top: 20px;">保 存</el-button>
          </div>

        </div>
        <div class="difcc pdRit30 ">
          <div class="dif alc mrTop20" style="margin-left: 25px;"  v-if="userInfo.firm_type==2">
            <p class="fs20 mb5 mr20">您的邀请码：{{userInfo.invite_code}}</p>
            <el-button type="primary" @click="copy(userInfo.share_url)">复制链接</el-button>
          </div>
          <div class="dif alc  mrTop20" style="margin-left: 25px;" v-if="userInfo.agent_level>0">
            <p class="fs20 mb5 mr20">代理商邀请码：{{userInfo.invite_code}}</p>
            <el-button type="primary" @click="copy(userInfo.invite_code)">复制邀请码</el-button>
          </div>
        </div>
      </div>
      <div class="top2 difb alc">
        <div class="dif alc">
          <div class="difcc">
            <div style="color:#D64040;font-size: 54px;">￥{{userInfo.money}}</div>
            <div style="margin-top: 26px;" class="fs32 col000">余额</div>
          </div>
          <div class="difcc" style="margin-left: 50px;">
            <div style="color:#D64040;font-size: 54px;">{{userInfo.score}}</div>
            <div style="margin-top: 26px;" class="fs32 col000">积分</div>
          </div>
        </div>
        <div style="margin-right: 30px;color: #3765FF" class="pointer fs20" @click="shwoTixian=true">申请提现</div>
      </div>
    </div>
    <!--提现-->
    <div class="e_warp" style="display: flex;flex-direction: column" v-loading="showLoading" v-else>
      <div class="dif alc pointer fs16 col666 mrBtm30 mrLf10" @click="shwoTixian=false">
        <i class="el-icon-arrow-left"></i>
        <p>返回</p>
      </div>
      <div class="e_item dif alc dif alc">
        <div class="e_left">提现金额:</div>
        <div class="e_select">
          <el-input placeholder="请输入提现金额" type="number" v-model="obj.amount" :maxlength="20"></el-input>
        </div>
      </div>
      <div class="e_item dif alc dif alc">
        <div class="e_left">开户银行:</div>
        <div class="e_select">
          <el-input placeholder="请输入开户银行" type="text" v-model="obj.bank" maxlength="15"></el-input>
        </div>
      </div>
      <div class="e_item dif alc dif alc">
        <div class="e_left">银行卡号:</div>
        <div class="e_select">
          <el-input placeholder="请输入银行卡号" type="text" v-model="obj.bank_id" maxlength="20"></el-input>
        </div>
      </div>
      <div class="e_item dif alc dif alc">
        <div class="e_left">真实姓名:</div>
        <div class="e_select">
          <el-input placeholder="请输入姓名" type="text" v-model="obj.name" maxlength="15"></el-input>
        </div>
      </div>
      <div class="e_item dif alc dif alc">
        <div class="e_left">联系电话:</div>
        <div class="e_select">
          <el-input placeholder="请输入联系电话" type="text" :maxlength="11" v-model="obj.tel"></el-input>
        </div>
      </div>
      <div class="submit_button difcac colfff pointer" @click="submit()">提交申请</div>
    </div>
    <el-dialog
        :visible.sync="showCode"
        width="30%"
        center>
      <div class="difcc">
        <img  :src="userInfo.qr_code" style="width: 100%;height: auto">
        <p class="fs22">扫码分享</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "myInfo",
  data() {
    return {
      showCode: false,
      isLoading: false,//是否加载3
      showLoading: false,
      token: '',
      userInfo: {
        nickname: '',//昵称
        avatar: '',//头像
        link_mobile: '',
        money: '',//余额
        link_address: '',//联系地址
        // industry_id: '',//行业id
        industry_name: '',//行业名称
      },
      captcha: '',//验证码
      time: 31,
      timeout: null, //验证码计时器
      obj: {
        amount: '',//提现金额
        bank: '',//开户银行
        bank_id: '',//银行卡号
        name: '',//姓名
        tel: '',//手机号
      }, //提现表单
      shwoTixian: false,
      cates: [],//行业分类
    }
  },
  watch: {
    userInfo: {
      handler(newValue) {
        this.userInfo.nickname = newValue.nickname.replace(/[^a-zA-Z0-9\u4E00-\u9FA5]/g, "")
      },
      deep: true
    }
  },
  methods: {
    saveMobile() {
      let data = {
        mobile: this.userInfo.link_mobile,
        captcha: this.captcha
      }
      this.$axios("POST","/user/changemobile",data,'保存中')
          .then(res => {
            if(res.code===1) {
              this.$alert('保存成功',{type: 'success'})
              this.shwoTixian = false
            }else {
              this.$alert(res.msg,{type: 'error'})
            }
          })
    },
    getCode() {
      let data = {
        mobile: this.userInfo.link_mobile,
        event: 'changemobile'
      }
      clearInterval(this.timeout)
      this.$axios("POST","/sms/send",data,'发送中')
          .then(res => {
            if(res.code===1) {
              this.timeout = setInterval(()=>{
                this.time--
                if(this.time<=0) {
                  this.time = 31
                  clearInterval(this.timeout)
                }
              },1000)
              this.$message.success('发送成功')
            }else {
              this.$alert(res.msg,{type: 'error'})
            }
          })
    },
    copy(value) {
      let input = document.createElement('input')
      input.value = value
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.body.removeChild(input)
      this.$alert('复制成功',{type: 'success'})
    },
    // 申请提现
    submit() {
      let data = this.obj
      this.showLoading = true
      this.$axios("POST","/Withdraw/sub",data)
      .then(res => {
        this.showLoading = false
        if(res.code===1) {
          this.$alert('申请成功',{type: 'success'})
          .then(con => {
            this.shwoTixian = false
          })
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    //获取行业分类
    getCates() {
      this.$axios("POST","/index/getIndustry",{})
          .then(res => {
            if(res.code===1) {
              this.cates = res.data
              let obj = this.cates.find(item => {
                return this.userInfo.industry_name===item.name
              })
              if(obj) {this.userInfo.industry_id = obj.id}else {this.userInfo.industry_id = res.data[0].id}
            }
          })
    },
    //保存用户信息
    save() {
      let data = {
        nickname: this.userInfo.nickname,
        link_address: this.userInfo.link_address,
        avatar: this.userInfo.avatar,
        industry_id: this.userInfo.industry_id || '',
        // link_mobile: this.userInfo.link_mobile,
      }
      this.isLoading = true
      this.$axios("POST","/user/profile",data)
      .then(res => {
        this.isLoading = false
        if(res.code===1) {
          this.$message.success('保存成功')
          this.getUserInfo()
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    //上传中
    uploading() {
      this.isLoading = true
    },
    //上传头像
    uploadSuccess(res) {
      this.isLoading = false
      this.userInfo.avatar = res.data.fullurl
    },
    //获取用户信息
    getUserInfo() {
      this.token = localStorage.getItem('hbzg')
      try{
        this.userInfo = JSON.parse(localStorage.getItem('hbInfo'))
      }catch (e) {}
      this.$axios("POST","/user/getUserInfo",{})
          .then(res => {
            if(res.code===1) {
              localStorage.setItem('hbInfo',JSON.stringify(res.data))
              this.$store.commit('setUserInfo',res.data)
              this.userInfo = JSON.parse(JSON.stringify(res.data))
              this.getCates() //获取行业分类
            }else {
              localStorage.removeItem('hbzg')
              this.$alert('获取用户信息失败，请刷新重试',{type: 'error'})
            }
          })
    },
  },
  created() {
    this.getUserInfo() //获取用户信息
  }
}
</script>

<style scoped>
  .avatar{
    border-radius: 50%;
    width: 140px;
    height: 140px;
    background-color: #EEEEEE;
    margin-bottom: 22px;
  }
  .top1{
    width: 100%;
    padding-bottom: 10px;
    background: #FFFFFF;
    border-radius: 16px 16px 16px 16px;
    border: 1px solid #E2E2E2;
    margin-bottom: 27px;
    box-sizing: border-box;
    padding-top: 15px;
    padding-left: 50px;
  }
  .top2{
    width: 100%;
    height: 234px;
    background: #FFFFFF;
    border-radius: 16px 16px 16px 16px;
    border: 1px solid #E2E2E2;
    box-sizing: border-box;
    padding-left: 50px;
  }

  .e_warp{
    //width: 1114px;
    width: 100%;
    height: 803px;
    background: #FFFFFF;
    box-sizing: border-box;
    padding-left: 36px;
    padding-top: 49px;
  }
  .e_left{
    font-size: 16px;
    color: #000000;
    margin-right: 28px;
  }
  .e_item{
    border-bottom: 1px dashed #EEEEEE;
    padding: 13px 61px;
    box-sizing: border-box;
    width: 490px;
  }
  .e_select ::v-deep.el-input__inner{
    width: 260px;
    height: 38px;
    background: #FFFFFF;
    border-radius: 2px 2px 2px 2px;
    border: 1px solid #D2D2D2;
    box-sizing: border-box;
    padding: 0 17px;
    font-size: 16px;
  }
  .submit_button{
    width: 443px;
    height: 65px;
    background: #007AFF;
    border-radius: 4px 4px 4px 4px;
    font-size: 20px;
    margin-top: 45px;
    transition: all 0.3s;
  }
  .submit_button:hover{
    background: #3A53FF;
  }

</style>