<template>
  <div>
    <div class="difb">
      <!--左侧大图片-->
      <div class="login_left_bg posr">
        <img src="@/assets/images/icon_dlbj.png">
      </div>
      <!--右侧审核内容-->
      <div class="difcc posr" style="width: 35vw;">
        <div class="dif alc homego" @click="logout()">
          <p>返回首页</p>
          <i class="el-icon-arrow-right" style="color: #666666; font-size: 16px;"></i>
        </div>
        <!--内容-->
        <transition enter-active-class="animation-scale-up">
          <div class="difcc posa">
            <div style="width: 30.94rem;margin-bottom: 2.19rem;border-bottom: 1px solid #888888;padding-bottom: 0.5rem;" class="difb alc">
              <span style="font-size: 42px;color: #1572F9" class="bold">审核状态</span>
            </div>
            <div class="mt50 mb250">
              <!--审核中-->
              <el-result icon="info" title="审核中" subTitle="请耐心等待" v-if="userInfo && userInfo.audit_status === 1"></el-result>
              <!--驳回-->
              <el-result icon="error" title="审核未通过" :subTitle="userInfo.review_result" v-if="userInfo && userInfo.audit_status === 3">
                <template slot="extra">
                  <el-button type="primary" size="medium" @click="goSubmit()">重新提交</el-button>
                </template>
              </el-result>
              <div class="tc" v-if="userInfo && (userInfo.audit_status === 3 || userInfo.audit_status === 1)">有任何问题可联系平台售后13356261927</div>

            </div>
          </div>
        </transition>
        <a href="https://beian.miit.gov.cn" target="_blank" style="color: #333;text-decoration: none" class="beiuan" v-if="config.beian"> {{config.beian}}</a>
      </div>
    </div>
    <!--重新提交审核-->
    <el-dialog title="重新提交" top="5vh" :visible.sync="showSubmit">
      <div>
        <enterprise :isComponents="true"></enterprise>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { provinceAndCityData, regionData, provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data'
import {mapState}  from "vuex";
import Enterprise from "@/views/meCenter/right/enterprise.vue";
export default {
  name: "Exstatus",
  components: {Enterprise},
  computed: {
    ...mapState(['userInfo'])
  },
  data() {
    return {
      showSubmit: false,
      config: {
        name: '',//站点名称
        beian: '',//备案
        address: '',//公司地址
        postcode: '',//邮编
      },
    }
  },
  methods: {
    //退出登录
    logout() {
      this.$router.push('/shops')
      /*this.$confirm('确认要退出该账户吗？',{type: 'info'})
          .then(con => {
            localStorage.removeItem('hbzg')
            localStorage.removeItem('hbInfo')
            this.$store.commit('logout')
            this.$router.push({
              path: '/login',
              query: {type: 0}
            })
          })*/
    },
    goSubmit() {
      // this.$router.push('/me/enterprise')
      this.showSubmit = true
    },
    //获取用户信息
    getUserInfo() {
      this.$axios("POST","/user/getUserInfo",{})
      .then(res => {
        if(res.code===1) {
          localStorage.setItem('hbInfo',JSON.stringify(res.data))
          this.$store.commit('setUserInfo',res.data)
        }else {
          localStorage.removeItem('hbzg')
          this.$alert('获取用户信息失败，请重试',{type: 'error'})
        }
      })
    },
    getConfig() { //获取系统配置
      this.$axios("POST","/index/getConfig",{})
          .then(res => {
            if(res.code===1) this.config = res.data
          })
    },
  },
  created() {
    this.getConfig()
    this.getUserInfo()
  }
}
</script>

<style scoped>
  .beiuan{
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translate(-50%);
  }
  .homego {
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 16px;
    color: #666666;
    cursor: pointer;
  }
  .login_left_bg{
    width: 65vw;
    height: 100vh;
    overflow: hidden;
  }
  .login_left_bg img{
    width:  100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .hover1:hover{
    text-decoration: none;
  }
  .login_tab{
    font-size: 1.5rem;
    color: #000000;
    transition: all 0.3s;
    cursor: pointer;
  }
  .login_tab.active{
    color: #1572F9;
    font-weight: bold;
    position: relative;
  }
  .login_tab.active::before{
    content: '';
    background-color: #1572F9;
    display: block;
    position: absolute;
    bottom: -10px;
    width: 50%;
    height: 3px;
    left: 50%;
    transform: translate(-50%);
  }
  .input_warp,.input_warp2{
    width: 25.69rem;
    margin-top: 1.44rem;
  }
  .input_warp2{
    margin-top: 1rem;
  }
  .input_warp ::v-deep.el-input__inner{
    width: 100%;
    height: 3.46rem;
    background: #FFFFFF;
    border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
    border: 0.13rem solid #DCDFE6;
    font-size: 18px;
  }
  .input_warp2 ::v-deep.el-input__inner{
    width: 100%;
    height: 2.96rem;
    background: #FFFFFF;
    border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
    border: 0.13rem solid #DCDFE6;
    font-size: 15px;
  }
  .login_button{
    width: 443px;
    height: 65px;
    background: #007AFF;
    border-radius: 4px 4px 4px 4px;
    font-size: 20px;
    color: #ffffff;
    cursor: pointer;
  }
  .login_button.gray{
    background-color: #666666;
  }
  .getCode{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 25px;
    color: #1572F9;
    font-size: 18px;
    z-index: 22;
    cursor: pointer;
  }
  .checked{
    width: 20px;
    height: 19px;
    cursor: pointer;
  }
  .registger_upload ::v-deep.el-upload--picture-card {
    width: 100px !important;
    height: 100px !important;
    line-height: 100px;
  }
  .registger_upload ::v-deep.el-upload-list__item {
    width: 100px;
    height: 100px;
  }
</style>