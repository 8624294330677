<template>
  <el-dialog :visible.sync="dialogVisible"  center title="申请信息" width="30vw" z-index="85">
    <div style="max-height: 50vh;overflow-y: auto">
      <el-descriptions class="margin-top"  :column="1" border lab>
        <el-descriptions-item label="公司名称">{{form.company_name}}</el-descriptions-item>
        <el-descriptions-item label="姓名">{{form.name}}</el-descriptions-item>
        <el-descriptions-item label="联系电话" >{{form.phone}}</el-descriptions-item>
        <el-descriptions-item label="公司简介">{{form.company_profile}}</el-descriptions-item>
        <el-descriptions-item label="公司资质">
          <div class="apply_upload2 dif difw " v-viewer>
            <div class="w-100 mt10 mr10 h-100 pointer" style="border: 1px solid #999;border-radius: 5px;overflow:hidden;" v-for="(item,index) in form.certification_images">
              <img :src="item"  class="block w100 h100">
            </div>
          </div>
        </el-descriptions-item>
      </el-descriptions>

    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "applyInfo",
  data() {
    return {
      dialogVisible: false,
      form: {
        company_name: '',//公司名称
        name: '',//姓名
        phone: '',//联系电话
        company_profile: '',//公司简介
        certification_images: '',//公司资质（图片）
      },
      mch_id: '',
      fileList: [],//营业执照
    }
  },
  methods: {
    applyBind() {
      let data = {
        mch_id: this.mch_id,
        ...this.form
      }
      data.certification_images = this.fileList.map(item => item.response.data.url).join(',')
      this.$axios("POST","/bind/applyBind",data)
          .then(res => {
            if(res.code===1) {
              this.dialogVisible = false
              this.$alert('申请成功，请等待审核')
                .then(e => {
                  this.$emit('applySuccess')
                }).catch(e => {
                  this.$emit('applySuccess')
                })
            }else {
              this.$alert(res.msg,{type: 'error'})
            }
          })
    },
    show(item) {
      this.form = item
      // 图片回显
      let imgarr = []
      if(item.certification_images && item.certification_images.length>0) {
        for(let value of item.certification_images) {
          imgarr.push({
            url:  value,
            response: {
              code: 1,
              data: {url: value}
            }
          })
        }
        this.fileList = imgarr
      }
      this.dialogVisible = true
    },
    onSuccess(res, file, fileList) {
      this.fileList = fileList
    },
    onRemove(file,fileList) {
      this.fileList = fileList
    },
  }
}
</script>

<style scoped>
.apply_upload2 ::v-deep.el-upload--picture-card {
  width: 100px !important;
  height: 100px !important;
  line-height: 100px;
}
.apply_upload2 ::v-deep.el-upload-list__item {
  width: 100px;
  height: 100px;
}
.apply_upload2 ::v-deep .el-upload--picture-card {
  display: none;
}
</style>