<template>
  <div>
    <div class="dif alc mt10 mb10">
      <div class="w-400  mr20">
        <el-input clearable @clear="clickSearch" placeholder="请输入搜索内容" @keyup.enter.native="clickSearch" v-model="keyword" ></el-input>
      </div>
      <el-button type="primary" icon="el-icon-search" @click="clickSearch()">搜索</el-button>
    </div>
    <el-table :data="list"  border  style="width: 100%" >
      <el-table-column align="center" label="供货商名称" width="100">
        <template slot-scope="scope">
          <span>{{scope.row.company_name}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="姓名" width="100">
        <template slot-scope="scope">
          <span>{{scope.row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="联系电话" width="150">
        <template slot-scope="scope">
          <span>{{scope.row.phone}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="公司简介" >
        <template slot-scope="scope">
          <span>{{scope.row.company_profile}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="公司资质"  width="210">
        <template slot-scope="scope">
          <div class="dif difw" v-viewer style="justify-content: center">
            <img class="pointer mr10 mb10 w-50 h-50" v-for="(item,index) in scope.row.certification_images" :key="index" :src="item" >
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createtime" align="center" label="申请时间" width="110"></el-table-column>
      <el-table-column  align="center" label="驳回原因" width="180">
        <template slot-scope="scope">
          <span v-if="scope.row.status==4">{{scope.row.reason1}}</span>
          <span v-else-if="scope.row.status==5">{{scope.row.reason2}}</span>
          <span v-else>无</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态" width="190">
        <template slot-scope="scope">
          <div v-if="scope.row.status==1">
            <el-button size="mini"  type="danger" @click="showReject=true;reason='';setId=scope.row.id"><span class="fs14">驳回</span></el-button>
            <el-button size="mini"  type="success" @click="passed(scope.row)"><span class="fs14">通过</span></el-button>
          </div>
          <el-button size="mini" v-if="scope.row.status==2" type="warning"><span class="fs14">待后台审核</span></el-button>
          <el-button size="mini" v-if="scope.row.status==3" type="success"><span class="fs14">审核通过</span></el-button>
          <el-button size="mini" v-if="scope.row.status==4" type="danger"><span class="fs14">已驳回</span></el-button>
          <el-button size="mini" v-if="scope.row.status==5" type="danger"><span class="fs14">平台审核驳回</span></el-button>
        </template>
      </el-table-column>
    </el-table>
    <apply-bind @applySuccess="getList" ref="applyBind"></apply-bind>
    <apply-info ref="applyInfo"></apply-info>
    <!--驳回-->
    <el-dialog title="驳回" :visible.sync="showReject" width="30%" center>
      <el-form label-width="80px">
        <el-form-item label="驳回原因">
          <el-input v-model="reason" row="4" placeholder="请输入驳回原因" :autosize="{ minRows: 4}" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showReject=false">取 消</el-button>
        <el-button type="primary" @click="reject()">驳 回</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ApplyBind from "@/views/meCenter/right/components/applyBind.vue";
import ApplyInfo from "@/views/meCenter/right/components/applyInfo.vue";

export default {
  name: "purchaserBindList",
  components: {ApplyInfo, ApplyBind},
  data() {
    return {
      list: [],
      reason: '',//驳回原因
      showReject: false,
      setId: '',
      keyword: '',
    }
  },
  methods: {
    showInfo(item) { //查看申请信息
      this.$refs.applyInfo.show(item)
    },
    apply(item) { //申请绑定
      this.$refs.applyBind.show(item)
    },
    clickSearch() {
      this.getList()
    },
    showImg(item) {
      this.$viewerApi({
        images: [item.logo_image]
      })
    },
    getList() {
      this.$axios("POST","/bind/getCList",{keyword: this.keyword})
          .then(res => {
            this.list = res.data.list
            this.total = res.data.total
            this.$store.dispatch('getBadge')
          })
    },
    reject() {
      this.$confirm('确认要驳回吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios("POST","/bind/auditFail",{id:this.setId,reason: this.reason})
            .then(res => {
              if(res.code===1) {
                this.$message.success('驳回成功')
                this.getList()
                this.showReject = false
                this.reason = ''
              }else {
                this.$alert(res.msg,{type: 'error'})
              }
            })
      })
    },
    passed(item) { //通过
      this.$confirm('确定通过审核吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios("POST","/bind/auditSucc",{id:item.id})
            .then(res => {
              if(res.code===1) {
                this.$message.success('审核成功')
                this.getList()
              }else {
                this.$alert(res.msg,{type: 'error'})
              }
            })
      })
    },
  },
  mounted() {
    this.getList()
  }
}

</script>

<style scoped>

</style>