import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './assets/css/oneoneBootstrap.css'
import './assets/css/common.scss' //公共sass
import './assets/css/animation.css' //color ui 动画库
// import 'animate.css' //很全的动画库 npm i animate.css
import request from "@/assets/js/axios";
import all from "@/assets/js/all";
Vue.mixin(require('./assets/js/information.js'));//文件注入
Vue.prototype.$axios = request;
Vue.prototype.$all = all;

Vue.config.productionTip = false
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.use(Viewer)
//鼠标横向拖动 滚动
Vue.directive('dragscroll', function (el) {
  el.onmousedown = function (ev) {
    const disX = ev.clientX
    const disY = ev.clientY
    const originalScrollLeft = el.scrollLeft
    const originalScrollTop = el.scrollTop
    const originalScrollBehavior = el.style['scroll-behavior']
    const originalPointerEvents = el.style['pointer-events']
    el.style['scroll-behavior'] = 'auto'
    // 鼠标移动事件是监听的整个document，这样可以使鼠标能够在元素外部移动的时候也能实现拖动
    document.onmousemove = function (ev) {
      ev.preventDefault()
      const distanceX = ev.clientX - disX
      const distanceY = ev.clientY - disY
      el.scrollTo(originalScrollLeft - distanceX, originalScrollTop - distanceY)
      // 由于我们的图片本身有点击效果，所以需要在鼠标拖动的时候将点击事件屏蔽掉
      el.style['pointer-events'] = 'none'
    }
    document.onmouseup = function () {
      document.onmousemove = null
      document.onmouseup = null
      el.style['scroll-behavior'] = originalScrollBehavior
      el.style['pointer-events'] = originalPointerEvents
    }
  }
})

window.eventBus = new Vue();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
