<template>
  <div style="min-height: 80vh">
    <div style="width: 1200px;padding-top: 41px;" class="mr0At">
      <!--全选-->
      <!--<div class="pl15" style="margin-bottom: 13px;">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">
          <p class="fs14 col000">全部商品</p>
        </el-checkbox>
      </div>-->
      <!--商品列表-->
        <div class="" v-if="list.length>0">
          <div v-for="(item2,index2) in list" :key="index2" class="cart_warp">
            <div class="box pdLft10 pdTop10 pdBtm10  fs14 col333 bgccc">
              <el-checkbox v-model="item2.check" @change="handleCheckAllChange($event,item2)">
                <p class="fs14 col000">{{item2.mch_name}}</p>
              </el-checkbox>
            </div>
            <el-checkbox-group v-model="select" @change="handleCheckedCitiesChange" style="width: 100%;">
              <div class="dif alc cart_item" v-for="(item,index) in item2.list" :key="index">
                <!--选择框-->
                <el-checkbox  :label="item.id">{{''}}</el-checkbox>
                <!--删除-->
                <div class="pointer hover del" @click="delCart(item)">删除</div>
                <!--图片-->
                <img :src="item.good_image" class="cart_img" :title="item.good_name">
                <!--商品名称-->
                <div class="two_overflow fs14 col333" style="width: 200px;word-break: break-all;white-space: normal">
                  {{item.good_name}}
                </div>
                <div class="two_overflow fs14 col333 tct" style="width: 200px;word-break: break-all;white-space: normal">
                  {{item.attr_txt}}
                </div>
                <!--单价-->
                <div class="tct fs20" style="color: #414141;width: 160px;">{{item.good_price}}</div>
                <!--数量选择-->
                <div style="margin-left: 70px;position:relative;z-index: 11" @click.stop>
                  <el-input-number size="mini" :min="1" v-model="item.num"
                                   @change="(e,old) => changeNum(e,old,item)"
                                   @keydown.native="channelInputLimit"></el-input-number>
                </div>
                <!--价格-->
                <div class="tct fs24" style="color: #D64040;width: 200px;margin-left: 30px;">￥{{(item.num*item.good_price).toFixed(2)}}</div>
              </div>
            </el-checkbox-group>

          </div>
          <!--合计数量-->
          <div class="heji dife alc">
            <span class="pointer hover" v-if="getPrice>0" @click="coudan()">共计{{select.length}}件，还差{{getPrice}}元包邮   去凑单></span>
            <span class="pointer hover" v-else>共计{{select.length}}件，满{{full_money}}包邮></span>
          </div>
        </div>
        <div v-else style="padding: 100px 0;">
          <el-result icon="info" title="购物车空空如也~"></el-result>
        </div>
    </div>
    <!--结算-->
    <div class="jiesuan difb alc">
      <div class="difb alc">
        <div class="dif alc" style="margin-left: 17px;">
          <span class="fs12" style="color: #414141">合计：</span>
          <span style="color: #D64040">
            <span class="fs18">￥</span>
            <span class="fs20">{{getAllMoney}}元</span>
          </span>
        </div>
      </div>
      <div class="dif alc">
        <div class="pointer mrRit20 text-red" @click="clearAll()">清空购物车</div>
        <div class="pointer mrRit20" @click="delSelectCar()">删除</div>
        <div class="jiesuan_button difcac hover" @click="goPay()">结 算</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cart",
  data() {
    return {
      num: 1,
      checkAll: false,
      isIndeterminate: false,
      select: [],//选中的购物车id
      list: [], //购物车列表
      full_money: '',//满多少包邮
      send_price: '',//不包邮时价格
    }
  },
  methods: {
    coudan() { //去凑单
      this.$router.push('/')
    },
    handleCheckAllChange(val,item) {
      let list = [];
      for(let value of item.list) {
        list.push(value.id)
      }
      for(let value of this.list) {
        value.check = false
      }
      item.check = val
      this.select = val ? list : [];
      // this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      for(let item of this.list) {
        let arr = []
        for(let v of item.list) {
          let index = this.select.findIndex(value1 => {
            return value1 == v.id
          })
          if(index>=0) arr.push(index)
        }
        if(arr.length===item.list.length && item.list.length!=0) {
          item.check = true
        }else {
          item.check = false
        }
      }
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.list.length;
      // this.isIndeterminate = checkedCount > 0 && checkedCount < this.list.length;
    },
    // 禁止输入
    channelInputLimit(e) {
      e.returnValue = ''
    },
    //修改数量
    changeNum(e,old,item) {
      let data = {
        id: item.id,
        type: 1
      }
      if(old>e) data.type = 0
      this.$axios("POST","/Cart/editCart",data)
    },
    //获取购物车商品
    getCart() {
      this.$axios("POST","/cart/getList",{},'加载中')
      .then(res => {
        if(res.code==1) {
          for(let item of res.data) {
            item.check = false
            item.isIndeterminate = false
          }
          this.list = res.data
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    //获取邮费 信息
    getInfo() {
      this.$axios("POST","/Order/getShip",{})
      .then(res => {
        if(res.code===1) {
          this.full_money = res.data.full_money
          this.send_price = res.data.send_price
        }
      })
    },
    //删除购物车
    delCart(item) {
      this.$confirm('确认要删除该商品吗？',{type: 'error'}).then(res => {
        this.$axios("POST","/cart/delCart",{ids: item.id})
            .then(res => {
              if(res.code===1) {
                this.$message.success('删除成功')
                this.getCart()
              }else {
                this.$message.error(res.msg)
              }
            })
      })
    },
    //删除选中的购物车
    delSelectCar() { //删除选中的
      if(this.select.length<=0) {
        return this.$alert('请选择需要删除的商品',{type: 'error'})
      }
      this.$confirm('确认要删除选中的商品吗？',{type: 'warning'})
      .then(con => {
        this.$axios("POST","/cart/delCart",{ids: this.select.join(',')})
            .then(res => {
              if(res.code===1) {
                this.$message.success('删除成功')
                this.getCart()
              }else {
                this.$message.error(res.msg)
              }
            })
      })
    },
    //清空购物车
    clearAll() { //清空购物车
      this.$confirm('确认要清空购物车吗？',{type: 'warning'})
      .then(con => {
        this.$axios("POST","/cart/delCart",{ids: ''})
            .then(res => {
              if(res.code===1) {
                this.$message.success('删除成功')
                this.getCart()
              }else {
                this.$message.error(res.msg)
              }
            })
      })
    },
    // 判断是否跨商店
    isOneShop() {
      let arr = []
      for(let item of this.select) {
        for(let key of this.list) {
          let index = key.list.findIndex(value => {
            return value.id == item
          })
          if(index>=0) {
            arr.push(key.mch_id)
          }
        }
      }
      if (Array.isArray(arr)) {
        return new Set(arr).size === 1;
      }
      return false;
    },
    //下单
    goPay() {
      if(this.select.length<=0) {
        return this.$alert('请选择商品',{type: 'error'})
      }else if(!this.isOneShop()) {
        return this.$alert('请选择一个商家支付',{type: 'error'})
      }else { //跳转到下单页面
        let arr = []
        for(let item of this.select) {
          for(let item2 of this.list) {
            let obj = item2.list.find(value => {
              return item === value.id
            })
            if(obj) {
              arr.push(obj)
            }
          }
        }
        // 将购物车数据存到缓存
        console.log(arr);
        localStorage.setItem('hbcart',JSON.stringify(arr))
        this.$router.push({
          path: '/payOrder',
          query: {type: 2}
        })
      }
    },
  },
  created() {
    this.getCart() //获取购物车
    this.getInfo() //获取邮费
  },
  computed: {
    //获取剩余包邮费用
    getPrice() {
      let arr = []
      for(let item of this.select) {
        for(let item2 of this.list) {
          let obj = item2.list.find(value => {
            return item === value.id
          })
          if(obj) {
            arr.push(obj)
          }
        }
      }
      let full_money = Number(this.full_money)
      let money = 0
      for(let item of arr) {
        money = Number(money) + (item.good_price * item.num)
      }
      if(money>full_money) {
        return -4
      }else {
        return full_money - money
      }
    },
    //获取合计数量
    getAllMoney() {
      let arr = []
      for(let item of this.select) {
        for(let item2 of this.list) {
          let obj = item2.list.find(value => {
            return item === value.id
          })
          if(obj) {
            arr.push(obj)
          }
        }
      }
      let money = 0
      for(let item of arr) {
        money = Number(money) + (item.good_price * item.num)
      }
      money = money.toFixed(2)
      money = money+''
      money = money.substr(0,4)
      return money
    },
  },
}
</script>

<style scoped>
  .cart_warp{
    width: 1200px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    margin-bottom: 15px;
    box-sizing: border-box;
  }
  .cart_item{
    border-bottom: 1px solid #E8E8E8;
    padding: 20px 15px;
    position: relative;
    width: 100%;
  }
  .cart_img{
    width: 90px;
    height: 90px;
    background-color: #EEEEEE;
    margin-right: 15px;
  }
  .del{
    position: absolute;
    top: 16px;
    right: 33px;
    font-size: 14px;
    color: #000000;
  }
  .heji{
    font-size: 12px;
    color: #333333;
    width: 100%;
    height: 31px;
    background-color: #FBFBFC;
    padding-right: 30px;
  }
  .jiesuan{
    width: 1200px;
    height: 55px;
    background: #FFFFFF;
    border: 1px solid #1572F9;
    margin: 20px auto;
    box-sizing: border-box;
    padding-left: 15px;
  }
  .jiesuan_button{
    width: 150px;
    height: 55px;
    background: #1572F9;
    font-size: 18px;
    color: #ffffff;
    cursor: pointer;
  }
</style>