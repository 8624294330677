<template>
  <div class="message_warp">
    <div class="fs28" style="color: #414141;margin-bottom: 64px;">留言内容</div>
    <textarea placeholder="请输入留言内容" class="message_text" v-model="obj.info"></textarea>
    <div class="button difcac" @click="submit()">提交</div>
  </div>
</template>

<script>
export default {
  name: "message",
  data() {
    return {
      obj: {
        mch_id: '',
        info: '',
      }
    }
  },
  created() {
    console.log(this.$route)
    this.obj.mch_id = this.$route.query.id
  },
  methods: {
    // 提交
    submit() {
      if(this.obj.info==='') {
        return this.$alert('请输入留言内容',{type: 'error'})
      }
      this.$axios("POST","/mchs/messageBoard",this.obj,'加载中')
      .then(res => {
        if(res.code===1) {
          this.$alert('留言成功',{type: 'success'})
          .then(con => {
            window.close()
          })
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
  }
}
</script>

<style scoped>
.message_text{
  width: 538px;
  height: 153px;
  background: #FFFFFF;
  border: 1px solid #969696;
  box-sizing: border-box;
  padding: 21px 14px;
  font-size: 14px;
  margin-bottom: 15px;
}
  .message_warp{
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 55px;
    min-height: 80vh;
  }
.button{
  width: 180px;
  height: 40px;
  background: #1572F9;
  font-size: 16px;
  color: #ffffff;
  transition: all 0.2s;
  cursor: pointer;
  margin-right: 25px;
}
.button:hover{
  background: #015bd5;
}
</style>