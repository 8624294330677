<template>
  <el-dialog :visible.sync="dialogVisible"  center title="申请加入" width="30vw" :close-on-click-modal="false">
    <div style="max-height: 50vh;overflow-y: auto">
      <el-form :model="form" label-width="80px" label-position="left" >
        <el-form-item label="公司名称" required>
          <el-input v-model="form.company_name" placeholder="请输入公司名称" disabled></el-input>
        </el-form-item>
        <el-form-item label="姓名" required>
          <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" required>
          <el-input v-model="form.phone" placeholder="请输入联系电话"  maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="公司简介" required>
          <el-input v-model="form.company_profile" placeholder="请输入公司简介" type="textarea" autosize></el-input>
        </el-form-item>
        <el-form-item label="公司资质" required>
          <div class="apply_upload">
            <el-upload
                :action="$store.state.upLoadUrl"
                :file-list="fileList"
                :on-remove="onRemove"
                :on-success="onSuccess"
                :limit="3"
                :on-exceed="onExceed"
                list-type="picture-card">
              <i class="el-icon-plus" ></i>
            </el-upload>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="applyBind">提交申请</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "applyBind",
  data() {
    return {
      dialogVisible: false,
      form: {
        company_name: '',//公司名称
        name: '',//姓名
        phone: '',//联系电话
        company_profile: '',//公司简介
        certification_images: '',//公司资质（图片）
      },
      mch_id: '',
      fileList: [],//营业执照
    }
  },
  methods: {
    applyBind() {
      let data = {
        mch_id: this.mch_id,
        ...this.form
      }
      data.certification_images = this.fileList.map(item => item.response.data.url).join(',')
      this.$axios("POST","/bind/applyBind",data)
          .then(res => {
            if(res.code===1) {
              this.dialogVisible = false
              this.$alert('申请成功，请等待审核')
                .then(e => {
                  this.$emit('applySuccess')
                }).catch(e => {
                  this.$emit('applySuccess')
                })
            }else {
              this.$alert(res.msg,{type: 'error'})
            }
          })
    },
    show(item) {
      this.mch_id = item.id
      this.$axios("POST","/bind/getDefaultApplyInfo",{})
          .then(res => {
            if(res.code===1) {
              this.form = res.data
              // 图片回显
              let imgarr = []
              if(res.data.certification_images) {
                let arr = res.data.certification_images.split(',')
                for(let value of arr) {
                  imgarr.push({
                    url: this.$store.state.imgUrl + value,
                    response: {
                      code: 1,
                      data: {url: value}
                    }
                  })
                }
                this.fileList = imgarr
              }
            }
            this.dialogVisible = true
          })
    },
    onSuccess(res, file, fileList) {
      this.fileList = fileList
    },
    onExceed() {
      this.$alert('最多上传3张照片',{type: 'info'})
    },
    onRemove(file,fileList) {
      this.fileList = fileList
    },
  }
}
</script>

<style scoped>
.apply_upload ::v-deep.el-upload--picture-card {
  width: 100px !important;
  height: 100px !important;
  line-height: 100px;
}
.apply_upload ::v-deep.el-upload-list__item {
  width: 100px;
  height: 100px;
}
</style>