<template>
  <div>
    <div class="e_warp" v-if="showApply" style="display: flex;flex-direction: column" v-loading="showLoading">
      <div class="dif alc">
        <div>
          <div class="e_item dif alc dif alc">
            <div class="e_left">姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名:</div>
            <div class="e_select">
              <el-input placeholder="请输入姓名" v-model="obj.name"></el-input>
            </div>
          </div>
          <div class="e_item dif alc dif alc">
            <div class="e_left">身份证号:</div>
            <div class="e_select">
              <el-input placeholder="请输入身份证号" v-model="obj.id_card" maxlength="18"></el-input>
            </div>
          </div>
          <div class="e_item dif alc dif alc">
            <div class="e_left">联系电话:</div>
            <div class="e_select">
              <el-input placeholder="请输入联系电话" v-model="obj.mobile" maxlength="11"></el-input>
            </div>
          </div>
          <!--<div class="e_item dif alc dif alc">
            <div class="e_left">代理类型:</div>
            <div class="e_select">
              <el-select v-model="obj.apply_type" @change="changeType" placeholder="请选择代理类型">
                <el-option
                    v-for="item in options1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="e_item dif alc dif alc">
            <div class="e_left">代理区域:</div>
            &lt;!&ndash;市级代理&ndash;&gt;
            <div class="e_select" v-if="obj.apply_type==2">
              <el-cascader
                  size="large"
                  :options="city2"
                  v-model="selectedOptions">
              </el-cascader>
            </div>
            &lt;!&ndash;省级代理&ndash;&gt;
            <div class="e_select" v-else>
              <el-select v-model="selectedOptions2">
                <el-option
                    v-for="item in citys"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>-->
        </div>
        <div style="width: 400px;margin-left: 30px;">
          <div class="tct text-red fs30 mrBtm30"
             style="text-decoration: underline;">全 国 代 理 商 申 请 条 件</div>
          <p class="bold fs16" style="line-height: 23px;letter-spacing: 1px">所有的会员都可以申请代理、</p>
          <p class="bold fs16" style="line-height: 23px;letter-spacing: 1px">申请代理后、我们可以详细了解代理分红政策。</p>
          <p class="bold fs16" style="line-height: 23px;letter-spacing: 1px">1、若在你熟悉的城市里有一定的资源。（比如：工程分包公司、消防公司、工程公司等）。</p>
          <p class="bold fs16" style="line-height: 23px;letter-spacing: 1px">2、若在你熟悉的城市里有一定的亲朋好友。（那么你加入我们团队后做起工作来会更加轻松）。</p>
          <p class="bold fs16" style="line-height: 23px;letter-spacing: 1px"> 3、若以上两点你都没有、只要你想尝试、我们一定为你搭建一个展示自己才华的平台、欢迎你加入我们团队。</p>
        </div>
      </div>
      <div class="submit_button difcac colfff pointer mr0At" @click="submit()">提交申请</div>
    </div>
    <div v-else class="box bgff" style="height: 90vh;padding-top: 100px;">
      <el-result icon="success" title="提示" :subTitle="info" v-if="info==='您已经是代理了'"></el-result>
      <el-result icon="info" title="提示" :subTitle="info" v-else></el-result>
    </div>
  </div>
</template>

<script>
import { provinceAndCityData, regionData, provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data'
export default {
  name: "applyAgent",
  data() {
    return {
      info: '',//提示内容
      showApply: true,//显示填写表单
      showLoading: false,
      citys: regionData,//三级联动
      city2: provinceAndCityData,//二级联动
      selectedOptions: [],//市级选择的地址
      selectedOptions2: '',//省级选择的地址
      options1: [
        {label: '省级代理',value: 1},
        {label: '市级代理',value: 2},
      ],
      obj: {
        name: '',//姓名
        id_card: '',//身份证
        mobile: '',//手机号
        apply_type: 1,//代理类型
        address: '',//选择的地址名称
        area_code: '',//选择的地址code
      },
    }
  },
  methods: {
    // 提交申请
    submit() {
      let data = this.obj
      /*if(data.apply_type===1) { //省级代理
        if(this.selectedOptions2==='') {
          return this.$alert('请选择代理区域',{type: 'error'})
        }
        let provice = this.citys.find(item => {
          return this.selectedOptions2 === item.value
        })
        console.log(this.selectedOptions2);
        data.address = provice.label
        data.area_code = provice.value
      }else { //市级代理
        if(this.selectedOptions.length<=0) {
          return this.$alert('请选择代理区域',{type: 'error'})
        }
        let city = [
            CodeToText[this.selectedOptions[0]],
            CodeToText[this.selectedOptions[1]]
        ]
        data.address = city[0]
        data.area_code = this.selectedOptions[1]
        if(city[1]==='市辖区') data.address = city[1]
      }*/
      if(data.name==='') {
        return this.$alert('请输入姓名',{type: 'error'})
      }else if(data.id_card==='') {
        return this.$alert('请输入身份证号',{type: 'error'})
      }else if(data.mobile==='') {
        return this.$alert('请输入联系电话',{type: 'error'})
      }
      this.showLoading = true
      this.$axios("POST","/Agent/apply",data)
      .then(res => {
        this.showLoading = false
        if(res.code===1) {
          this.$alert('申请成功，请等待审核',{type: 'success'})
          .then(e => {
            this.$router.go(0)
          }).catch(e => {
            this.$router.go(0)
          })
        }else {
          this.$alert(res.msg,{type: 'info'})
        }
      })
    },
    //选择类型
    changeType(e) {
      console.log(e);
    },
    //获取填写内容
    getInfo() {
      this.showLoading = true
      this.$axios("POST","/Agent/checkSub",{})
          .then(res => {
            this.showLoading = false
            if(res.code===1) {
              if(res.data.status==1) {
                this.showApply = true
              }else {
                this.obj.name = res.data.info.name
                this.obj.id_card = res.data.info.id_card
                this.obj.mobile = res.data.info.mobile
                this.obj.apply_type = res.data.info.apply_type*1
                this.obj.address = res.data.info.address
                this.obj.area_code = res.data.info.area_code
                if(this.obj.apply_type==1) { //省级  回显
                  this.selectedOptions2 = res.data.info.area_code
                }else { //市级回显
                  // this.selectedOptions = [res.data.info.area_code]
                  let province = res.data.info.area_code.substring(0,2)+'0000'
                  this.selectedOptions = [province,res.data.info.area_code]
                }
                this.showApply = true
                this.$alert(res.msg,{type:"info"})
              }
            }else {
              this.showApply = false
              this.info = res.msg
            }
          })
    },
  },
  created() {
    this.getInfo()
  }
}
</script>

<style scoped>
.e_warp{
  width: 100%;
  height: 803px;
  background: #FFFFFF;
  box-sizing: border-box;
  padding-left: 36px;
  padding-top: 49px;
}
.e_left{
  font-size: 16px;
  color: #000000;
  margin-right: 28px;
}
.e_item{
  border-bottom: 1px dashed #EEEEEE;
  padding: 13px 61px;
  box-sizing: border-box;
  width: 490px;
}
.e_select ::v-deep.el-input__inner{
  width: 260px;
  height: 38px;
  background: #FFFFFF;
  border-radius: 2px 2px 2px 2px;
  border: 1px solid #D2D2D2;
  box-sizing: border-box;
  padding: 0 17px;
  font-size: 16px;
}
.submit_button{
  width: 443px;
  height: 65px;
  background: #007AFF;
  border-radius: 4px 4px 4px 4px;
  font-size: 20px;
  margin-top: 45px;
  transition: all 0.3s;
}
.submit_button:hover{
  background: #3A53FF;
}
</style>