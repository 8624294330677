<template>
  <div>
    <!--<navbar></navbar>-->
    <router-view></router-view>
  </div>
</template>

<script>
import Navbar from "@/components/common/navbar";
export default {
  name: "WantToBuyCenter",
  components: {Navbar},
  data() {
    return {
      showPay: false,//显示支付
      activeName: '1',
      cates: [
        {id: '1',name: '建筑'},
        {id: '2',name: '五金'},
        {id: '3',name: '建材'},
        {id: '4',name: '化工'},
        {id: '5',name: '机电'},
        {id: '6',name: '机电'},
        {id: '7',name: '机电'},
        {id: '8',name: '机电'},
      ],//分类
      list: [null,null,null,null],//报价列表
    }
  },
  methods: {
    goDetail(item) { //跳转到详情
      // this.showPay = true
      this.$router.push({
        path: '/detail',
        // query: {id: 1},
      })
    },
    goPay() { //确认支付
      this.$router.push('/submitpay')
    },
    goVip() { //开通会员

    },
  }
}
</script>

<style scoped>
.swiper{
  width: 100%;
}
/*分类*/
::v-deep .el-tabs__item{
  font-size: 32px;
  width: 200px;
  text-align: center;
}
::v-deep .el-tabs__nav-scroll{
  padding-bottom: 30px;
}
::v-deep .el-tabs__nav-next .el-icon-arrow-right{
  font-size: 30px;
  padding-top: 5px;
}
::v-deep .el-tabs__nav-prev .el-icon-arrow-left{
  font-size: 30px;
  padding-top: 5px;
}
::v-deep .el-tabs__item.is-active{
  color: #1572F9;
}
::v-deep .el-tabs__active-bar{
  bottom: -30px;
  height: 6px;
  background-color: #1572F9;
}
::v-deep .el-tabs__active-bar:after{
  content: '';
  width: 0;
  height: 0;
  border-bottom: 20px solid #1572F9;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%,-100%);
}
::v-deep .el-tabs__nav-wrap::after{
  height: 6px;
}
.wtb_warp{
  box-sizing: border-box;
  padding: 0 23px;
}
.wtb_item{
  border-bottom: 1px solid #F2F2F2;
  padding-top: 27px;
  padding-bottom: 45px;
}

.buy_page ::v-deep .number{
  width: 40px;
  height: 40px;
  padding: 6px 5px;
}
.buy_page ::v-deep .more{
  width: 40px;
  height: 40px;
  padding: 6px 5px;
}
.buy_page ::v-deep .btn-next{
  width: 40px;
  height: 40px;
  padding: 6px 5px;
}
.buy_page ::v-deep .btn-prev{
  width: 40px;
  height: 40px;
  padding: 6px 5px;
}

.pay_bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 333;
  background-color: rgba(0,0,0,0.5);
}
.pay_warp{
  width: 602px;
  height: 389px;
  background: #FFFFFF;
  border-radius: 26px 26px 26px 26px;
  box-sizing: border-box;
  padding-top: 46px;
}
.button1{
  width: 216px;
  height: 73px;
  background: #DCDCDC;
  font-size: 36px;
  color: #ffffff;
  cursor: pointer;
  border-radius: 40px;
  transition: all 0.2s;
}
.button1:hover{
  background-color: #c4c4c4;
}
.button2{
  border-radius: 40px;
  width: 216px;
  height: 73px;
  background: #1572F9;
  font-size: 36px;
  color: #ffffff;
  margin-left: 24px;
  cursor: pointer;
  transition: all 0.2s;
}
.button2:hover{
  background: #0152d0;
}
</style>