<template>
  <div>
    <!--供应商查看商品-->
    <supplier-goods v-if="userInfo.firm_type == 1"></supplier-goods>
    <!--采购商查看商家-->
    <purchaser-shops v-if="userInfo.firm_type == 2"></purchaser-shops>
  </div>
</template>

<script>
import {mapState} from "vuex";
import SupplierGoods from "@/views/IndustryShop/components/supplierGoods.vue";
import PurchaserShops from "@/views/IndustryShop/components/purchaserShops.vue";

export default {
  name: 'IndustrySearch',
  components: {PurchaserShops, SupplierGoods},
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    })
  },
  data() {
    return {

    }
  }
}
</script>