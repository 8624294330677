<template>
  <div class="add_list_warp difcac" @click="hide()">
    <!--发布列表-->
    <div class="add_list_center posa" @click.stop v-if="current===-1">
      <i class="el-icon-circle-close close" @click="hide()"></i>
      <p class="fs26 col000 tct" style="margin-bottom: 12px;">我要发布</p>
      <div class="add_button difcac colfff" @click="current=item.id" v-if="showCateIs(item)"
           v-for="(item,index) in cate" :key="index">{{item.name}}</div>
    </div>
    <!--采购信息-->
    <transition enter-active-class="animation-slide-top">
      <div class="add_warp" v-if="current===1" @click.stop>
        <div class="add_title  difcac colfff fs18">
          <p>采购信息</p>
          <i class="el-icon-close colse_icon" @click.stop="hide()"></i>
        </div>
        <div style="height: 50px;"></div>
        <div class="box add_cont" style="padding: 0 36px;">
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>招标标题:</div>
            <div class="add_select" >
              <el-input placeholder="请输入标题" type="text" v-model="askBuy.title"></el-input>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>行业分类:</div>
            <div class="add_select" >
              <el-select v-model="askBuy.industry_id" placeholder="请选择">
                <el-option
                    v-for="item in industry"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>报价表名称:</div>
            <div class="add_select" >
              <el-input placeholder="请输入报价表名称" v-model="askBuy.quotation_name"></el-input>
            </div>
          </div>
          <div class="add_item dif">
            <div class="add_left" style="padding-top: 5px;">报价表:</div>
            <div class="add_select" style="width: 260px;">
              <el-upload
                  class="upload-demo"
                  :action="$store.state.upLoadUrl"
                  :headers="{'token': token}"
                  :file-list="askBuyFillList"
                  :on-success="(res,file,fileList)=>onSuccess(res,file,fileList,1)">
                <div class="dife" style="width: 260px;">
                  <el-button size="small" type="primary">立即上传</el-button>
                </div>
              </el-upload>
            </div>
          </div>
          <div class="add_item dif">
            <div class="add_left"><span class="text-red">*</span>信息描述:</div>
            <div class="add_select" style="width: 253px;margin-left: 23px;">
              <el-input placeholder="请输入信息描述" v-model="askBuy.describe" :rows="4" type="textarea"></el-input>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>联系人:</div>
            <div class="add_select" >
              <el-input placeholder="请输入联系人姓名" v-model="askBuy.linkman"></el-input>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>联系电话:</div>
            <div class="add_select" >
              <el-input placeholder="请输入联系人电话" maxlength="41" v-model="askBuy.contact_way"></el-input>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left">邮箱地址:</div>
            <div class="add_select" >
              <el-input placeholder="请输入邮箱地址" v-model="askBuy.mail"></el-input>
            </div>
          </div>
          <!--<div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>标书金额:</div>
            <div class="add_select" >
              <el-input placeholder="请输入标书金额" v-model="askBuy.tender_amount"></el-input>
            </div>
          </div>-->
          <!--<div class="add_item dif alc">-->
          <!--  <div class="add_left"><span class="text-red">*</span>城市地区:</div>-->
          <!--  <div class="add_select" >-->
          <!--    <el-input placeholder="自动定位" disabled v-model="area_name"></el-input>-->
          <!--  </div>-->
          <!--</div>-->
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>开始时间:</div>
            <div class="add_select" >
              <el-date-picker
                  v-model="askBuy.start_time"
                  prefix-icon="none"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="请选择时间">
              </el-date-picker>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>结束时间:</div>
            <div class="add_select" >
              <el-date-picker
                  v-model="askBuy.end_time"
                  prefix-icon="none"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="请选择时间">
              </el-date-picker>
            </div>
          </div>
          <div class="fs20" style="color: #FB6124;padding: 21px 61px" v-if="userInfo.is_vip!=1">发布需支付{{payConfig.post_pay_1}}元</div>
          <div class="fs20" style="color: #FB6124;padding: 21px 61px" v-else>发布需支付0元</div>
          <div class="submit_button difcac" @click="goPay(1)" v-if="payConfig.post_pay_1>0 && userInfo.is_vip!=1">立即支付并发布</div>
          <div class="submit_button difcac" @click="goPay(1)" v-else>立即发布</div>
        </div>
      </div>
    </transition>
    <!--安装工程信息-->
    <transition enter-active-class="animation-slide-top" >
      <div class="add_warp" v-if="current===2" @click.stop>
        <div class="add_title  difcac colfff fs18">
          <p>安装工程信息</p>
          <i class="el-icon-close colse_icon" @click.stop="hide()"></i>
        </div>
        <div style="height: 50px;"></div>
        <div class="box add_cont" style="padding: 0 36px;">
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>招标标题:</div>
            <div class="add_select" >
              <el-input placeholder="请输入标题" type="text" v-model="information.title"></el-input>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>行业分类:</div>
            <div class="add_select" >
              <el-select v-model="information.industry_id" placeholder="请选择">
                <el-option
                    v-for="item in industry"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>报价表名称:</div>
            <div class="add_select" >
              <el-input placeholder="请输入报价表名称" v-model="information.quotation_name"></el-input>
            </div>
          </div>
          <div class="add_item dif">
            <div class="add_left" style="padding-top: 5px;">报价表:</div>
            <div class="add_select" style="width: 260px;">
              <el-upload
                  class="upload-demo"
                  :action="$store.state.upLoadUrl"
                  :headers="{'token': token}"
                  :file-list="informationFillList"
                  :on-success="(res,file,fileList)=>onSuccess(res,file,fileList,2)">
                <div class="dife" style="width: 260px;">
                  <el-button size="small" type="primary">立即上传</el-button>
                </div>
              </el-upload>
            </div>
          </div>
          <div class="add_item dif">
            <div class="add_left"><span class="text-red">*</span>信息描述:</div>
            <div class="add_select" style="width: 253px;margin-left: 23px;">
              <el-input placeholder="请输入信息描述" v-model="information.describe" :rows="4" type="textarea"></el-input>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>联系人:</div>
            <div class="add_select" >
              <el-input placeholder="请输入联系人姓名" v-model="information.linkman"></el-input>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>联系电话:</div>
            <div class="add_select" >
              <el-input placeholder="请输入联系人电话" maxlength="41" v-model="information.contact_way"></el-input>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>邮箱地址:</div>
            <div class="add_select" >
              <el-input placeholder="请输入邮箱地址" v-model="information.mail"></el-input>
            </div>
          </div>
          <!--<div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>标书金额:</div>
            <div class="add_select" >
              <el-input placeholder="请输入标书金额" v-model="information.tender_amount"></el-input>
            </div>
          </div>-->
          <!--<div class="add_item dif alc">-->
          <!--  <div class="add_left"><span class="text-red">*</span>城市地区:</div>-->
          <!--  <div class="add_select" >-->
          <!--    <el-input placeholder="自动定位" disabled v-model="area_name"></el-input>-->
          <!--  </div>-->
          <!--</div>-->
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>开始时间:</div>
            <div class="add_select" >
              <el-date-picker
                  v-model="information.start_time"
                  prefix-icon="none"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="请选择时间">
              </el-date-picker>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>结束时间:</div>
            <div class="add_select" >
              <el-date-picker
                  v-model="information.end_time"
                  prefix-icon="none"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="请选择时间">
              </el-date-picker>
            </div>
          </div>
          <div class="fs20" style="color: #FB6124;padding: 21px 61px" v-if="userInfo.is_vip!=1">发布需支付{{payConfig.post_pay_2}}元</div>
          <div class="fs20" style="color: #FB6124;padding: 21px 61px" v-else>发布需支付0元</div>
          <div class="submit_button difcac" @click="goPay(2)" v-if="payConfig.post_pay_2>0 && userInfo.is_vip!=1">立即支付并发布</div>
          <div class="submit_button difcac" @click="goPay(2)" v-else>立即发布</div>
        </div>
      </div>
    </transition>
    <!--物流货运信息-->
    <transition enter-active-class="animation-slide-top" >
      <div class="add_warp" v-if="current===3" @click.stop>
        <div class="add_title  difcac colfff fs18">
          <p>物流货运信息</p>
          <i class="el-icon-close colse_icon" @click.stop="hide()"></i>
        </div>
        <div style="height: 50px;"></div>
        <div class="box add_cont" style="padding: 0 36px;">
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>标题:</div>
            <div class="add_select" >
              <el-input placeholder="请输入标题" v-model="logistics.title"></el-input>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>行业分类:</div>
            <div class="add_select" >
              <el-select v-model="logistics.industry_id" placeholder="请选择">
                <el-option
                    v-for="item in industry"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>公司地址:</div>
            <div class="add_select" >
              <el-input placeholder="请输入公司地址" v-model="logistics.company_address"></el-input>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>联系人:</div>
            <div class="add_select" >
              <el-input placeholder="请输入联系人姓名" v-model="logistics.linkman"></el-input>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>联系电话:</div>
            <div class="add_select" >
              <el-input placeholder="请输入联系人电话" maxlength="41" v-model="logistics.contact_way"></el-input>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>邮箱地址:</div>
            <div class="add_select" >
              <el-input placeholder="请输入邮箱地址" v-model="logistics.mail"></el-input>
            </div>
          </div>
          <div class="add_item dif">
            <div class="add_left"><span class="text-red">*</span>信息描述:</div>
            <div class="add_select" style="width: 253px;margin-left: 23px;">
              <el-input placeholder="请输入信息描述" v-model="logistics.describe" :rows="4" type="textarea"></el-input>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>开始时间:</div>
            <div class="add_select" >
              <el-date-picker
                  v-model="logistics.start_time"
                  prefix-icon="none"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="请选择时间">
              </el-date-picker>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>结束时间:</div>
            <div class="add_select" >
              <el-date-picker
                  v-model="logistics.end_time"
                  prefix-icon="none"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="请选择时间">
              </el-date-picker>
            </div>
          </div>
          <div class="fs20" style="color: #FB6124;padding: 21px 61px" v-if="userInfo.is_vip!=1">发布需支付{{payConfig.post_pay_3}}元</div>
          <div class="fs20" style="color: #FB6124;padding: 21px 61px" v-else>发布需支付0元</div>
          <div class="submit_button difcac" @click="goPay(3)" v-if="payConfig.post_pay_3>0 && userInfo.is_vip!=1">立即支付并发布</div>
          <div class="submit_button difcac" @click="goPay(3)" v-else>立即发布</div>
        </div>
      </div>
    </transition>
    <!--发布招聘-->
    <transition enter-active-class="animation-slide-top" >
      <div class="add_warp" v-if="current===4" @click.stop>
        <div class="add_title  difcac colfff fs18">
          <p>发布招聘</p>
          <i class="el-icon-close colse_icon" @click.stop="hide()"></i>
        </div>
        <div style="height: 50px;"></div>
        <div class="box add_cont" style="padding: 0 36px;">
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>招聘标题:</div>
            <div class="add_select" >
              <el-input placeholder="请输入标题" v-model="job.title"></el-input>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>招聘岗位:</div>
            <div class="add_select" >
              <el-select v-model="job.job_cate_id" placeholder="请选择">
                <el-option
                    v-for="item in jobCate"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>岗位薪资:</div>
            <div class="add_select" >
              <el-select v-model="job.post_salary" placeholder="请选择">
                <el-option
                    v-for="(item,index) in salaryArr"
                    :key="index"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>学历水平:</div>
            <div class="add_select" >
              <el-select v-model="job.degree_level" placeholder="请选择">
                <el-option
                    v-for="item in degreeLevelList"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>工作经验:</div>
            <div class="add_select" >
              <el-select v-model="job.work_experience" placeholder="请选择">
                <el-option
                    v-for="item in WorkExperienceList"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>公司地址:</div>
            <div class="add_select" >
              <el-input placeholder="请输入公司地址" v-model="job.office_location"></el-input>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>联系人:</div>
            <div class="add_select" >
              <el-input placeholder="请输入联系人姓名" v-model="job.linkman"></el-input>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>联系电话:</div>
            <div class="add_select" >
              <el-input placeholder="请输入联系人电话" maxlength="41" v-model="job.contact_way"></el-input>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>邮箱地址:</div>
            <div class="add_select" >
              <el-input placeholder="请输入邮箱地址" v-model="job.mail"></el-input>
            </div>
          </div>
          <div class="add_item dif">
            <div class="add_left"><span class="text-red">*</span>岗位介绍:</div>
            <div class="add_select" style="width: 253px;margin-left: 23px;">
              <el-input placeholder="请输入信息描述" v-model="job.describe" :rows="4" type="textarea"></el-input>
            </div>
          </div>
          <div class="fs20" style="color: #FB6124;padding: 21px 61px" v-if="userInfo.is_vip!=1">发布需支付{{payConfig.post_pay_4}}元</div>
          <div class="fs20" style="color: #FB6124;padding: 21px 61px" v-else>发布需支付0元</div>
          <div class="submit_button difcac" @click="goPay(4)" v-if="payConfig.post_pay_4>0 && userInfo.is_vip!=1">立即支付并发布</div>
          <div class="submit_button difcac" @click="goPay(4)" v-else>立即发布</div>
        </div>
      </div>
    </transition>
    <!--发布求职-->
    <transition enter-active-class="animation-slide-top" >
      <div class="add_warp" v-if="current===5" @click.stop>
        <div class="add_title  difcac colfff fs18">
          <p>发布求职</p>
          <i class="el-icon-close colse_icon" @click.stop="hide()"></i>
        </div>
        <div style="height: 50px;"></div>
        <div class="box add_cont" style="padding: 0 36px;">
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>求职标题:</div>
            <div class="add_select" >
              <el-input placeholder="请输入标题" v-model="publish.title"></el-input>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>求职岗位:</div>
            <div class="add_select" >
              <el-select v-model="publish.job_cate_id" placeholder="请选择">
                <el-option
                    v-for="item in jobCate"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>期望薪资:</div>
            <div class="add_select" >
              <el-select v-model="publish.post_salary" placeholder="请选择">
                <el-option
                    v-for="(item,index) in salaryArr"
                    :key="index"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>学历水平:</div>
            <div class="add_select" >
              <el-select v-model="publish.degree_level" placeholder="请选择">
                <el-option
                    v-for="item in degreeLevelList"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>工作经验:</div>
            <div class="add_select" >
              <el-select v-model="publish.work_experience" placeholder="请选择">
                <el-option
                    v-for="item in WorkExperienceList"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>期望办公地点:</div>
            <div class="add_select" >
              <el-input placeholder="请输入期望办公地点" v-model="publish.office_location"></el-input>
              <!--<el-cascader-->
              <!--    size="large"-->
              <!--    v-model="address"-->
              <!--    :options="region">-->
              <!--</el-cascader>-->
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>联系人:</div>
            <div class="add_select" >
              <el-input placeholder="请输入联系人姓名" v-model="publish.linkman"></el-input>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>联系电话:</div>
            <div class="add_select" >
              <el-input placeholder="请输入联系人电话" maxlength="41" v-model="publish.contact_way"></el-input>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>邮箱地址:</div>
            <div class="add_select" >
              <el-input placeholder="请输入邮箱地址" v-model="publish.mail"></el-input>
            </div>
          </div>
          <div class="add_item dif">
            <div class="add_left"><span class="text-red">*</span>个人介绍:</div>
            <div class="add_select" style="width: 253px;margin-left: 23px;">
              <el-input placeholder="请输入信息描述" :rows="4" type="textarea" v-model="publish.describe"></el-input>
            </div>
          </div>
          <div class="fs20" style="color: #FB6124;padding: 21px 61px" v-if="userInfo.is_vip!=1">发布需支付{{payConfig.post_pay_5}}元</div>
          <div class="fs20" style="color: #FB6124;padding: 21px 61px" v-else>发布需支付0元</div>
          <div class="submit_button difcac" @click="goPay(5)" v-if="payConfig.post_pay_5>0 && userInfo.is_vip!=1">立即支付并发布</div>
          <div class="submit_button difcac" @click="goPay(5)" v-else>立即发布</div>
        </div>
      </div>
    </transition>
    <!--二手设备-->
    <transition enter-active-class="animation-slide-top">
      <div class="add_warp" v-if="current===6" @click.stop>
        <div class="add_title  difcac colfff fs18">
          <p>发布二手设备</p>
          <i class="el-icon-close colse_icon" @click.stop="hide()"></i>
        </div>
        <div style="height: 50px;"></div>
        <div class="box add_cont" style="padding: 0 36px;">
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>设备名称:</div>
            <div class="add_select" >
              <el-input placeholder="请输入设备名称" type="text" v-model="secondHand.title"></el-input>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>行业分类:</div>
            <div class="add_select" >
              <el-select v-model="secondHand.industry_id" placeholder="请选择">
                <el-option
                    v-for="item in industry"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="add_item dif">
            <div class="add_left" style="padding-top: 5px;"><span class="text-red">*</span>上传照片:</div>
            <div class="add_select mrLft20" style="width: 260px;">
              <el-upload
                  class="upload-demo"
                  list-type="picture-card"
                  :action="$store.state.upLoadUrl"
                  :headers="{'token': token}"
                  :file-list="secondHandFillList"
                  :on-success="(res,file,fileList)=>onSuccess(res,file,fileList,6)">
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
          </div>
          <div class="add_item dif">
            <div class="add_left"><span class="text-red">*</span>设备介绍:</div>
            <div class="add_select" style="width: 253px;margin-left: 23px;">
              <el-input placeholder="请输入设备介绍" v-model="secondHand.describe" :rows="4" type="textarea"></el-input>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>联系人:</div>
            <div class="add_select" >
              <el-input placeholder="请输入联系人姓名" v-model="secondHand.linkman"></el-input>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left"><span class="text-red">*</span>联系电话:</div>
            <div class="add_select" >
              <el-input placeholder="请输入联系人电话" maxlength="41" v-model="secondHand.contact_way"></el-input>
            </div>
          </div>
          <div class="add_item dif alc">
            <div class="add_left">邮箱地址:</div>
            <div class="add_select" >
              <el-input placeholder="请输入邮箱地址" v-model="secondHand.mail"></el-input>
            </div>
          </div>
          <div class="fs20" style="color: #FB6124;padding: 21px 61px" v-if="userInfo.is_vip!=1">发布需支付{{payConfig.post_pay_6}}元</div>
          <div class="fs20" style="color: #FB6124;padding: 21px 61px" v-else>发布需支付0元</div>
          <div class="submit_button difcac" @click="goPay(6)" v-if="payConfig.post_pay_6>0 && userInfo.is_vip!=1">立即支付并发布</div>
          <div class="submit_button difcac" @click="goPay(6)" v-else>立即发布</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {mapState} from "vuex";
import { provinceAndCityData, regionData, provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data'
export default {
  name: "addList",
  computed: {
  },
  data() {
    return {
      userInfo: {
        is_vip: 0,
        vip_end_time_str: '',//vip到期时间
      },
      token: '',
      current: -1,//当前选中的发布类型
      cate: [
        {id: 1,name: '采购信息'},
        // {id: 2,name: '安装工程信息'},
        // {id: 3,name: '物流货运信息'},
        // {id: 4,name: '发布招聘'},
        // {id: 5,name: '发布求职'},
        {id: 6,name: '发布二手设备'},
      ],
      //测试用
      time: '',
      region: provinceAndCityData,
      address: '',
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      value: '',

      area_name: '', //自动定位获取的城市名
      area_code: '', //自动定位获取的城市code

      industry: [],//行业分类
      jobCate: [],//岗位列表

      askBuy: { //采购信息
        title: '',//标题
        industry_id: '',//行业分类
        describe: '',//信息描述
        linkman: '',//联系人
        contact_way: '',//联系方式
        mail: '',//邮箱
        // tender_amount: '',//标书金额
        quotation_file: '',//报价表
        start_time: '',//开始时间
        end_time: '',//结束时间
        area_code:' ',//城市代码
        area_name: '',//城市名称
        quotation_name: '',//报价单名称
      },
      askBuyFillList: [],//采购信息 文件列表

      information: { //安装工程求购
        title: '',//标题
        industry_id: '',//行业分类
        describe: '',//信息描述
        linkman: '',//联系人
        contact_way: '',//联系方式
        mail: '',//邮箱
        tender_amount: '',//标书金额
        quotation_file: '',//报价表
        start_time: '',//开始时间
        end_time: '',//结束时间
        area_code:' ',//城市代码
        area_name: '',//城市名称
        quotation_name: '',//报价单名称
      },
      informationFillList: [],//安装工程求购 文件列表

      logistics: { //物流货运
        title: '',//标题
        company_address: '',//公司地址
        describe: '',//信息描述
        linkman: '',//联系人
        contact_way: '',//联系方式
        mail: '',//邮箱
        start_time: '',//开始时间
        end_time: '',//结束时间
        area_code: '',//城市代码
        area_name: '',//城市名称
        industry_id: '',//行业分类
      },

      job: { //人才招聘
        title: '', //标题
        describe: '',//信息描述
        linkman: '',//联系人
        contact_way: '',//联系方式
        mail:  '',//邮箱
        area_code: '',
        area_name: '',
        office_location: '',//办公地点
        post_salary: '',//岗位薪资
        degree_level: '',//学历水平
        work_experience: '',//工作经验
        job_cate_id: '',//行业分类
      },

      publish: { //求职
        title: '',//求职标题
        describe: '',//个人介绍
        linkman: '',//联系人
        contact_way: '',//联系方式
        mail: '',//邮箱
        area_code: '',
        area_name: '',
        office_location: '',//期望办公地点
        post_salary: '',//岗位薪资
        degree_level: '',//学历水平
        work_experience: '',//工作经验
        job_cate_id: '',//行业分类
      },

      secondHand: { //二手设备
        industry_id: '',//行业份额里
        title: '',//标题
        describe: '',//信息描述
        linkman: '',//联系人
        contact_way: '',//联系方式
        area_code: '',
        area_name: '',
        used_images: '',//图片
      },
      secondHandFillList: [],//发布二手设备 文件列表

      salaryArr: [],//岗位薪资
      degreeLevelList: [],//学历选项
      WorkExperienceList: [],//工作经验选项
      payConfig: { //支付价格
        post_pay_1: '',//采购信息发布所需金额
        post_pay_2: '',//安装工程信息发布所需金额
        post_pay_3: '',//物流货运信息发布所需金额
        post_pay_4: '',//人才招聘发布所需金额
        post_pay_5: '',//求职信息发布所需金额
        post_pay_6: '',//二手设备发布所需金额
      },
    }
  },
  methods: {
    showCateIs(item) {
      if(item.id===1) {
        if(this.userInfo.firm_type == 1 ) return false
        return true
      }
      return true
    },
    //获取用户信息
    getUserInfo() {
      if(localStorage.getItem('hbInfo')) {
        this.userInfo = JSON.parse(localStorage.getItem('hbInfo'))
      }
      this.$axios("POST","/user/getUserInfo",{})
          .then(res => {
            if(res.code===1) {
              localStorage.setItem('hbInfo',JSON.stringify(res.data))
              this.$store.commit('setUserInfo',res.data)
              this.userInfo = res.data
            }else {
              this.$alert('获取用户信息失败，请刷新重试',{type: 'error'})
            }
          })
    },
    //上传图片
    onSuccess(res,file,fileList,type) {
      console.log(res);
      if(res.code==0) {
        this.$message.error(res.msg)
        return
      }
      if(type===1) { //采购信息
        this.askBuyFillList = [file]
        this.askBuy.quotation_file = res.data.url
      }else if(type===2) { //安装工程求购
        this.informationFillList = [file]
        this.information.quotation_file = res.data.url
      }else if(type===6) { //发布二手设备
        this.secondHandFillList = [...this.secondHandFillList,file]
      }
    },
    //选择地址
    changeAddress(e,type) {
      if(type===1) { //采购信息

      }
    },
    // 隐藏
    hide() {
      this.$emit('hideWarp',{type: 1})
    },
    //去支付
    goPay(type) {
      let data = {},url="/post/ask_buy/publish"
      if(type===1) { //采购信息
        data = this.askBuy
        data.area_code = this.$store.state.code
        data.area_name = this.$store.state.address
      }else if(type===2) { //安装采购信息
        data = this.information
        data.area_code = this.$store.state.code
        data.area_name = this.$store.state.address
        url="/post/information/publish"
      }else if(type===3) { //发布物流货运
        data = this.logistics
        data.area_code = this.$store.state.code
        data.area_name = this.$store.state.address
        url="/post/post_logistics_freight/publish"
      }else if(type===4) { //发布招聘
        data = this.job
        data.area_code = this.$store.state.code
        data.area_name = this.$store.state.address
        url="/post/job_fair/publish"
      }else if(type===5) { //发布求职
        data = this.publish
        data.area_code = this.$store.state.code
        data.area_name = this.$store.state.address
        url="/post/job_intention/publish"
      }else if(type===6) { //发布二手设备
        data = this.secondHand
        data.area_code = this.$store.state.code
        data.area_name = this.$store.state.address
        let images = []
        this.secondHandFillList.forEach((item,index) => { images.push(item.response.data.url)} )
        data.used_images = images.join(',')
        url="/post/second_hand/publish"
      }
      this.$axios("POST",url,data,'加载中')
      .then(res => {
        if(res.code===1) {
          if(res.data.is_pay==1) { //需要支付
            //跳转到支付界面
            this.hide();
            this.$router.push({
              path: '/submitpay',
              query: {
                id: res.data.order_id,
                pay_amount: res.data.pay_amount,
                issub: 1,
              }
            })
          }else { //不需要支付
            // eventBus.$emit('updateList',{})
            this.$alert('发布成功',{type: 'success'})
            .then(ale=>{
              // this.hide();
              this.$router.go(0)
            }).catch(ale=>{
              // this.hide();
              this.$router.go(0)
            })
          }
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    //获取行业分类
    getIndustry () {
      this.$axios("POST","/index/getIndustry",{})
      .then(res => {
        if(res.code===1) this.industry = res.data
      })
    },
    //获取岗位列表
    getJobCate() {
      this.$axios("POST","/index/getJobCate",{})
          .then(res => {
            if(res.code===1) this.jobCate = res.data
          })
    },
    //获取定位
    getLocation() {
      var  geolocation = new AMap.Geolocation({
        enableHighAccuracy: true,//是否使用高精度定位，默认:true
        timeout: 100000,
        maximumAge: 0,           //定位结果缓存0毫秒，默认：0
        needAddress: true,
        extensions: 'all'
      });
      let that = this;
      geolocation.getCityInfo(function (status,res) {
        // 逆地理编码
        let geocoder = new AMap.Geocoder({
          extensions: 'all'
        })
        let lnglat = res.position
        geocoder.getAddress(lnglat,function (code,res1) {
          if (code === 'complete' && res1.info === 'OK') {
            let data = res1.regeocode.addressComponent
            that.area_code = data.adcode.substring(0, 4)+'00' //市级编码
            if(data.city) { //区名
              that.area_name = data.city
            }else { //直辖市
              that.area_name = data.province
            }
          }
        })
      });
    },
    //获取价格配置
    getConfig() {
      this.$axios("POST","/index/getConfig",{})
      .then(res => {
        if(res.code===1) this.payConfig = res.data
      })
    },
    //获取岗位薪资
    getSalary() {
      this.$axios("POST","/post/job_fair/getPostSalaryList",{})
      .then(res => {
        if(res.code===1) this.salaryArr = res.data
      })
    },
    //获取学历选项
    getDegreeLeveList() {
      this.$axios("POST","/post/job_fair/getDegreeLevelList",{})
      .then(res => {
        if(res.code===1) this.degreeLevelList = res.data
      })
    },
    //获取工作经验选项
    getWorkExperienceList() {
      this.$axios("POST","/post/job_fair/getWorkExperienceList",{})
          .then(res => {
            if(res.code===1) this.WorkExperienceList = res.data
          })
    },
    //获取发布记录
    getHistory() {
      //获取采购信息
      this.$axios("POST",'/post/ask_buy/getLastRecord',{})
      .then(res => {
        if(res.code==1 && res.data) {
          this.askBuy.mail = res.data.mail;this.askBuy.contact_way = res.data.contact_way;this.askBuy.linkman = res.data.linkman;
        }
      })
      //获取安装工程设备
      this.$axios("POST",'/post/Information/getLastRecord',{})
          .then(res => {
            if(res.code==1 && res.data) {
              this.information.mail = res.data.mail;this.information.contact_way = res.data.contact_way;this.information.linkman = res.data.linkman;
            }
          })
      //获取人才招聘
      this.$axios("POST",'/post/job_fair/getLastRecord',{})
          .then(res => {
            if(res.code==1 && res.data) {
              this.job.mail = res.data.mail;this.job.contact_way = res.data.contact_way;this.job.linkman = res.data.linkman;
            }
          })
      //获取求职信息
      this.$axios("POST",'/post/job_intention/getLastRecord',{})
          .then(res => {
            if(res.code==1 && res.data) {
              this.publish.mail = res.data.mail;this.publish.contact_way = res.data.contact_way;this.publish.linkman = res.data.linkman;
            }
          })
      //获取物流信息
      this.$axios("POST",'/post/post_logistics_freight/getLastRecord',{})
          .then(res => {
            if(res.code==1 && res.data) {
              this.logistics.mail = res.data.mail;this.logistics.contact_way = res.data.contact_way;this.logistics.linkman = res.data.linkman;
            }
          })
      //获取二手设备
      this.$axios("POST",'/post/second_hand/getLastRecord',{})
          .then(res => {
            if(res.code==1 && res.data) {
              this.secondHand.contact_way = res.data.contact_way;this.secondHand.linkman = res.data.linkman;
            }
          })
    },
  },
  created() {
    this.token = localStorage.getItem('hbzg')
    this.getIndustry() //获取行业分类
    this.getJobCate() //获取岗位列表
    // this.getLocation() //获取城市
    this.getConfig() //获取价格配置
    this.getSalary() //获取岗位薪资
    this.getDegreeLeveList() //获取学历选项
    this.getWorkExperienceList() //获取工作经验
    this.getUserInfo() //获取用户信息
    this.getHistory() //获取发布记录
  },
}
</script>

<style scoped>
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    font-size: 30px;
    color: #666666;
  }
  .add_list_warp{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 23;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
  }
  .add_list_center{
    width: 30vw;
    overflow-y: auto;
    background: #FFFFFF;
    border-radius: 10px 10px 10px 10px;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    box-sizing: border-box;
    padding-top: 18px;
    padding-bottom: 15px;
    position: relative;
  }
  .add_list_center::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .add_button{
    width: 24vw;
    height: 8vh;
    background: #FF8A00;
    border-radius: 10px 10px 10px 10px;
    font-size: 24px;
    margin: 12px auto;
    cursor: pointer;
    transition: all 0.2s;
  }
  .add_button:hover{
    font-size: 28px;
    text-decoration: none;
  }
  .add_warp{
    position: relative;
    background-color: #ffffff;
    width: 540px;

    padding-bottom: 25px;
  }
  .add_cont::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .add_cont{
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-y: auto;
    max-height: 70vh;
  }
  .add_title{
    width: 540px;
    height: 50px;
    position: absolute;
    z-index: 33;
    background: #1572F9;
  }
  .colse_icon{
    position: absolute;
    top: 50%;
    right: 21px;
    transform: translateY(-50%);
    font-size: 22px;
    cursor: pointer;
  }
  .add_item{
    padding: 13px 55px 13px 30px;
    border-bottom: 1px dashed #EEEEEE;
    color: #000000;
    font-size: 16px;
  }
  .add_left{
    text-align: right;
    width: 110px;
  }
  .add_item input{
    width: 260px;
    height: 38px;
    background: #FFFFFF;
    border-radius: 2px 2px 2px 2px;
    border: 1px solid #D2D2D2;
    box-sizing: border-box;
    padding: 0 17px;
    font-size: 16px;
    margin-left: 23px;
  }
  .add_select ::v-deep.el-input__inner{
    width: 253px;
    height: 38px;
    background: #FFFFFF;
    border-radius: 2px 2px 2px 2px;
    border: 1px solid #D2D2D2;
    box-sizing: border-box;
    padding: 0 17px;
    font-size: 16px;
    margin-left: 20px;
  }
  .add_select ::v-deep.el-textarea__inner{
    width: 253px;
    background: #FFFFFF;
    border-radius: 2px 2px 2px 2px;
    border: 1px solid #D2D2D2;
    box-sizing: border-box;
    padding: 0 17px;
    font-size: 16px;
  }
  .add_select ::v-deep.el-upload-list--picture-card .el-upload-list__item{
    width: 107px;
    height: 107px;
  }
  .add_select ::v-deep.el-upload--picture-card{
    width: 107px;
    height: 107px;
    line-height: 107px;
  }
  .submit_button{
    width: 443px;
    height: 65px;
    background: #007AFF;
    border-radius: 4px 4px 4px 4px;
    font-size: 20px;
    color: #ffffff;
    margin: 0 auto;
    margin-top: 19px;
    cursor: pointer;
    transition: all 0.2s;
  }
  .submit_button:hover{
    transform: scale(1.03);
  }
</style>
