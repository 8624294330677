<template>
  <div class="after_warp">
    <!--退款商品-->
    <div class="dif" style="margin-bottom: 35px;">
      <p class="fs18" style="color:#414141;margin-top: 39px;">退款商品</p>
      <div>
        <div class="dif alc mrBtm20" v-for="(item,index) in info.goods_arr" :key="index">
          <img :src="item.image" alt="" class="after_img">
          <div>
            <p class="fs18 col000">{{item.name}}</p>
            <p class="fs14" style="color: #A0A0A0;margin-top: 4px;">{{item.attr_txt}}</p>
            <p class="bold mrTop10" style="color: #F75C5C">
              <span class="fs18">￥</span>
              <span class="fs24">{{item.total_price}}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--物流公司-->
    <div class="dif alc">
      <div>物流公司：</div>
      <div style="width: 280px;">
        <el-input placeholder="请输入物流公司" v-model="obj.user_send_express"></el-input>
      </div>
    </div>
    <div class="dif alc mrTop30">
      <div>物流单号：</div>
      <div style="width: 280px;">
        <el-input placeholder="请输入物流单号" v-model="obj.user_send_express_no"></el-input>
      </div>
    </div>
    <!--提交-->
    <div class="submit_button difcac colfff fs24" @click="submit()">提交</div>
  </div>
</template>

<script>
export default {
  name: "retrunSubmit",
  data() {
    return {
      radio: '1',
      token: '',
      dialogImageUrl: '',
      dialogVisible: false,
      id: '',//订单id
      info: {
        id: '',
        goods_arr: [],
      },
      obj: {
        id: '',
        user_send_express: '',//快递公司
        user_send_express_no: '',//快递单号
      },
      fileList: [],//照片

    }
  },
  methods: {
    submit() { //提交审核
      let data = this.obj
      if(data.user_send_express==='') {
        return this.$alert('请输入物流公司',{type: 'error'})
      }else if(data.user_send_express_no==='') {
        return this.$alert('请输入物流单号',{type: 'error'})
      }
      this.$axios("POST","/order_refund/sendExpress",data)
          .then(res => {
            if(res.code===1) {
              this.$alert('提交成功',{type: 'success'})
                  .then(a => {
                        this.$router.replace({
                          path: '/me/myOrder'
                        })
                      }
                  ).catch(a => {
                    this.$router.replace({
                      path: '/me/myOrder'
                    })
                  }
              )

            }else {
              this.$alert(res.msg,{type: 'error'})
            }
          })
    },
    //上传图片相关
    onSuccess(res, file, fileList) {
      this.fileList = fileList
    },
    onRemove(file,fileList) {
      this.fileList = fileList
    },
    onExceed() {
      this.$alert('最多上传3张照片',{type: 'info'})
    },
  },
  created() {
    this.id = this.$route.query.id
    this.obj.id = this.$route.query.id
    this.token = localStorage.getItem('hbzg')
    this.info = JSON.parse(localStorage.getItem('hbAfter'))
  }
}
</script>

<style scoped>
.after_warp{
  min-height: 821px;
  background: #FFFFFF;
  box-sizing: border-box;
  padding-left: 46px;
  padding-top: 27px;
}
.after_img{
  width: 106px;
  height: 106px;
  background-color: #EEEEEE;
  margin: 0 22px;
}
.after_textarea{
  width: 538px;
  height: 153px;
  background: #FFFFFF;
  border: 1px solid #969696;
  margin-left: 5px;
  font-size: 14px;
  box-sizing: border-box;
  padding: 21px 14px;
}
.submit_button{
  width: 443px;
  height: 65px;
  background: #007AFF;
  border-radius: 4px 4px 4px 4px;
  margin-top: 57px;
  cursor: pointer;
  transition: all 0.2s;
  margin-left: 25px;
}
.submit_button:hover{
  background: #0164cd;

}
</style>