<template>
  <div class="order_warp box pl32 pr32" v-loading="isLoading">
    <div class="pt32 pb22" style="border-bottom: 2px solid #1572F9">
      <p class="fz20 col888">订单详情</p>
    </div>
    <div class="info_warp posr" v-if="info">
      <div class="dif posa" style="top: 24px;right: 26px;">

        <div class="dif pointer" style="align-items: flex-start">
          <div class="posr mr9" style="min-width: 100px">
            <img src="@/assets/images/dui.png" v-if="info.audit_status>1 && (info.reject_status>1 || info.reject_status==0)" class="w-28 ml7 h-28 block" alt="">
            <img src="@/assets/images/sc.png" v-else-if="info.reject_status===1" class="w-28 ml7 h-28 block" alt="">
            <div class="small_radio difcac ml7" v-else>1</div>
            <p class="mt8 bold fz14" :style="{color: info.audit_status>1 && (info.reject_status>1 || info.reject_status==0)?'#1572F9':info.reject_status===1?'#E70000':'#888888'}">信息部</p>
            <p class="mt6  fz14" :style="{color: info.audit_status>1 && (info.reject_status>1 || info.reject_status==0)?'#1572F9':info.reject_status===1?'#E70000':'#888888'}" v-if="info.auth_name1">{{info.auth_name1}}</p>
            <p class="mt4  fz12" :style="{color: info.audit_status>1 && (info.reject_status>1 || info.reject_status==0)?'#1572F9':info.reject_status===1?'#E70000':'#888888'}" v-if="info.auth_name1">{{info.authtime1}}</p>
            <div class="w-42 mt14 posa" style="border-top: 1px solid #888;top: 0;right: 9px;"></div>
          </div>
          <div class="posr mr9" style="min-width: 100px">
            <img src="@/assets/images/dui.png" v-if="info.audit_status>2 && (info.reject_status>2 || info.reject_status==0)" class="w-28 ml7 h-28 block" alt="">
            <img src="@/assets/images/sc.png" v-else-if="info.reject_status===2" class="w-28 ml7 h-28 block" alt="">
            <div class="small_radio difcac ml7" v-else>2</div>
            <p class="mt8 bold fz14" :style="{color: info.audit_status>2 && (info.reject_status>2 || info.reject_status==0)?'#1572F9':info.reject_status===2?'#E70000':'#888888'}">财务部</p>
            <p class="mt6  fz14" :style="{color: info.audit_status>2 && (info.reject_status>2 || info.reject_status==0)?'#1572F9':info.reject_status===2?'#E70000':'#888888'}" v-if="info.auth_name2">{{info.auth_name2}}</p>
            <p class="mt4  fz12" :style="{color: info.audit_status>2 && (info.reject_status>2 || info.reject_status==0)?'#1572F9':info.reject_status===2?'#E70000':'#888888'}" v-if="info.auth_name2">{{info.authtime2}}</p>
            <div class="w-42 mt14 posa" style="border-top: 1px solid #888;top: 0;right: 9px;"></div>
          </div>
          <div class="posr mr9" style="min-width: 100px">
            <img src="@/assets/images/dui.png" v-if="info.audit_status>3 && (info.reject_status>3 || info.reject_status==0)" class="w-28 ml7 h-28 block" alt="">
            <img src="@/assets/images/sc.png" v-else-if="info.reject_status===3" class="w-28 ml7 h-28 block" alt="">
            <div class="small_radio difcac ml7" v-else>3</div>
            <p class="mt8 bold fz14" :style="{color: info.audit_status>3 && (info.reject_status>3 || info.reject_status==0)?'#1572F9':info.reject_status===3?'#E70000':'#888888'}">总经理</p>
            <p class="mt6  fz14" :style="{color: info.audit_status>3 && (info.reject_status>3 || info.reject_status==0)?'#1572F9':info.reject_status===3?'#E70000':'#888888'}" v-if="info.auth_name3">{{info.auth_name3}}</p>
            <p class="mt4  fz12" :style="{color: info.audit_status>3 && (info.reject_status>3 || info.reject_status==0)?'#1572F9':info.reject_status===3?'#E70000':'#888888'}" v-if="info.auth_name3">{{info.authtime3}}</p>
            <div class="w-42 mt14 posa" style="border-top: 1px solid #888;top: 0;right: 9px;"></div>
          </div>
          <div class="posr" style="min-width: 100px">
            <img src="@/assets/images/dui.png" v-if="info.audit_status>4 && (info.reject_status>4 || info.reject_status==0)" class="w-28 ml7 h-28 block" alt="">
            <img src="@/assets/images/sc.png" v-else-if="info.reject_status===4" class="w-28 ml7 h-28 block" alt="">
            <div class="small_radio difcac ml7" v-else>4</div>
            <p class="mt8 bold fz14" :style="{color: info.audit_status>4 && (info.reject_status>4 || info.reject_status==0)?'#1572F9':info.reject_status===4?'#E70000':'#888888'}">财务打款</p>
            <p class="mt6  fz14" :style="{color: info.audit_status>4 && (info.reject_status>4 || info.reject_status==0)?'#1572F9':info.reject_status===4?'#E70000':'#888888'}" v-if="info.auth_name4">{{info.auth_name4}}</p>
            <p class="mt4  fz12" :style="{color: info.audit_status>4 && (info.reject_status>4 || info.reject_status==0)?'#1572F9':info.reject_status===4?'#E70000':'#888888'}" v-if="info.auth_name4">{{info.authtime4}}</p>
          </div>
        </div>
        
        <p class="fz20" style="color: #E7A100" v-if="info.status_text==='审核中'">{{info.status_text}}</p>
        <p class="fz20" style="color: #04C631" v-if="info.status_text==='已通过'">{{info.status_text}}</p>
        <p class="fz20" style="color: #E70000" v-if="info.status_text==='已驳回'">{{info.status_text}}</p>
      </div>

      <div class="difb">
        <p class="fz20 bold text_333">订单号：{{info.order_no}}</p>

      </div>
      <div class="difb mt18" style="align-items: flex-end">
        <div>
          <p class="mb12 fz18 col888">采购公司名称：{{info.caigou_name}}</p>
          <p class="mb12 fz18 col888">供应商公司名称：{{info.gongying_name}}</p>
          <p class="mb12 fz18 col888">产品名称：{{info.product_name}}</p>
          <p class="mb12 fz18 col888">合同金额：{{info.contract_amount}}</p>
          <p class="mb12 fz18 col888">定金金额：{{info.deposit_amount}}</p>
          <p class="mb12 fz18 col888">采购商提成金额：{{info.caigou_ticheng}}</p>
          <p class="mb12 fz18 col888" v-if="info.audit_status==6">驳回理由：{{info.reason}}</p>
          <p class="mb12 fz18 col888">备注：{{info.remark || '暂无备注'}}</p>
          <p class="mb12 fz18 col888" v-viewer>
            <span style="vertical-align: top">宣传图片：</span>
            <img :src="item" v-for="(item,index) in info.publicity_images" class="w-150 h-150 mb10 mr10" style="display: inline-block" alt="">
          </p>
          <p class="mb12 fz18 col888" v-if="info.audit_status==5" v-viewer>
            <span style="vertical-align: top">打款凭证：</span>
            <img :src="info.remit_image" class="w-150 h-150 mb10 mr10" style="display: inline-block" alt="">
          </p>
          <div class="dif alc mt30"  v-if="$store.state.userInfo.id">
            <img :src="$store.state.userInfo.avatar" alt="" class="bra block w-54 h-54" style="background: #eee">
            <div class="ml14">
              <p class="fz20 col333">{{$store.state.userInfo.nickname}}</p>
              <p class="mt4 fz16 col888">申请时间：{{info.createtime_str}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "commissionDetail",
  data() {
    return {
      id: '',
      info: null,
      isLoading: false
    }
  },
  methods: {
    getDetail() {
      this.isLoading = true
      this.$axios('POST','/push_money/getInfo',{id: this.id})
          .then(res => {
            this.isLoading = false
            if(res.code===1) {
              if(res.data.reject_status) {
                res.data['auth_name'+res.data.reject_status] = res.data.reject_name
                res.data['authtime'+res.data.reject_status] = res.data.reject_time
              }
              this.info = res.data
            }else {
              this.$alert(res.msg,'提示',{type: 'error'})
                  .then(res => this.$router.back())
                  .catch(res => this.$router.back())
            }
          })
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.getDetail()
  }
}
</script>

<style scoped>
.borderleft{
  position: absolute;
  height: 100%;
  width: 1px;
  background: #707070;
  left: 14px;
  bottom: -33px;
}
.small_radio{
  width: 28px;
  height: 28px;
  font-size: 16px;
  box-sizing: border-box;
  border-radius: 50%;
  color: #888;
  border: 1px solid #707070;
}
  .order_warp{
    width: 100%;
    box-sizing: border-box;
    background: #FFF;
    padding-bottom: 40px;
  }
  .info_warp{
    box-sizing: border-box;
    margin-top: 22px;
    /*height: 452px;*/
    padding: 24px;
    background: #F7F6FA;
    border-radius: 20px 20px 20px 20px;
  }
</style>