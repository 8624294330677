<template>
  <div class="after_warp">
    <!--售后商品-->
    <div class="dif" style="margin-bottom: 35px;">
      <p class="fs18" style="color:#414141;margin-top: 39px;">退货商品</p>
      <div>
        <div class="dif alc mrBtm20" v-for="(item,index) in info.goods_arr" :key="index">
          <img :src="item.image" alt="" class="after_img">
          <div>
            <p class="fs18 col000">{{item.name}}</p>
            <p class="fs14" style="color: #A0A0A0;margin-top: 4px;">{{item.attr_txt}}</p>
            <p class="bold mrTop10" style="color: #F75C5C">
              <span class="fs18">￥</span>
              <span class="fs24">{{item.total_price}}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="dif alc" style="margin-bottom: 52px;">
      <div class="fs18" style="color: #414141">退款金额：</div>
      <div class="fs18 text-red bold">￥{{info.pay_price}}</div>
    </div>
    <!--服务类型-->
    <div class="dif alc" style="margin-bottom: 52px;">
      <div class="fs18" style="color: #414141">服务类型：</div>
      <el-radio-group v-model="radio" @change="changeRadio">
        <el-radio v-model="radio" label="1"><span class="fs18" style="color: #414141">仅退款</span></el-radio>
        <el-radio v-model="radio" label="2"><span class="fs18" style="color: #414141">退货退款</span></el-radio>
      </el-radio-group>
    </div>
    <div class="dif alc" style="margin-bottom: 52px;">
      <div class="fs18" style="color: #414141">货物状态：</div>
      <el-select v-model="obj.goods_status" placeholder="请选择">
        <el-option
            v-for="item in types"
            :label="item.name"
            :value="item.id">
        </el-option>
      </el-select>
    </div>
    <div class="dif alc" style="margin-bottom: 52px;">
      <div class="fs18" style="color: #414141">退款原因：</div>
      <el-select v-model="obj.reason_type" placeholder="请选择">
        <el-option
            v-for="item in resons"
            :label="item.name"
            :value="item.id">
        </el-option>
      </el-select>
    </div>
    <!--售后说明-->
    <div class="dif" style="margin-bottom: 22px;">
      <div class="fs18" style="color: #414141">退款说明：</div>
      <textarea placeholder="请输入退款说明" class="after_textarea" v-model="obj.desc"></textarea>
    </div>
    <!--上传图片-->
    <div class="dif">
      <div class="fs18" style="color: #414141">上传图片：</div>
      <div>
        <el-upload
            :action="$store.state.upLoadUrl"
            :class="{hide:hideUpload}"
            :headers="{'token': token}"
            :file-list="fileList"
            :on-remove="onRemove"
            :limit="3"
            :on-success="onSuccess"
            :on-exceed="onExceed"
            :on-change="onChange"
            list-type="picture-card">
          <i class="el-icon-plus" ></i>
        </el-upload>
      </div>
    </div>
    <!--提交-->
    <div class="submit_button difcac colfff fs24" @click="submit()">提交</div>
  </div>
</template>

<script>
export default {
  name: "after",
  data() {
    return {
      hideUpload: false,
      radio: '1',
      token: '',
      dialogImageUrl: '',
      dialogVisible: false,
      id: '',//订单id
      info: {
        id: '',
        goods_arr: [],
      },
      obj: {
        order_id: '',
        desc: '',//描述
        pic_images: '',//照片
        reason_type: 1,
        goods_status: 1,
      },
      fileList: [],//照片

      types: [
        {id: 1,name: '已收到货'},
        {id: 0,name: '未收到货'},
      ],
      resons: [
        {id: 1,name: '七天无理由退货'},
        {id: 0,name: '其他'},
      ],
    }
  },
  methods: {
    changeRadio(e) {
      if(e==2) {
        this.types = [
          {id: 1,name: '已收到货'},
        ]
        this.obj.goods_status = 1
      }else {
        this.types = [
          {id: 1,name: '已收到货'},
          {id: 0,name: '未收到货'},
        ]
        this.obj.goods_status = 1
      }
    },
    submit() { //提交审核
      let data = this.obj
      data.back_type = this.radio
      let imgs = []
      for(let item of this.fileList) {
        if(item.response.code==1) {
          imgs.push(item.response.data.url)
        }
      }
      data.pic_images = imgs.join(',')
      if(data.desc==='') {
        return this.$alert('请输入退款说明',{type: 'error'})
      }
      this.$axios("POST","/order_refund/apply",data)
      .then(res => {
        if(res.code===1) {
          this.$alert('申请成功,请等待审核',{type: 'success',showClose: false})
          .then(a => {
            this.$router.replace({
              path: '/me/myOrder'
            })
          }).catch(a => {
            this.$router.replace({
              path: '/me/myOrder'
            })
          })
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    //上传图片相关
    onSuccess(res, file, fileList) {
      this.fileList = fileList
    },
    onRemove(file,fileList) {
      this.fileList = fileList
      this.hideUpload = fileList.length >= 3;
    },
    onChange(file,fileList) {
      this.hideUpload = fileList.length >= 3
    },
    onExceed() {
      this.$alert('最多上传3张照片',{type: 'info'})
    },
  },
  created() {
    this.id = this.$route.query.id
    this.obj.order_id = this.$route.query.id
    this.token = localStorage.getItem('hbzg')
    this.info = JSON.parse(localStorage.getItem('hbAfter'))
  }
}
</script>
<style>
.hide .el-upload--picture-card {
  display: none;
}
</style>
<style scoped>

  .after_warp{
    min-height: 821px;
    background: #FFFFFF;
    box-sizing: border-box;
    padding-left: 46px;
    padding-top: 27px;
  }
  .after_img{
    width: 106px;
    height: 106px;
    background-color: #EEEEEE;
    margin: 0 22px;
  }
  .after_textarea{
    width: 538px;
    height: 153px;
    background: #FFFFFF;
    border: 1px solid #969696;
    margin-left: 5px;
    font-size: 14px;
    box-sizing: border-box;
    padding: 21px 14px;
  }
  .submit_button{
    width: 443px;
    height: 65px;
    background: #007AFF;
    border-radius: 4px 4px 4px 4px;
    margin-top: 57px;
    cursor: pointer;
    transition: all 0.2s;
    margin-left: 25px;
  }
  .submit_button:hover{
    background: #0164cd;

  }
</style>