<template>
  <div>
    <!--店铺标题logo-->
    <div class="shop_top">
       <div class="mr0At box difb alc" style="width: 1150px;padding-right: 146px;">
         <div class="dif alc" @click="goShop()">
           <div class="difcac logo pointer">
             <img :src="info.shop_arr.logo_image" class=" h100" style="width: auto;max-width: 140px">
           </div>
           <div class="fs16 col000 pointer hover">
             <div class="dif alc">
               <p class="hover">{{info.shop_arr.name}}</p>
               <p class="hover mrLft20">联系电话：{{info.shop_arr.service_tel}}</p>
             </div>
             <p class="hover">{{info.shop_arr.address}}{{info.shop_arr.info_address}}</p>
           </div>
         </div>
         <div class="difcac pointer hover message_button" @click="goMessage()">
           <img src="@/assets/images/message.png" style="width: 16px;height: 16px;">
           <p class="fs16 colfff" style="margin-left: 7px;">留言</p>
         </div>
       </div>
    </div>
    <!--商品信息-->
    <div class="goods_warp">
      <!--商品图片和规格-->
      <div class="dif mrBtm20">
        <div class="img_warp fs">
          <el-carousel height="220px" :autoplay="autoplay" trigger="click">
            <el-carousel-item v-if="info.video_file">
              <video id="video" :src="info.video_file" controls class="w100 h100"></video>
            </el-carousel-item>
            <el-carousel-item v-for="(item,index) in info.details" :key="index">
              <img :src="item" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>
        <div>
          <!--标题-->
          <p style="width: 492px;word-break: break-all;line-height: 41px;color: #414141;margin-bottom: 23px;"
             class="bold fs18">{{info.name}}</p>
          <!--规格-->
          <div class="dif" v-for="(item,index) in info.spec.item" :key="item.id">
            <div class="spec_title">{{item.name}}</div>
            <div class="dif difw" style="width: 610px;">
              <div class="spec_item difcac"
                   @click="changeSpec(index,item2)"
                   :class="{'active': isActive(index,item2)}"
                   v-for="(item2,index2) in item.sku_items" :key="item2.id">{{item2.name}}</div>
            </div>
          </div>
          <!--数量-->
<!--          <div class="dif">
            <div class="spec_title">数量</div>
            <el-input-number v-model="num"  :min="1" size="small"></el-input-number>
          </div>-->
          <!--价格-->
<!--          <div class="bold" style="color: #F75C5C;margin-top: 51px;">
            <span class="fs18">￥</span>
            <span class="fs24">{{specPrice}}</span>
          </div>-->
          <!--立即购买-->
<!--          <div class="dif alc" style="margin-top: 60px;">
            <div class="button difcac" @click="goBuy()">
              <span>立即购买</span>
            </div>
            <div class="button difcac" @click="addCart()">
              <span>添加到购物车</span>
            </div>
            <span style="color: #A0A0A0" class="fs16">库存：{{stock}}</span>
            <span style="color: #A0A0A0;margin-left: 50px;" class="fs16">已售：{{info.sales}}</span>
          </div>-->
        </div>
      </div>
      <div>
        <el-tabs v-model="activeName">
          <el-tab-pane v-for="(item,index) in cates" :key="index"
                       :label="item.name" :name="item.id"></el-tab-pane>
        </el-tabs>
        <!--商品详情-->
      </div>
      <div v-html="info.info_content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "goodsDetail",
  data() {
    return {
      autoplay: true,
      videoContext: null,
      id: '',//商品id
      num: 1,
      activeName: '1',
      cates: [{id: '1',name: '商品详情'},],
      info: {
        id: '',
        image: '',
        name: '',
        details: [],
        sales: 0,
        mch_id: 0,
        info_contet: '',
        price: '',
        stock: 0,
        market_price: '',
        spec: {
          groups: [],
          item: [],
          products: {},
        },
        shop_arr: {
          id: '',
          name: '',
          address: '',
          logo_image: '',
          info_address: '',
        },
      },
      selectSpec: [],//选中的规格
      specPrice:'',
      stock: '',//库存
      currentSpec: null,//当前选中的规格集合
    }
  },
  methods: {
    //选择规格
    changeSpec(index,item) {
      this.selectSpec[index] = item.id
      this.$forceUpdate()
      this.getSpecPrice();
    },
    //获取当前选择规格价格
    getSpecPrice() {
      let arr = []
      for(let item of this.selectSpec) {
        if(!item) return
        arr.push(item)
      }
      let spec_id = arr.join('_')
      this.currentSpec = this.info.spec.products[spec_id]
      this.specPrice = this.currentSpec.price
      this.stock = this.currentSpec.stock
      console.log(this.currentSpec);
    },
    //立即购买
    goBuy() {
      let token = localStorage.getItem('hbzg')
      if(!token) {
        this.$router.push({
          path: '/login',query:{type: 0}
        })
        return
        return this.$alert('请登录后操作',{type: 'error'})
            .then(res=>{
              this.$router.push({
                path: '/login',query:{type: 0}
              })
            })
      }
      if(!this.currentSpec) {
        return this.$alert('请选择规格',{type: 'error'})
      }
      console.log(this.currentSpec);
      let list = [{
        attr_txt: this.currentSpec.attr.join(','),
        good_image: this.info.image,
        good_name: this.info.name,
        good_price: this.currentSpec.price,
        num: this.num,
        sku_id: this.currentSpec.id
      }]
      localStorage.setItem('hbcart',JSON.stringify(list))
      this.$router.push({
        path: '/payOrder',
        query: {type: 1}
      })
    },
    //留言
    goMessage() {
      let token = localStorage.getItem('hbzg')
      if(!token) {
        return this.$alert('请登录后操作',{type: 'error'})
            .then(res=>{
              this.$router.push({
                path: '/login',query:{type: 0}
              })
            })
      }
      let path = this.$router.resolve({
        path: '/message',
        query: {id: this.info.shop_arr.id}
      })
      window.open(path.href)
    },
    //商家详情
    goShop() {
      this.$router.push({
        path: '/shopDetail',
        query: {
          id: this.info.mch_id
        }
      })
    },
    //获取商品详情
    getGoodsDetail() {
      this.$axios("POST","/Goods/getGoodInfo",{id: this.id},'加载中')
      .then(res => {
        if(res.code===1) {
          this.info = res.data
          this.specPrice = res.data.price
          this.stock = res.data.stock
          //设置规格
          for(let item of this.info.spec.item) {
            this.selectSpec.push('')
          }
          //如果只有一个规格 默认选择
          if(this.info.spec.item.length===1) {
            console.log(this.info.spec.item[0]);
            this.changeSpec(0,this.info.spec.item[0].sku_items[0])
          }
          this.$nextTick(() => {
            if(this.info.video_file) {
              this.videoContext = document.getElementById('video')
              this.videoContext.addEventListener('play',() => {
                this.autoplay = false
                console.log('开始播放')
              })
              this.videoContext.addEventListener('ended',() => {
                this.autoplay = true
              })
              this.videoContext.addEventListener('pause',() => {
                this.autoplay = true
              })
            }
          })
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    //添加到购物车
    addCart() {
      let token = localStorage.getItem('hbzg')
      if(!token) {
        this.$router.push({
          path: '/login',query:{type: 0}
        })
        return
        return this.$alert('请登录后操作',{type: 'error'})
            .then(res=>{
              this.$router.push({
                path: '/login',query:{type: 0}
              })
            })
      }
      if(!this.currentSpec) {
        return this.$alert('请选择规格',{type: 'error'})
      }
      let data = {
        sku_id: this.currentSpec.id,
        count: this.num
      }
      this.$axios("POST","/Cart/addToCart",data,'加载中')
      .then(res => {
        if(res.code===1) {
          this.$message.success('添加成功')
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
  },
  computed: {
    //判断规格是否选中
    isActive() {
      return function (index,item) {
        if(this.selectSpec.length>0) {
          if(this.selectSpec[index]===item.id) {
            return true
          }else {
            return false
          }
        }
        return  false
      }
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getGoodsDetail();
  }
}
</script>

<style scoped>
  .message_button{
    background-color: #3765FF;
    border-radius: 10px;
    padding: 8px 12px;
  }
  .shop_top{
    border: 1px solid #EEEEEE;
    padding-top: 11px;
    padding-bottom: 19px;
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 24px;
  }
  .logo{
    max-width: 140px;
    height: 60px;
    background: #1572F9;
    margin-right: 28px;
    font-weight: bold;
    font-size: 18px;
    color: #ffffff;
  }
  .goods_warp{
    width: 1200px;
    margin: 0 auto;
  }
  .img_warp{
    width: 220px;
    height: 222px;
    margin-right: 46px;
  }
  .img_warp img{
    width: 100%;
    height: 100%;
    background-color: #EEEEEE;
  }
  .spec_title{
    font-size: 14px;
    color: #868686;
    margin-right: 40px;
    padding-top: 5px;
  }
  .spec_item{
    box-sizing: border-box;
    color: #414141;
    font-size: 14px;
    min-width: 68px;
    height: 30px;
    padding: 0 20px;
    background: #FFFFFF;
    border: 1px solid #B8B7BD;
    margin-right: 6px;
    margin-bottom: 14px;
    cursor: pointer;
    transition: all 0.2s;
  }
  .spec_item:hover{
    background-color: #1572F9;
    color: #ffffff;
    border: 1px solid #1572F9;
  }
  .spec_item.active{
    background-color: #1572F9;
    color: #ffffff;
    border: 1px solid #1572F9;
  }

  .button{
    width: 180px;
    height: 40px;
    background: #1572F9;
    font-size: 16px;
    color: #ffffff;
    transition: all 0.2s;
    cursor: pointer;
    margin-right: 25px;
  }
  .button:hover{
    background: #015bd5;
  }

  /*分类*/
  ::v-deep .el-tabs__item{
    font-size: 32px;
    width: 200px;
    text-align: center;
  }
  ::v-deep .el-tabs__nav-scroll{
    padding-bottom: 30px;
  }
  ::v-deep .el-tabs__nav-next .el-icon-arrow-right{
    font-size: 30px;
    padding-top: 5px;
  }
  ::v-deep .el-tabs__nav-prev .el-icon-arrow-left{
    font-size: 30px;
    padding-top: 5px;
  }
  ::v-deep .el-tabs__item.is-active{
    color: #1572F9;
  }
  ::v-deep .el-tabs__active-bar{
    bottom: -30px;
    height: 6px;
    background-color: #1572F9;
  }
  ::v-deep .el-tabs__active-bar:after{
    content: '';
    width: 0;
    height: 0;
    border-bottom: 20px solid #1572F9;
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,-100%);
  }
  ::v-deep .el-tabs__nav-wrap::after{
    height: 6px;
  }
</style>