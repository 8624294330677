<template>
  <div>
    <!--轮播图-->
    <div class="swiper_warp  mt5">
      <div class="difb">
        <div class="fenlei" style="padding: 20px;">
          <div class="difb alc">
            <div class=" logo pointer">
              <img :src="info.logo_image" class=" h100" style="width: auto;max-width: 140px">
            </div>
            <div class="difcac pointer hover message_button" @click="goMessage()">
              <img src="@/assets/images/message.png" style="width: 16px;height: 16px;">
              <p class="fs16 colfff" style="margin-left: 7px;">留言</p>
            </div>
          </div>
          <p class="hover mt10">{{info.name}}</p>
          <p class="hover mt10">联系电话：{{info.service_tel || '无'}}</p>
          <p class="hover mt10">地址：{{info.address}}{{info.info_address}}</p>
        </div>
        <div class="w-900">
          <!--轮播图-->
          <div class="swiper">
            <el-carousel height="237px" >
              <el-carousel-item v-for="item in swiper" :key="item.id">
                <img :src="item.slideimage" class="w100 h100 bgcb" @click="goSwiper(item)">
              </el-carousel-item>
            </el-carousel>
          </div>
          <!--logo-->
          <div class="difb alc mt5">
            <img :src="item.slideimage" @click="goSwiper(item)" class="block pointer w-447 h-70" v-for="(item,index) in logos" :key="item.id">
          </div>
        </div>
      </div>
      <!--推荐商家-->
      <div class="dif pl14 shadow_bottom pt5" v-if="referMerchants.length>0 ">
        <template>
          <div class="shop_item3" v-for="(item,index) in referMerchants" :key="item.id" @click="goShopDetail(item)">
            <img :src="item.cover_image" class="w100" style="display: block;height: 215px;" :title="item.name" :alt="item.name">
            <div class="shop_cont posr">
              <div class="shop_vip difcac" v-if="item.vip_level==2">VIP商户</div>
              <div class="shop_vip difcac" style="color: #000" v-if="item.vip_level==3">钻石VIP商户</div>
              <!--<div class="shop_vip_black difcac" v-if="item.vip_level==3">钻石VIP商户</div>-->
              <p class="one_overflow col333 bold fs14">{{item.name}}</p>
              <p class="one_overflow col333" style="font-size: 12px;margin-top: 4px;">{{item.info_address}}</p>
            </div>
          </div>
          <!--<div class="shop_item2" v-for="(item,index) in referMerchants" :key="item.id" @click="goShopDetail(item)" style="box-shadow: 0px 4px 10px 1px rgba(0,0,0,0.2);"></div>-->
        </template>
      </div>
    </div>
    <div class="w-1200" style="margin: 0 auto;">
      <el-divider><span class="bold fs20">商品列表</span></el-divider>
    </div>
    <!--商品列表-->
    <div class="mr0At difc"  v-loading="showLoding">
      <!--右侧商品-->
      <div class="right_goods">
        <!--新-->
        <div class="dif difw" v-if="rightGoods.length>0">
          <div class="goods_item pointer" v-for="(item,index) in rightGoods" :key="index" @click="goDetail(item)">
            <div class="img">
              <img :src="item.image" :alt="item.name" :title="item.name">
            </div>
            <div class="box" style="padding: 10px;">
              <div class="two_overflow fz16">{{item.name}}</div>
              <!--<div class="bold" style="color: #F75C5C">
                <span class="fs14">￥</span>
                <span class="fs18">{{item.price}}</span>
              </div>-->
            </div>
          </div>
        </div>
        <div v-else class="box" style="padding-top: 100px;">
          <el-result icon="info" title="提示" subTitle="暂无商品"></el-result>
        </div>
      </div>
    </div>
    <!--分页-->
    <div class="difcac home_page" style="margin: 38px 0 50px 0;">
      <el-pagination
          background
          layout="prev, pager, next"
          :page-count="allPage"
          :current-page="page"
          @current-change="changeSize">
      </el-pagination>
    </div>

    <!--系统公告-->
    <el-dialog
        title="系统公告"
        :visible.sync="showAffiche"
        width="30%"
        center>
      <div v-html="affiche" style="overflow-y: auto;max-height: 60vh"></div>
    </el-dialog>
  </div>
</template>

<script>

import Navbar from "@/components/common/navbar";
import SwiperContent from "@/components/common/swiperContent.vue";
export default {
  name: 'supplierGoods',
  components: {
    SwiperContent,
    Navbar
  },
  data() {
    return {
      topCate: [
        {id: 0,name: '平台推荐'},
        {id: 1,name: '特价专区'},
      ],
      topCurrent: 0,
      leftCurrent: 0,//当前选中的左侧分类
      leftCate: [
        {name: '行业分类'},{name: '建筑'},
      ],//左侧分类
      rightCurrent: 0,//当前选中的右侧分类
      rightGoods: [],//右侧商品
      showLoding: false,//显示加载
      limit: 30,//每页数量
      page: 1,//当前页数
      allPage: 1,//总页数

      timer: null,
      items: [],
      animate: false,

      affiche: '',//系统公告
      showAffiche: false,//显示系统公告
      swiper: [],//轮播图
      logos: [],//logo
      referMerchants: [],//推荐商家
      info: {
        id: '',
        name: '',
        address: '',
        logo_image: '',
        bg_image: [],
        info_address: '',
      },
    }
  },
  methods: {
    getVipDialog() {
      this.$axios("POST","/mchs/isShowHiht",{})
          .then(res => {
            if(res.code==1) {
              if(res.data.is_show==0) return
              let isShowVip = sessionStorage.getItem('isShowVip')
              if(!isShowVip) {
                /*this.$notify({
                   title: '到期提醒',
                   dangerouslyUseHTMLString: true,
                   message: res.data.show_info,
                   duration: 0
                 });*/
                this.$alert(res.data.show_info,'到期提醒',{type: "warning"})
                sessionStorage.setItem('isShowVip',1)
              }
            }
          })
    },
    //获取公告
    getAffiche() {
      this.$axios("POST","/index/getConfig",{name: 'affiche'})
          .then(res => {
            if(res.code==1) {
              let isShowAffiche = sessionStorage.getItem('isShowAffiche')
              if(!isShowAffiche) {
                this.showAffiche = true
                sessionStorage.setItem('isShowAffiche',1)
              }
              this.affiche = res.data
            }
          })
    },
    //点击轮播图跳转
    goSwiper(item) {
      if(item.is_jump==2) {
        this.$router.push({
          path: '/shopDetail',
          query: {id: item.mch_id}
        })
      }
    },
    //获取商户信息
    getShopInfo() {
      this.$axios("POST","/Mchs/getInfo",{id: this.$store.state.userInfo.mch_id})
          .then(res=>{
            if(res.code===1) {
              this.info = res.data
            }else {
              this.$alert(res.msg,{type: 'error'})
            }
          })
    },
    //获取轮播图
    getSwiper() {
      this.$axios("POST","/index/getRotationMap",{module: 2})
          .then(res => {
            if(res.code===1) this.swiper = res.data
          })
      this.$axios("POST","/index/getRotationMap",{module: 12})
          .then(res => {
            if(res.code===1) this.logos = res.data
          })
      this.$axios("POST","/mchs/getList",{
        page: 1, limit: 5,is_top: 1
      }).then(res => {
        this.referMerchants = res.data.list
      })
    },
    //选择上方分类
    selectTop(item,index) {
      this.page = 1
      this.topCurrent = index
      this.getGoodsList()
    },
    //选择左侧分类
    selectLeftCate(item,index) {
      this.leftCate.map(item => {
        item.check = false
        return item
      })
      this.leftCurrent = index
      item.check = true
      if(index===0) {
        this.page = 1
        this.getGoodsList()
        return
      }
      this.rightCurrent = 0
      this.page = 1
      this.getGoodsList()
    },
    //选择右侧分类
    selectRight(index,pindex) {
      this.leftCate.map(item => {
        item.check = false
        return item
      })
      this.leftCurrent = pindex
      this.rightCurrent = index
      this.rightGoods = 0
      this.page = 1
      this.getGoodsList()
    },
    //获取商城分类
    getGoodCate() {
      // this.$axios("POST","/Goods/getGoodCate",{})
      this.$axios("POST","/mchs/getMchCate",{mch_id: this.$store.state.userInfo.mch_id})
          .then(res => {
            if(res.code===1) {
              this.leftCate = res.data
              this.leftCate.unshift({id: '',name: '全部',san_arr: null})
              this.getGoodsList();
            }else {
              this.$message.error(res.msg)
            }
          })
    },
    //获取商品列表
    getGoodsList() {
      this.showLoding = true
      let data = {
        chanpin_type: 1,
        // cate_id: this.leftCate[this.leftCurrent].san_arr[this.rightCurrent].id,
        page: this.page,
        limit: this.limit,
        keyword: this.searchKeyword,
        is_special: this.topCate[this.topCurrent].id
      }
      if(this.leftCate[this.leftCurrent].id=='') {

      }else {
        data.cate_id = this.leftCate[this.leftCurrent].san_arr[this.rightCurrent].id
      }
      this.$axios("POST","/Goods/getGoodList",data)
          .then(res=>{
            this.showLoding = false
            if(res.code===1) {
              this.rightGoods = res.data.list
              this.allPage = Math.ceil(res.data.total/this.limit)
            }else {
              this.$message.error(res.msg)
            }
          })
    },
    goShopDetail(item) {
      this.$router.push({
        path: '/shopDetail',
        query: {
          id: item.id
        }
      })
    },
    //切换页数
    changeSize(e) {
      this.page = e
      window.scrollTo({
        top: 0,//推荐使用，getBoundingClientRect 相对于当前视口的位置
        behavior: "smooth" // 平滑滚动
      });
      this.getGoodsList();
    },
    //商品详情
    goDetail(item) {
      let url = this.$router.resolve({
        path: '/goods',
        query: {
          id: item.id
        }
      })
      window.open(url.href)
    },

    //初始化动画
    initAnime() {
      this.$axios("POST","/common/getPostNewNotice",{})
          .then(res => {
            if(res.code==1) {
              for(let item of res.data) {
                this.items.push(item.text)
              }
              if(res.data.length>4) {
                this.timer=setInterval(this.scroll, 2000);
              }
            }
          })
    },
    // 开始滚动
    scroll() {
      this.animate = true; // 因为在消息向上滚动的时候需要添加css3过渡动画，所以这里需要设置true
      setTimeout(() => {
        this.items.push(this.items[0]);
        this.items.shift();
        this.animate = false; // margin-top 为0 的时候取消过渡动画
      }, 1500);
    },
    // 鼠标移入 暂停动画
    mouseOver(){
      clearInterval(this.timer)
    },
    // 鼠标移出 重新开始动画
    mouseOut(){
      this.timer=setInterval(this.scroll, 2000);
    }
  },
  created() {
    this.getKeyword(this.$route.query)
    this.getAffiche()
    this.getVipDialog()
    this.getSwiper()
    this.getShopInfo()
    this.getGoodCate() //获取商城分类
  },
  watch: {
    $route(newValue) {
      this.getKeyword(this.$route.query)
      this.page  =1
      this.leftCurrent = 0
      this.getGoodCate()
    }
  },
}
</script>

<style scoped>
/*.swiper{*/
/*  !*width: 100%;*!*/
/*  width: 1200px;*/
/*  margin: 0 auto;*/
/*}*/
.leftmenu_item{
  text-align: center;
  font-size: 16px;
  box-sizing: border-box;
  padding: 10px 10px;
  color: #333;
}
.leftmenu_item.active{
  color: #FFF;
  background: #1572F9;
  font-weight: bold;
}

.fenlei{
  width: 290px;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.16);
  height: 314px;
  //scrollbar-width: none; /* Firefox */
  //-ms-overflow-style: none; /* IE 10+ */
  overflow-y: auto;
}
.fenlei::-webkit-scrollbar {
  //display: none; /* Chrome Safari */
}


.left_cate{
  box-sizing: border-box;
  padding: 18px 0;
  width: 150px;
  /*height: 888px;*/
  /*height: 620px;*/
  background: #FFFFFF;
  overflow-y: auto;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.left_cate::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.left_item{
  width: 100%;
  min-height: 34px;
  box-sizing: border-box;
  padding: 8px 4px;
  font-size: 14px;
  color: #000000;
  transition: all 0.3s;
  cursor: pointer;
}
.left_item:hover{
  font-weight: bold;
}
.left_item.active{
  color: #ffffff;
  background-color: #0B8EFE;
}
/*.right_goods{
  margin-left: 18px;
  width: 1200px;
  height: 888px;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px 1px rgba(98, 128, 165, 0.3);
  border-radius: 4px 4px 4px 4px;
  scrollbar-width: none; !* Firefox *!
  -ms-overflow-style: none; !* IE 10+ *!
  box-sizing: border-box;
  padding: 0 20px;
}*/
.right_goods::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
/*.right_cate{
  scrollbar-width: none; !* Firefox *!
  -ms-overflow-style: none; !* IE 10+ *!
  box-sizing: border-box;
  overflow-x: auto;
  padding: 28px 26px;
  white-space: nowrap;
}*/
.right_cate::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.right_item:nth-last-child(1) {
  margin-right: 0;
}
/*.right_item{
  display: inline-block;
  font-size: 28px;
  color: #000000;
  transition: all 0.3s;
  margin-right: 90px;
}*/
.right_item.active{
  color: #1572F9;
}
.right_item:hover{
  color: #1572F9;
}
/* .goods_item{
   width: 275px;
   height: 376px;
   background: #FFFFFF;
   box-shadow: 0px 4px 6px 1px rgba(98, 128, 165, 0.3);
   border-radius: 4px 4px 4px 4px;
   margin-bottom: 25px;
   margin-right: 20px;
 }
 .goods_item:nth-child(4n) {
   margin-right: 0;
 }
 .goods_item .img{
   width: 275px;
   height: 241px;
   background: #f1f1f1;
   box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
   border-radius: 4px 4px 4px 4px;
   overflow: hidden;
 }
 .goods_item .img img{
   transition: all 0.3s;
   width: 100%;
   height: 100%;
 }
 .goods_item:hover .img img{
   transform: scale(1.1);
 }*/
.home_page ::v-deep .number{
  width: 40px;
  height: 40px;
  padding: 6px 5px;
}
.home_page ::v-deep .more{
  width: 40px;
  height: 40px;
  padding: 6px 5px;
}
.home_page ::v-deep .btn-next{
  width: 40px;
  height: 40px;
  padding: 6px 5px;
}
.home_page ::v-deep .btn-prev{
  width: 40px;
  height: 40px;
  padding: 6px 5px;
}




.right_goods{
  width: 1200px;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px 1px rgba(98, 128, 165, 0.3);
  border-radius: 4px 4px 4px 4px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  box-sizing: border-box;
  padding: 0 14px;
  padding-top: 15px;
}
.right_cate{
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  box-sizing: border-box;
  overflow-x: auto;
  padding: 14px 26px;
  white-space: nowrap;
}
.right_item{
  font-size: 16px;
  color: #000000;
  transition: all 0.3s;
  margin-right: 20px;
  margin-bottom: 10px;
}

.goods_item{
  width: 185px;
  height: 225px;
  background: #FFFFFF;
  box-shadow: 0px 4px 6px 1px rgba(98, 128, 165, 0.3);
  border-radius: 4px 4px 4px 4px;
  margin-bottom: 12px;
  margin-right: 12px;
}
.goods_item:nth-child(6n) {
  margin-right: 0;
}
.goods_item .img{
  width: 185px;
  height: 160px;
  background: #f1f1f1;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 4px 4px 4px 4px;
  overflow: hidden;
}
.goods_item .img img{
  transition: all 0.3s;
  width: 100%;
  height: 100%;
}
.goods_item:hover .img img{
  transform: scale(1.1);
}


.swiper_warp{
  width: 1200px;
  margin: 0 auto;
}
.shop_item2{
  margin-right: 10px;
  margin-top: 5px;
  width: 220px;
  height: 80px;
  background: #FFF;
  box-sizing: border-box;
  cursor: pointer;

}
.shop_item2:nth-last-child(1) {
  margin-right: 0;
}
.swiper{
  /*width: 100%;*/
  width: 900px;
}
.shop_warp{
  width: 1200px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.3);
  padding: 20px;
  padding-bottom: 15px;
}
.shop_item3{
  margin-top: 5px;
  width: 215px;
  overflow: hidden;
  /*height: 215px;*/
  margin-right: 24px;
  margin-bottom: 20px;
  position: relative;
  box-shadow: 0px 4px 10px 1px rgba(0,0,0,0.2);
  cursor: pointer;
}
.shop_item3 img{
  transition: all 0.3s;
}
.shop_item3:hover img{
  transform: scale(1.1);
}
.shop_item3:nth-last-child(1) {
  margin-right: 0;
}
.shop_vip{
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-100%);
  /*width: 57px;*/
  height: 29px;
  background: #FFAA00;
  border-radius: 8px 0px 0px 0px;
  font-size: 12px;
  color: #FFFFFF;
  padding: 0 6px;
}
.shop_vip_black{
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-100%);
  height: 29px;
  background: #1C1C1C;
  border-radius: 8px 0px 0px 0px;
  font-size: 12px;
  color: gold;
  padding: 0 6px;
}
.shop_cont{
  width: 100%;
  height: 50px;
  /*position: absolute;*/
  /*bottom: 0;*/
  /*left: 0;*/
  padding: 6px 8px 5px 8px;
  box-sizing: border-box;
  background: rgba(255, 255, 255,1);
}
.shop_item:nth-child(5n) {
  margin-right: 0;
}
.notice{
  width: 280px;
  height: 237px;
  background: #FFFFFF;
  box-sizing: border-box;
  padding-top: 10px;
}
.newsList {
  margin: 0 auto;
  height: 187px;
  overflow: hidden;
}
.anim {
  transition: all 1.5s;
  margin-top: -48px;
}
#con1 li {
  color: #333333;
  list-style: none;
  cursor: pointer;
  transition: all 0.3s;
}
#con1 li:hover{
  text-decoration: none;
  color: #3765FF;
}
.news_item{
  width: 258px;
  position: relative;
  font-size: 14px;
  padding: 14px 0;
  position: relative;
  border-bottom: 1px solid #BFBFBF;
  padding-left: 23px;
}
.news_item:before{
  content: '';
  width: 8px;
  height: 8px;
  background: #1572F9;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.notice_border{
  width: 262px;
  height: 1px;
  border: 1px solid #2AA5E3;
  margin-top: 10px;
}

.top_item{
  font-size: 24px;
  color: #000000;
  cursor: pointer;
  margin-right: 79px;
  transition: all 0.3s;
}
.top_item.active,.top_item:hover{
  color: #1572F9;
  font-weight: bold;
}
</style>

<style>
.popper1{
  border: 1px solid #1572F9 !important;
}
.popper1 .popper__arrow{
  border-right-color: #1572F9 !important;
}
</style>

