<template>
  <div class="order_warp box pl32 pr32" v-loading="showLoading">
    <div class="pt32 pb22" style="border-bottom: 2px solid #1572F9">
      <p class="fz18 col888">申请</p>
    </div>
    <div class="mt24">
      <div class="info_warp mb10 difb alc">
        <p class="fz18 col333"><span style="color: #E70000">*</span>采购商公司名称</p>
        <input type="text" class="trt box h100 w50 fz18" placeholder="请输入采购商公司名称" style="background: rgba(0,0,0,0)" v-model="obj.caigou_name">
      </div>
      <div class="info_warp mb10 difb alc">
        <p class="fz18 col333"><span style="color: #E70000">*</span>供应商公司名称</p>
        <input type="text" class="trt box h100 w50 fz18" placeholder="请输入供应商公司名称" style="background: rgba(0,0,0,0)" v-model="obj.gongying_name">
      </div>
      <div class="info_warp mb10 difb alc">
        <p class="fz18 col333"><span style="color: #E70000">*</span>产品名称</p>
        <input type="text" class="trt box h100 w50 fz18" placeholder="请输入产品名称" style="background: rgba(0,0,0,0)" v-model="obj.product_name">
      </div>
      <div class="info_warp mb10 difb alc">
        <p class="fz18 col333"><span style="color: #E70000">*</span>合同金额</p>
        <input type="number" class="trt box h100 w50 fz18" placeholder="请输入合同金额" style="background: rgba(0,0,0,0)" v-model="obj.contract_amount">
      </div>
      <div class="info_warp mb10 difb alc">
        <p class="fz18 col333">定金金额</p>
        <input type="number" class="trt box h100 w50 fz18" placeholder="请输入定金金额" style="background: rgba(0,0,0,0)" v-model="obj.deposit_amount">
      </div>
      <div class="info_warp mb10 difb alc">
        <p class="fz18 col333"><span style="color: #E70000">*</span>采购商提成金额</p>
        <input type="number" class="trt box h100 w50 fz18" placeholder="请输入采购商提成金额" style="background: rgba(0,0,0,0)" v-model="obj.caigou_ticheng">
      </div>
      <div class="info_warp mb10 pb20">
        <p class="fz18 col333 pt17"><span style="color: #E70000">*</span>宣传图片</p>
        <!--<div class="dif difw">
          <img src="@/assets/images/icon_sc.png" alt="" class="w-96 h-96 block mt16 mr16">
          <div class="w-96 h-96 block mt16 mr16 posr" v-for="(item,index) in obj.publicity_images">
            <img src="" alt="" class="w100 h100 block">
            <div class="posa" style="top: 1px;right: 3px;" @click="obj.publicity_images.splice(index,1)">
              <i class="el-icon-delete-solid fz14 pointer" style="color: #fa3534"></i>
            </div>
          </div>
        </div>-->
        <div class="mt10">
          <el-upload
              :action="$store.state.upLoadUrl"
              :headers="{'token': token}"
              :file-list="fileList"
              :on-remove="onRemove"
              :on-success="onSuccess"
              list-type="picture-card">
            <i class="el-icon-plus" ></i>
          </el-upload>
        </div>
        <div class="mt10" v-viewer>
          <span class="fz18" style="color: #E70000;vertical-align:middle;">请上传成功发布朋友圈分享;成功发布抖音分享，成功发布快手分享截图.例如</span>
          <img :src="item" v-for="(item,index) in images" alt="" style="background: #eee;vertical-align:middle;display:inline-block;" class="w-46 h-72 ml12 pointer">
        </div>
      </div>
      <div class="info_warp mb10 pb20">
        <p class="fz18 col333 pt17">申请规则</p>
        <div class="mt10" v-viewer>
          <img :src="rule" alt="" style="vertical-align:middle;display:inline-block;height: auto" class="w-256  ml12 pointer">
        </div>
      </div>
      <div class="info_warp mb10 difb alc">
        <p class="fz18 col333">备注（选填）</p>
        <input v-model="obj.remark" type="text" class="trt box h100 w50 fz18" placeholder="请输入备注" style="background: rgba(0,0,0,0)">
      </div>
      <div class="s_button difcac pointer" @click="submit()">提交</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "commissionDetail",
  data() {
    return {
      showLoading: false,
      id: '',
      obj: {
        caigou_name: '',
        gongying_name: '',
        product_name: '',
        contract_amount: '',
        deposit_amount: '',
        caigou_ticheng: '',
        publicity_images: [],
        remark: '',
      },
      token: '',
      fileList: [],//宣传图片
      rule: '',//申请规则
      images: []
    }
  },
  methods: {
    getDetail() {
      this.showLoading = true
      this.$axios('POST','/push_money/getInfo',{id: this.id})
          .then(res => {
            this.showLoading = false
            if(res.code===1) {
              for(let i in this.obj) {
                this.obj[i]=res.data[i]
              }
              this.fileList = res.data.publicity_images.map((item,index) => {
                return {
                  url: item,
                  response: {
                    code: 1,
                    data: {
                      url: res.data.publicity_images_old[index]
                    }
                  }
                }
              })
            }
          })
    },
    submit() { //提交
      // if(!this.obj.caigou_name) return this.$alert('请输入采购商公司名称',{type: 'error'})
      // if(!this.obj.gongying_name) return this.$alert('请输入供应商公司名称',{type: 'error'})
      // if(!this.obj.product_name) return this.$alert('请输入产品名称',{type: 'error'})
      // if(!this.obj.contract_amount) return this.$alert('请输入合同金额',{type: 'error'})
      // if(!this.obj.deposit_amount) return this.$alert('请输入定金金额',{type: 'error'})
      // if(!this.obj.caigou_ticheng) return this.$alert('请输入采购商提成金额',{type: 'error'})
      // if(this.fileList.length<=0) return this.$alert('请上传宣传图片',{type: 'error'})
      let data = JSON.parse(JSON.stringify(this.obj))
      data.publicity_images = this.fileList.filter(item => {
        return item.response.code==1
      }).map(item => {
        return item.response.data.url
      }).join(',')
      console.log(data)
      this.showLoading = true
      let url = this.id?'/push_money/resubmit':'/push_money/apply'
      if(this.id) data.id = this.id
      this.$axios('POST',url,data)
          .then(res => {
            this.showLoading = false
            if(res.code===1) {
              this.$alert('申请成功，请等待审核')
                  .then(e => {
                    this.$router.push('/me/myCommission')
                  }).catch(e => {
                this.$router.push('/me/myCommission')
              })
            }else {
              this.$alert(res.msg,{type: 'error'})
            }
          })
    },
    getSystem() {
      this.$axios('POST','/push_money/getRoyaltyRule',{}).then(res => this.rule = res.data)
      this.$axios('POST','/push_money/getXuanchuanExample',{}).then(res => {this.images = res.data} )
    },
    //上传图片相关
    onSuccess(res, file, fileList) {
      this.fileList = fileList
      console.log(fileList);
    },
    onRemove(file,fileList) {
      this.fileList = fileList
    }
  },
  mounted() {
    if(this.$route.query.id) {
      this.id = this.$route.query.id
      this.getDetail()
    }
    this.getSystem()
  }
}
</script>

<style scoped>
.s_button{
  width: 416px;
  height: 52px;
  background: #1572F9;
  border-radius: 26px 26px 26px 26px;
  cursor: pointer;
  color: #FFF;
  font-weight: bold;
  font-size: 20px;
  margin: 30px auto;
}
.order_warp{
  width: 100%;
  box-sizing: border-box;
  background: #FFF;
  padding-bottom: 40px;
}
.info_warp{
  box-sizing: border-box;
  background: #F7F6FA;
  border-radius: 10px 10px 10px 10px;
  padding: 0 24px;
  min-height: 60px;
}
/* chrome 73so.com */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/* 火狐浏览器 */
input[type="number"]{
  -moz-appearance: textfield;
  height: 30px;
  line-height: 30px;
}
</style>
