<template>
  <div>
    <div class="mb8 ">
      <div class="dif alc">
        <div class="dif alc">
          <p>分类：</p>
          <el-select v-model="obj.industry_id" slot="prepend" placeholder="请选择" style="width: 120px;">
            <el-option :label="item.name" :value="item.id" v-for="(item,index) in cates"></el-option>
          </el-select>
        </div>
        <div class="w-400 ml20 mr20">
          <el-input clearable @clear="clickSearch" placeholder="请输入采购商名称" @keyup.enter.native="clickSearch" v-model="obj.keyword" ></el-input>
        </div>
        <el-button type="primary" icon="el-icon-search" @click="clickSearch()">搜索</el-button>
      </div>
    </div>
    <el-table :data="list"  border  style="width: 100%" >
      <el-table-column align="center" label="头像" width="80">
        <template slot-scope="scope">
          <div class="difcac pointer" @click="showImg(scope.row)"><img class="w-50 h-50" :src="scope.row.cover_image" alt=""></div>
        </template>
      </el-table-column>
      <el-table-column prop="name" align="center" label="名称" width="280"></el-table-column>
      <el-table-column prop="info_address" align="center" label="详细地址" ></el-table-column>
      <el-table-column align="center" label="绑定状态" width="140">
        <template slot-scope="scope">
          <el-button v-if="scope.row.bind_status===1" type="primary" @click="apply(scope.row)"><span class="fs16">申请加入</span></el-button>
          <el-button v-if="scope.row.bind_status===2" type="warning"><span class="fs16">审核中</span></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="difcac mt20 mb20">
      <el-pagination  background layout="prev, pager, next" :current-page.sync="obj.page" :page-size="obj.limit" :total="total" @current-change="getList"></el-pagination>
    </div>
    <apply-bind @applySuccess="getList" ref="applyBind"></apply-bind>
  </div>
</template>

<script>
import ApplyBind from "@/views/meCenter/right/components/applyBind.vue";

export default {
  name: "purchaser",
  components: {ApplyBind},
  data() {
    return {
      obj: {
        page: 1,
        limit: 20,
        keyword: '',
        industry_id: '',
      },
      list: [],
      cates: [],
      total: 0
    }
  },
  methods: {
    refresh() {
      let time = new Date().getTime()
      this.$router.replace('/me/purchaser'+`?time=${time}`)
    },
    apply(item) { //申请绑定
      this.$refs.applyBind.show(item)
    },
    showImg(item) {
      this.$viewerApi({
        images: [item.cover_image]
      })
    },
    clickSearch() { //搜索
      this.obj.page = 1
      this.getList()
    },
    getList() {
      this.$axios("POST","/mchs/getCaigouList",this.obj)
          .then(res => {
            this.list = res.data.list
            this.total = res.data.total
          })
    },
    getMchInfo(invite_code) {
      this.$axios("POST","/mchs/inviteCodeByMch",{invite_code})
          .then(res => {
            if(res.code===1) {
              this.$nextTick(() => {
                this.apply(res.data)
              })
            }
          })
    },
    getCates() {
      this.$axios("POST","/index/getIndustry",{})
          .then(res => {
            if(res.code===1) this.cates = [{id: '',name: '全部'},...res.data]
          })
    },
  },
  mounted() {
    this.getList()
    this.getCates()
    if(this.$route.query.code) {
      this.getMchInfo(this.$route.query.code)
    }
  }
}

</script>

<style scoped>

</style>