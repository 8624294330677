<template>
  <div>
    <div class="tct mb4 bold">若需要解除绑定关系可联系客服13356261927</div>
    <!--供应商查看-->
    <supplier-bind-list v-if="userInfo.firm_type == 1"></supplier-bind-list>
    <!--采购商查看-->
    <purchaser-bind-list v-else></purchaser-bind-list>
  </div>
</template>

<script>
import {mapState} from "vuex";
import SupplierBindList from "@/views/meCenter/right/components/supplierBindList.vue";
import PurchaserBindList from "@/views/meCenter/right/components/purchaserBindList.vue";

export default {
  name: "bindList",
  components: {PurchaserBindList, SupplierBindList},
  computed: {
    ...mapState(['userInfo'])
  },
  data() {
    return {

    }
  },
  methods: {

  },
  mounted() {

  }
}

</script>

<style scoped>

</style>