<template>
  <div style="padding-bottom: 81px;">
    <!--地址列表-->
    <div style="width: 1200px;" class="mr0At">
      <div class="difb alc" style="padding: 28px 0;border-bottom: 1px solid #E8E8E8;">
        <p class="fs26 col000">确认收货地址</p>
        <p class="fs26 col000 pointer hover" @click="goAddress()">地址管理</p>
      </div>
      <div class="address_warp pdTop20">
        <el-radio-group v-model="currentAddress">
          <div v-for="(item,index) in addressList" :key="index">
            <el-radio :label="item.id" class="dif alc" style="margin-bottom: 70px;">
              <p class="address_item hover one_overflow">
                {{item.name}}，{{item.tel}}，{{item.address}}{{item.sca_name}}
              </p>
            </el-radio>
          </div>
        </el-radio-group>
        <div v-if="addressList.length<=0" style="">
          <el-result icon="info" title="提示" subTitle="赞无收货地址"></el-result>
        </div>
      </div>
    </div>
    <!--备注-->
    <div style="width: 1200px;" class="mr0At mrBtm30">
      <div class="difb alc" style="padding: 28px 0;border-bottom: 1px solid #E8E8E8;">
        <p class="fs26 col000">备注</p>
      </div>
      <el-input type="textarea" rows="8" placeholder="请输入备注" v-model="remark"></el-input>
    </div>
    <div style="border-bottom: 1px solid #E2E2E2;"></div>
    <!--商品信息-->
    <div style="width: 1200px;padding-top: 42px;" class="mr0At">
      <div class="bold mrBtm30" style="font-size: 26px;">确认订单信息：</div>
      <!--表头-->
      <div class="dif alc fs18 col000" style="margin-bottom: 12px;background-color: #EEEEEE;padding-top: 10px;padding-bottom: 10px;">
        <div style="margin-left: 122px;margin-right: 179px;">商品信息</div>
        <div style="margin-right: 160px;">商品规格</div>
        <div style="margin-right: 155px;">单价（元）</div>
        <div style="margin-right: 180px;">数量</div>
        <div>金额（元）</div>
      </div>
      <!--表单-->
      <div class="goods_warp">
        <div class="goods_item dif alc" v-for="(item,index) in list" :key="index">
          <!--商品-->
          <div class="dif alc">
            <img :src="item.good_image" alt="" class="goods_img">
            <p class="two_overflow fs14 col000"
               style="word-break: break-all;width: 130px;">{{item.good_name}}</p>
          </div>
          <!--规格-->
          <div class="two_overflow fs14 col000 tct"
               style="width: 120px;margin-left: 74px;word-break: break-all">{{item.attr_txt}}</div>
          <!--单价-->
          <div class="fs20 tct" style="color: #414141;width: 200px;margin-left: 75px;">{{item.good_price}}</div>
          <!--数量-->
          <div style="margin-left: 70px;">
            <el-input-number size="mini" :min="1" v-model="item.num" disabled></el-input-number>
          </div>
          <!--金额-->
          <div class="fs24  tct" style="color: #D64040;width: 160px;margin-left: 80px;">
            ¥{{(item.num*item.good_price).toFixed(2)}}
          </div>
        </div>
      </div>
    </div>

    <!--支付-->
    <div class="pay_center">
      <!--支付-->
      <div class="difb alc" style="width: 1100px;">
        <div class="dif alc">
          <div class="pay_item difcac" @click="payType=1" :class="{'active': payType===1}">
            <img src="@/assets/images/icon_zhifubao.png" style="width: 38px;height: 38px;">
            <span class="mrLft10">支付宝支付</span>
            <div class="check" v-if="payType===1"></div>
            <i class="el-icon-check check_icon" v-if="payType===1"></i>
          </div>
          <div class="pay_item difcac mrLft20" @click="payType=2" :class="{'active': payType===2}">
            <img src="@/assets/images/icon_wieixin.png" style="width: 38px;height: 38px;">
            <span class="mrLft10">微信支付</span>
            <div class="check" v-if="payType===2"></div>
            <i class="el-icon-check check_icon" v-if="payType===2"></i>
          </div>
        </div>
        <!--<div class="dif alc">
          <span class="fs32 col000">应付金额</span>
          <span style="font-size: 54px;color: #F75C5C">￥{{allMoney}}</span>
          <span class="fs32 col000 mrLft10">元</span>
        </div>-->
        <div class="difcc" style="border: 2px solid #1572F9;padding-top: 20px;">
          <div class=" " style="padding: 0 30px;">
            <div class="dif alc">
              <span>商品总金额：</span>
              <span style="font-size: 32px;color: #F75C5C">{{allMoney}}</span>
              <span class="fs32 col000 mrLft10">元</span>
            </div>
            <p class="tct" v-if="money<full_money">含运费￥{{send_price}}元</p>
          </div>
          <div class="pay_button difcac mrTop20" @click="submit()">提交订单</div>
        </div>
      </div>
      <!--<div class="dife alc">-->
      <!--  <div class="pay_button difcac" @click="submit()">提交订单</div>-->
      <!--</div>-->
    </div>


    <!--支付宝支付-->
    <el-dialog :visible.sync="showAlipay" :modal="true" width="350px" :center="true" top="40vh" @close="close2()">
      <div class="difcc">
        <h2 class="tct mrBtm20">支付宝支付</h2>
        <div class="mrTop20 difcac">
          <div>
            <el-button type="success" plain round @click="paySuccess()">支付完成</el-button>
          </div>
          <div class="mrLft30">
            <el-button type="info" plain round @click="close2()">重新支付</el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <!--微信-->
    <el-dialog :visible.sync="showWeChatCode" :modal="true" width="350px" :center="true" top="30vh" @close="close()">
      <div v-if="imgUrl" class="difcc">
        <h2 class="tct mrBtm20">微信支付</h2>
        <img :src="imgUrl" style="width: 200px;height: auto">
        <div class="mrTop20 difcac">
          <div>
            <el-button type="success" plain round @click="paySuccess()">支付完成</el-button>
          </div>
          <div class="mrLft30">
            <el-button type="info" plain round @click="close()">重新支付</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>

</template>

<script>
export default {
  name: "payOrder",
  data() {
    return{
      type: '1',//1直接下单 2购物车下单
      list: [],//下单列表
      addressList: [], //地址列表
      currentAddress: '', //选择的地址
      payType: 1, //支付类型 1支付宝 2微信
      showAlipay: false,
      showWeChatCode: false,
      imgUrl: '',//微信二维码
      money: 0,
      allMoney: 0,//总价
      full_money: 0,
      send_price: 0,
      remark: '',//备注
    }
  },
  methods: {
    close() { //微信支付关闭弹窗
      this.imgUrl = ''
      this.showWeChatCode = false
    },
    close2() { //支付宝支付关闭弹窗
      this.showAlipay = false
    },
    paySuccess() { //点击支付成功
      this.$router.push('/me/myOrder')
    },
    // 地址管理
    goAddress() {
      this.$router.push('me/address')
    },
    //获取地址列表
    getAddressList() {
      this.$axios("POST","/user/getMyAddress",{})
          .then(res => {
            if(res.code===1) {
              this.addressList = res.data
              let obj = this.addressList.find(item => {
                return item.is_default==1
              })
              if(obj) this.currentAddress = obj.id
            }
          })
    },
    //获取默认地址
    getDefaultAddress() {},
    //获取邮费 信息
    getInfo() {
      this.$axios("POST","/Order/getShip",{})
          .then(res => {
            if(res.code===1) {
              this.full_money = res.data.full_money
              this.send_price = res.data.send_price
              this.getAllMoney() //获取总金额
            }
          })
    },
    //获取总金额
    getAllMoney() {
      let money = 0
      for(let item of this.list) {
        money = Number(money) + (item.good_price * item.num)
      }
      if(money<this.full_money) {
        money = money + Number(this.send_price)
      }
      this.money = money
      this.allMoney = money.toFixed(2)
    },
    //提交订单
    submit() {
      if(!this.currentAddress) {
        return this.$alert('请选择收货地址',{type: 'error'})
      }
      let data = {
        address_id: this.currentAddress,
        remark: this.remark
      }
      if(this.type==2) { //购物车下单
        let arr = []
        for(let item of this.list) {
          arr.push(item.id)
        }
        data.cart_ids = arr.join(',')
      }else { //商品直接下单
        data.sku_id = this.list[0].sku_id,
        data.num = this.list[0].num
      }
      this.$axios("POST","/Order/place",data,'加载中')
      .then(res => {
        if(res.code===1) {
          this.getPayInfo(res.data.id)
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    //获取支付信息
    getPayInfo(id) {
      let data ={
        pay_type: this.payType,
        id: id
      }
      this.$axios("POST","/Order/placePay",data,'加载中')
      .then(res => {
        if(res.code===1) {
          if(data.pay_type===1) {
            this.showAlipay = true
            // 打开支付宝form表单
            const div = document.createElement('div')
            div.innerHTML = res.data
            document.body.appendChild(div)
            document.forms[0].acceptCharset = "UTF-8"
            document.forms[0].target = "_blank"
            document.forms[0].submit()
          }else {
            this.showWeChatCode = true
            this.imgUrl = res.data
          }
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
  },
  created() {
    this.getAddressList() //获取地址列表
    this.type = this.$route.query.type || '1'
    if(this.type==2) { //购物车下单  获取缓存中的购物车数据
      this.list = JSON.parse(localStorage.getItem('hbcart'))
    }else { //直接下单
      this.list = JSON.parse(localStorage.getItem('hbcart'))
    }
    console.log(this.list);
    this.getInfo() //获取邮费信息

  }
}
</script>

<style scoped>
  ::v-deep .el-radio__inner{
    width: 24px;
    height: 24px;
  }
  ::v-deep .el-radio__input.is-checked .el-radio__inner::after{
    width: 12px;
    height: 12px;
  }
  .address_warp{
    overflow-y: auto;
    max-height: 463px;
  }
  .address_item{
    width: 1100px;
    font-size: 24px;
    color: #000000;
  }
  .goods_warp{
    background: #FFFFFF;
    box-sizing: border-box;
    border: 1px solid #E8E8E8;
  }
  .goods_item{
    border-bottom: 1px solid #E8E8E8;
    box-sizing: border-box;
    padding: 20px 0 20px 37px ;
  }
  .goods_img{
    width: 90px;
    height: 90px;
    background-color: #EEEEEE;
    margin-right: 15px;
  }
  .pay_warp{
    background-color: #f1f1f1;
    height: 79vh;
  }
  .pay_center{
    height: 100%;
    box-sizing: border-box;
    background-color: #ffffff;
    width: 1200px;
    margin: 0 auto;
    padding-left: 50px;
    padding-top: 37px;
  }
  .pay_item{
    width: 236px;
    height: 69px;
    background: #FFFFFF;
    border-radius: 6px 6px 6px 6px;
    border: 2px solid #868686;
    cursor: pointer;
    font-size: 28px;
    color: #383D42;
    overflow: hidden;
    position: relative;
  }
  .pay_item.active{
    border: 2px solid #337DF7;
    color: #337DF7;
  }
  .check{
    width: 41px;
    height: 41px;
    background-color: #3765FF;
    position: absolute;
    right: -20px;
    bottom: -20px;
    transform: rotate(45deg);
  }
  .check_icon{
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 18px;
    color: #ffffff;
    z-index: 22;
  }
  .pay_button{
    width: 100%;
    height: 40px;
    background: #1572F9;
    border: 1px solid #1572F9;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.1s;
  }
  .pay_button:hover{
    background: #0042aa;
  }
</style>