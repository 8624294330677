<template>
  <div style="width: 1200px;margin: 0 auto;padding-top: 73px;padding-bottom: 61px;">
    <!--标题-->
    <h1 class="fs32 col000 one_overflow">请在这里添加您的具体新闻名称请在这里添加您的副新闻名称</h1>
    <div class="difb alc" style="margin-top: 57px;">
      <p class="fs18" style="color: #969696">XXX有限公司</p>
      <p class="fs18" style="color: #969696">2021-11-30 11:47:08</p>
    </div>
    <div class="border"></div>
    <!--内容-->
    <div class="fs18 col000" style="line-height: 1.4em;">
      xx律师事务所，是经过某某省司法厅批准设立的综合性的法律服务机构。经过几年的高速发展，xx律师已 经发展成为xx省内规模较大的律师事务所之一。xx律师事务所位于xx著名的城市，地理位置十分优越。周围是xx文教中心，与xx省博物馆、xx师范大学毗邻而居，具有浓厚的人文气息；与xx省司法厅隔路相望，具有同司法行政机关适时信息沟通的便利。拥有所有权的 2800平方米的现代办公场所，为律师与客户的交流提供了方便舒适的环境。秉承"客户至上、专业服务、合作共赢"的服务理念，坚持"严谨、高效"的服务原则。
      致力于为国内外客户量身定制各种类别的法律服务成为一家服务领域全方位、服务范围国际化的颇具规模的综合性律师事务所凭借深厚的专业知识、丰富的执业经 验、勤勉的敬业态度，成为成都律师界中较富朝气活力的中坚力量xx律师事务所是经北京市司法局批准成立的合伙制律师事务所。本所律师多 属于复合型专家律师，本所律师具备传统及现代行业的专业知识和丰富的实践经验，专业范围覆盖了各个主要法律领域。本所的合伙人多样性及对中国法律的精深掌 握使得本所律师有足够的能力为国内及国际客户提供复杂的、全方位的专业法律服务，本所关注客户在国际商务交流中的需要，
    </div>
    <div class="border2"></div>
    <!--联系方式-->
    <div class="contact posr">
      <!--背景图-->
      <img src="@/assets/images/icon_bjbj.png" class="w100 h100">
      <!--内容-->
      <div class="contact_warp">
        <h2 class="fs20 box" style="padding-left: 80px;">报价请传邮箱,报价单总价请写在邮箱标题上面方便观看</h2>
        <div class="contact_item difb alc" style="margin-top: 33px;">
          <div>联系人：</div>
          <div style="color: #969696">张三</div>
        </div>
        <div class="contact_item difb alc">
          <div>联系方式：</div>
          <div style="color: #969696" class="dif alc">
            13276442943
            <img src="@/assets/images/icon_dianhua.png" style="width: 24px;height: 24px;margin-left: 11px;">
          </div>
        </div>
        <div class="contact_item dife alc" style="color: #969696">
          联系我时请说是在慧邦中工平台看到的
        </div>
        <div class="contact_item difb alc">
          <div style="color: #414141">限投数量：</div>
          <div style="color: #969696">1</div>
        </div>
        <div class="contact_item difb alc">
          <div style="color: #414141">标书金额：</div>
          <div style="color: #969696">1</div>
        </div>
      </div>
    </div>
    <!--报价列表-->
    <div>
      <div class="user_item difb alc" v-for="(item,index) in 4" :key="index">
        <div class="dif alc">
          <img src="" alt="" class="avatar">
          <div class="fs32 col000">
            <p>用户名</p>
            <p style="margin-top: 19px;">2345765421</p>
          </div>
        </div>
        <div class="trt fs32">
          <p class="col000">2020-12-12</p>
          <p class="pointer hover mrTop30" style="color: #FB6124">下载标书</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "offerDetail",
  methods: {
    //我要发布
    fabu() {
      eventBus.$emit('showFabu',{});
    },
  },
}
</script>

<style scoped>
.avatar{
  width: 140px;
  height: 140px;
  background: #F5E7E7;
  border-radius: 50%;
  margin-right: 45px;
}
.user_item{
  height: 234px;
  background: #FFFFFF;
  border-radius: 16px 16px 16px 16px;
  border: 1px solid #E2E2E2;
  box-sizing: border-box;
  margin: 12px 0;
  padding-left: 50px;
  padding-right: 60px;
}
.border{
  width: 979px;
  height: 1px;
  background: #EEEEEE;
  margin: 14px auto 30px;
}
.border2{
  width: 1200px;
  height: 8px;
  margin: 14px 0;
  background: #EEEEEE;
}
.contact{
  width: 1200px;
  height: 648px;
  background: #000000;
}
.contact_warp{
  width: 906px;
  height: 463px;
  background: #FFFFFF;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  box-sizing: border-box;
  padding-top: 68px;
}
.contact_item{
  border-bottom: 1px dashed #EEEEEE;
  padding: 19px 148px 17px 59px;
  color: #000000;
  font-size: 18px;
  width: 865px;
  margin: 0 auto;
}
.button{
  width: 272px;
  height: 55px;
  background: #1572F9;
  font-size: 18px;
  color: #ffffff;
  transition: all 0.2s;
  cursor: pointer;
}
.button:hover{
  background: #015bd5;
}
</style>