<template>
  <div>
    <!--用户信息-->
    <div id="myInfo" >
      <div class="top1 difcac ">
        <img src="@/assets/images/contact.png" style="height: auto;width: 80px;">
        <div class="bold fs32" style="margin-left: 40px;">{{contact}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "contact",
  data() {
    return {
      contact: '',
    }
  },
  methods: {
    getContact() { //获取客服
      this.$axios("POST","/index/getConfig",{name: 'kefu_tel'})
      .then(res => {
        if(res.code===1) {
          this.contact = res.data
        }
      })
    },
  },
  created() {
    this.getContact()
  }

}
</script>

<style scoped>
.avatar{
  border-radius: 50%;
  width: 140px;
  height: 140px;
  background-color: #EEEEEE;
  margin-bottom: 22px;
}
.top1{
  width: 100%;
  height: 400px;
  background: #FFFFFF;
  border-radius: 16px 16px 16px 16px;
  border: 1px solid #E2E2E2;
  margin-bottom: 27px;
  box-sizing: border-box;
}
.top2{
  width: 100%;
  height: 234px;
  background: #FFFFFF;
  border-radius: 16px 16px 16px 16px;
  border: 1px solid #E2E2E2;
  box-sizing: border-box;
  padding-left: 50px;
}

.e_warp{
  width: 1114px;
  height: 803px;
  background: #FFFFFF;
  box-sizing: border-box;
  padding-left: 36px;
  padding-top: 49px;
}
.e_left{
  font-size: 16px;
  color: #000000;
  margin-right: 28px;
}
.e_item{
  border-bottom: 1px dashed #EEEEEE;
  padding: 13px 61px;
  box-sizing: border-box;
  width: 490px;
}
.e_select ::v-deep.el-input__inner{
  width: 260px;
  height: 38px;
  background: #FFFFFF;
  border-radius: 2px 2px 2px 2px;
  border: 1px solid #D2D2D2;
  box-sizing: border-box;
  padding: 0 17px;
  font-size: 16px;
}
.submit_button{
  width: 443px;
  height: 65px;
  background: #007AFF;
  border-radius: 4px 4px 4px 4px;
  font-size: 20px;
  margin-top: 45px;
  transition: all 0.3s;
}
.submit_button:hover{
  background: #3A53FF;
}

</style>